import axios from 'axios';
import router from '../router';
import store from '../store';

if (process.env.VUE_APP_API_ROOT) {
  // console.info('%cUsing api root: ' + process.env.VUE_APP_API_ROOT, 'color:darkgreen;');
} else {
  alert(
    'You need to select which API to use. Please specify an apiRoot in the file api_config.js in the root dir.\nAlso, for local development, you need to specify clientId in the same file.');
}


let yogoApi = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
});

yogoApi.interceptors.response.use(function (response) {
  const responseData = response.data;
  if (responseData.error) {
    store.dispatch('alert/show', {
      title: responseData.error.localized_title,
      message: responseData.error.localized_message,
    });
  }
  return responseData;
}, function (error) {

  if (
    (error.response.status === 401 || error.response.status === 403)
    && error.request.responseURL.indexOf('/login') === -1
  ) {
    window.localStorage.removeItem('accessToken');
    window.sessionStorage.removeItem('accessToken');

    router.push({ name: 'Login' });
  }
  return Promise.reject(error);
});

yogoApi.interceptors.request.use(async function (config) {

  let headers = config.headers || {};

  if (config.url.indexOf('/login') > -1) {
    // Don't send access token to login
    if (headers.Authorization) delete headers.Authorization;
  } else {
    // Send access token to all other routes, if available
    let accessToken = window.localStorage.getItem('accessToken')
      || window.sessionStorage.getItem('accessToken');

    // If we are on mobile and not going for the Signup screen,
    // wait for accessToken to be available
    const searchParams = (new URL(document.location)).searchParams;
    if (searchParams.get('mobileAppMode') && !accessToken) {
      const currentRoute = router.currentRoute;
      const requestedRoute = store.getters.requestedRoute;
      if (
        currentRoute.name !== 'Signup'
        && (!requestedRoute || requestedRoute.name !== 'Signup')
      ) {
        do {
          accessToken = window.localStorage.getItem('accessToken')
            || window.sessionStorage.getItem('accessToken');
          await new Promise(resolve => setTimeout(resolve, 200));
        }
        while (!accessToken);
      }
    }

    if (accessToken) {
      headers.Authorization = 'Bearer ' + accessToken;
    }
  }

  // Set request context, just as a hint to the API.
  // That prevents customers from accidentally logging in to admin area.
  headers['X-Yogo-Request-Context'] = 'frontend';

  // Set client ID from env. Used for local testing
  if (process.env.VUE_APP_CLIENT_ID) {
    headers['X-Yogo-Client-ID'] = process.env.VUE_APP_CLIENT_ID;
  }

  // Set client ID from window URL. Used for..?
  const regexResult = window.location.href.match(/clientId=(\d+)/);

  if (regexResult) {
    headers['X-Yogo-Client-ID'] = regexResult[1];
  }

  // Apply headers
  config.headers = headers;

  return config;

}, function (error) {
  console.log(error.message);
});

export default yogoApi;
