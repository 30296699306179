<template>
    <div class="signup-for-class">

        <h1>Vil du tilmelde dig denne klasse?</h1>

    </div>
</template>

<script>
    export default {
        name: 'SignupForClass'
    }
</script>

<style>



</style>
