<template>
  <svg width="16px" height="14px" viewBox="0 0 52 37" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" transform="translate(3.000000, 3.000000)" stroke="currentColor" stroke-width="6">
        <circle id="Oval" cx="15.5" cy="15.5" r="15.5"></circle>
        <circle id="Oval-Copy" cx="30.5" cy="15.5" r="15.5"></circle>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'TwoCirclesIcon',
};
</script>
