<template>
  <div
      class="eventsection"
      :class="{'pt-5' : mobileAppMode }"
  >
    <div
        :class="mobileAppMode ? 'mx-5 rounded-lg bg-white shadow-lg p-4' : 'theme--frame-box'"
    >

      <div class="flex__grid--thirds align--top">
        <div class="flex__1-3--margin">
          <img
              :src="Imgix(eventGroup.image.filename, {w:640, h:640, fit:'crop'})"
              alt=""
              :class="{'rounded-lg' : mobileAppMode}"
              v-if="eventGroup.image"
          >
        </div>
        <div class="space4"></div>
        <div class="flex__1-3--margin">
          <h3>{{ eventGroup.name }}</h3>
          <nl2br tag="p" :text="eventGroup.description"></nl2br>
        </div>
        <div v-if="!mobileAppMode" class="space4"></div>
        <div class="flex__1-3--margin">
          <ul class="widget__list--standard">
            <li v-for="event in eventGroup.events" :key="'event_' + event.id">

              <p class="ttu bold pointer" @click.prevent="openEventDialog(event)">
                {{ event.name }}
              </p>

              <div v-if="event.use_time_slots">
                <p v-for="timeSlot in event.time_slots" :key="'timeSlot_' + timeSlot.id">
                  {{
                    formatDate(`${timeSlot.date} ${timeSlot.start_time}`,
                        { weekday: true, time: true })
                  }}-{{ formatTime(timeSlot.end_time) }}
                </p>
              </div>
              <nl2br
                  v-if="!event.use_time_slots"
                  tag="p"
                  :text="event.time_freetext"
              />
              <div v-if="event.room && !event.time_slots_have_separate_rooms_and_teachers">
                {{ event.room.name }}
              </div>

              <p>
                <span :class="{strikethrough: event.has_member_discount}">
                  {{ $t('global.price') }}:
                  <span v-if="event.price > 0">
                  {{ formatCurrency(event.price) }}
                  </span>
                  <span v-else>
                    {{ $t('global.free') }}
                  </span>
                </span>
                <span v-if="event.has_member_discount">
                  <br>
                  <span class="bold">
                    {{ $t('prices.MemberPrice') }}:
                    <span v-if="event.price_with_member_discount > 0">
                      {{ formatCurrency(event.price_with_member_discount) }}
                    </span>
                    <span v-else>
                      {{ $t('global.free') }}
                    </span>
                  </span>
                </span>
              </p>

              <div class="space2"></div>
              <div
                  class="flex justify-between items-center w-full flex-wrap border__bottom--gray"
                  :class="{'my-1' : !mobileAppMode}"
              >
                <a
                    v-if="!mobileAppMode"
                    class="btn lower flex my-2"
                    @click.prevent="openEventDialog(event)"
                >
                  {{ $t('global.readMore') }}
                </a>
                <div
                    v-else
                    @click.prevent="openEventDialog(event)"
                    class="btn"
                    :class="{ 'btn-small-app my-1': mobileAppMode }"
                >
                  {{ $t('global.readMore') }}
                </div>

                <span v-if="signedUpForEventIds.indexOf(event.id) !== -1"
                      :class="{ 'mb-2': !mobileAppMode }"
                >
                  {{ $t('global.youAreSignedUp') }}
                </span>
                <div
                    class="flex flex-wrap items-center"
                    v-else-if="event.signup_count < event.seats"
                >
                  <YogoNumberInput
                      v-if="client.settings.customer_can_buy_multiple_seats_for_event && event.price"
                      class="mr-4"
                      :class="{'w-full': showInstallmentPaymentOption(event)}"
                      :value="eventBuyCountMap[event.id]"
                      @input="$emit('updateEventBuyCountMap', event.id, $event)"
                      :min="1"
                      :max="event.seats - event.signup_count"
                  ></YogoNumberInput>
                  <div class="mr-2">
                    <button
                        class="btn btn-primary"
                        :class="{ 'btn-primary-app my-1': mobileAppMode }"
                        :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                        @click.prevent="buyEvent(event)"
                    >
                      {{ $t('global.signup') }}
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                        v-if="showInstallmentPaymentOption(event)"
                        class="btn btn-primary"
                        :class="mobileAppMode ? 'btn-primary-app my-1': 'my-2'"
                        :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                        @click.prevent="buyEvent(event, payInInstallments = true)"
                    >
                      {{ $t('event.SignUpPayInInstallments') }}
                    </button>
                  </div>
                  <a
                      v-if="showInstallmentPaymentOption(event)"
                      href="#"
                      @click="showInstallments(event)"
                      :class="mobileAppMode ? 'btn-small-app my-1': 'my-2 underline'"
                  >
                    {{ $t('event.ShowInstallments') }}
                  </a>
                </div>
                <span v-else>{{ $t('global.fullyBooked') }}</span>
              </div>
            </li>
          </ul>
          <div class="space4"></div>
        </div>
      </div>
    </div>

    <modal :show.sync="showInstallmentModal">

      <template v-slot:title>{{
          installmentModalEvent ? installmentModalEvent.name : ''
        }}
      </template>

      <template v-slot:content>
        <div>
          <h3>{{ $t('event.Installments') }}</h3>
          <div class="inline-block min-w-full py-2 align-middle">
            <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    {{ $t('global.Date') }}
                  </th>
                  <th class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-6">{{
                      $t('global.Amount')
                    }}
                  </th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(row, idx) in modalInstallmentRows" :key="'installment_' + idx">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                    {{ row.date }}
                  </td>
                  <td class="whitespace-nowrap py-4 px-3 text-sm font-medium text-gray-900 sm:pl-6">
                    <span v-if="row.has_member_discount">
                      <span class="strikethrough">{{ row.amount }}</span><br>
                      <span class="font-bold">{{ row.amount_with_member_discount }}</span>
                    </span>
                    <span v-else>{{ row.amount }}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>

    </modal>

  </div> <!-- eventsection -->


</template>

<script>

import { mapGetters } from 'vuex';
import Imgix from "../services/Imgix";
import currencyFormatters from '@/mixins/currencyFormatters';
import YogoNumberInput from '@/components/ui/YogoNumberInput';
import Modal2 from '@/components/YogoDialog.vue';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';

export default {
  components: {
    YogoNumberInput,
    Modal: Modal2,
  },
  data() {
    return {
      showInstallmentModal: false,
      installmentModalEvent: null,
    };
  },
  mixins: [Imgix, currencyFormatters, dateTimeFunctions],
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'profileImageFilename',
      'client',
      'mobileAppMode',
    ]),
    modalInstallmentRows() {
      // If no event selected, return empty array
      if (!this.installmentModalEvent) return [];

      // First payment (now)
      const rows = [{
        date: this.$t('global.Now'),
        amount: this.formatCurrency(this.installmentModalEvent.current_required_paid_amount),
        has_member_discount: this.installmentModalEvent.has_member_discount,
        amount_with_member_discount: this.installmentModalEvent.has_member_discount
            ? this.formatCurrency(this.installmentModalEvent.current_required_paid_amount_with_member_discount)
            : null,
      }];
      if (this.installmentModalEvent.current_required_number_of_paid_installments > 1) {
        const n = this.installmentModalEvent.current_required_number_of_paid_installments;
        rows[0].date += ` (${this.$t('event.installmentsOneToN', { n })})`;
      }

      // Future payments
      for (
          let i = this.installmentModalEvent.installments.length - this.installmentModalEvent.number_of_installments_left;
          i < this.installmentModalEvent.installments.length;
          i++
      ) {
        rows.push({
          date: this.formatDate(this.installmentModalEvent.installments[i].date),
          amount: this.formatCurrency(this.installmentModalEvent.installments[i].amount),
          has_member_discount: this.installmentModalEvent.installments[i].has_member_discount,
          amount_with_member_discount: this.installmentModalEvent.installments[i].has_member_discount
              ? this.formatCurrency(this.installmentModalEvent.installments[i].amount_with_member_discount)
              : null,
        });
      }
      return rows;
    },
  },
  props: [
    'eventGroup',
    'signedUpForEventIds',
    'eventBuyCountMap',
  ],
  mounted: function () {
    //$('.modal').modal();
  },
  methods: {
    openEventDialog(event) {
      this.$emit('openEventDialog', event);
    },
    buyEvent(event, payInInstallments = false) {
      this.$emit('buyEvent', event, payInInstallments);
    },
    showInstallmentPaymentOption(event) {
      return event.installment_payments_enabled && event.number_of_installments_left >= 1;
    },
    showInstallments(event) {
      this.installmentModalEvent = event;
      this.showInstallmentModal = true;
    },
  },
};
</script>

<style lang="stylus">

.flex__1-3--margin
  img
    width 100%
    height 100%
  object-fit contain


</style>
