import pick from 'lodash/pick';
import assign from 'lodash/assign';

export const SHOW = 'SHOW';
export const RESET = 'RESET';
export const SET_CALLBACK = 'SET_CALLBACK';
export const SET_BUTTONS = 'SET_BUTTONS';

const allowedSettingKeys = ['title', 'message'];

const initialState = () => ({
  title: '',
  message: '',
  callback: null,
  buttons: [{
    label: 'global.Ok',
    action: 'closeDialog',
    primary: true,
  }],
});


const getters = {
  title: state => state.title,
  message: state => state.message,
  callback: state => state.callback,
  buttons: state => state.buttons,
};

const actions = {
  show({ commit }, settings) {
    console.log('show alert');
    commit(SHOW, pick(settings, allowedSettingKeys));
  },
  reset({ commit }) {
    commit(RESET);
  },
  setCallback({ commit }, callback) {
    commit(SET_CALLBACK, callback);
  },
  setButtons({ commit }, buttons) {
    commit(SET_BUTTONS, buttons);
  },
};

const mutations = {
  [SHOW]: (state, settings) => {
    console.log(settings);
    assign(state, settings);
  },
  [RESET]: (state) => {
    state.title = '';
    state.message = '';
    state.buttons = initialState().buttons;
  },
  [SET_CALLBACK]: (state, callback) => {
    state.callback = callback;
  },
  [SET_BUTTONS]: (state, buttons) => {
    state.buttons = buttons;
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
