<template>
  <div></div>
</template>

<script>

import {mapGetters} from 'vuex';
import _defaults from 'lodash/defaults';

export default {
  computed: {
    ...mapGetters(['client']),
  },
  methods: {
    formatNumber(amount, options) {
      const optionsWithDefaults = _defaults(options, {
        maximumFractionDigits: 2,
      });
      return new Intl.NumberFormat(
          this.client.settings.locale,
          optionsWithDefaults,
      ).format(amount);
    },
  },
};

</script>
