<template>
  <div :style="{color: fontColor}" class="class-action">
    <button class="btn btn-secondary" :style="{color: fontColor}"
            v-if="showSignupButton"
            @click.prevent.stop="signupButtonClick">
      {{ $t('global.signup') }}
    </button>

    <button class="btn btn-secondary mt-2" :style="{color: fontColor}"
            v-if="showLivestreamSignupButton"
            @click.prevent.stop="signupForLivestreamButtonClick">
      {{ $t('global.signUpForLivestream') }}
    </button>

    <button class="btn btn-secondary mt-2" :style="{color: fontColor}"
            v-if="showWaitingListSignupbutton"
            @click.prevent.stop="signupForWaitingListButtonClick">
      {{ $t('global.joinWaitlist') }}
    </button>

    <SignedUpCheckmark
        :align="align"
        :color="color"
        :class="customClass"
        v-if="classItem.user_is_signed_up_for_class || classItem.user_is_signed_up_for_livestream"
        :signup-count="classItem.user_signup_count"
    >
    </SignedUpCheckmark>
    <div
        :align="align"
        v-if="classItem.user_is_signed_up_for_livestream"
        :class="customClass"
    >
      {{ $t('global.youAreSignedUpForLivestream') }}
    </div>

    <div
        v-if="classItem.user_is_signed_up_for_waiting_list"
        :style="{display:'flex', alignItems: 'center', justifyContent: align, flexShrink:0}"
        :class="customClass"
    >
      <SignedUpForWaitingListIcon :align="align" :color="color"></SignedUpForWaitingListIcon>
      <span style="font-size:20px;"> #{{ classItem.user_number_on_waiting_list }} </span>
      <md-tooltip>{{
          $t('global.youAreNumberXOnTheWaitlist',
              { number: classItem.user_number_on_waiting_list })
        }}
      </md-tooltip>
    </div>

    <button class="btn btn-secondary" v-if="classItem.user_can_start_livestream"
            @click.prevent.stop="$router.push({name: 'LivestreamClassPreloader', params: {classId: classItem.id}})">
      {{ $t('global.startLivestream') }}
    </button>


  </div>
</template>

<script>

import SignedUpCheckmark from "../../graphics/SignedUpCheckmark";
import SignedUpForWaitingListIcon from "../../graphics/SignedUpForWaitingListIcon";
import { mapGetters } from 'vuex';

export default {
  components: { SignedUpCheckmark, SignedUpForWaitingListIcon },
  props: [
    'classItem',
    'color',
    'align',
    'customClass',
  ],
  computed: {
    fontColor() {
      return {
        white: 'rgba(255,255,255,0.85)',
        black: 'rgba(0,0,0,0.85)',
      }[this.color];
    },
    showSignupButton() {
      return (
              this.classItem.class_accepts_customer_signups
              || !!this.classItem.class_series_types.length
              || !!this.classItem.class_series_types_livestream.length
          )
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_livestream
          && !this.classItem.user_is_signed_up_for_waiting_list;
    },
    showLivestreamSignupButton() {
      return this.classItem.class_accepts_livestream_signups
          && !this.classItem.user_is_signed_up_for_livestream
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_waiting_list;
    },
    showWaitingListSignupbutton() {
      return this.classItem.class_accepts_waiting_list_signups
          && !this.classItem.user_is_signed_up_for_livestream
          && !this.classItem.user_is_signed_up_for_class
          && !this.classItem.user_is_signed_up_for_waiting_list;
    },
    ...mapGetters([
      'client',
    ]),
  },
  methods: {
    signupButtonClick() {
      if (this.classItem.class_series_types.length || this.classItem.class_series_types_livestream.length || this.client.settings.customer_can_reserve_multiple_seats_for_class) {
        this.$emit('openClassDialog', this.classItem);
      } else {
        this.$emit('signUpForClass', this.classItem);
      }
    },
    signupForLivestreamButtonClick() {
      if (this.classItem.class_series_types.length) {
        this.$emit('openClassDialog', this.classItem);
      } else {
        this.$emit('signUpForLivestream', this.classItem);
      }
    },
    signupForWaitingListButtonClick() {
      if (this.classItem.class_series_types.length) {
        this.$emit('openClassDialog', this.classItem);
      } else {
        this.$emit('signUpForWaitingList', this.classItem);
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.class-action {
  padding-top: 10px;
}
</style>
