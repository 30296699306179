<template>
  <select v-model="form.country" autocomplete="country" :disabled="disabled">
    <option
        v-for="country in countries"
        :value="country.code"
        :key="country.code"
    >
      {{ country.name }}
    </option>
  </select>
</template>

<script>
import { mapGetters } from 'vuex';
import _sortBy from 'lodash/sortBy';
import _mapValues from 'lodash/mapValues';
import _values from 'lodash/values';

// Country names copied from https://github.com/AceLewis/cldr-country-names-database/blob/master/data/json
const countryNames = {
  en: {
    AC: {
      name: 'Ascension Island',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'United Arab Emirates',
    },
    AF: {
      name: 'Afghanistan',
    },
    AG: {
      name: 'Antigua & Barbuda',
    },
    AI: {
      name: 'Anguilla',
    },
    AL: {
      name: 'Albania',
    },
    AM: {
      name: 'Armenia',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Antarctica',
    },
    AR: {
      name: 'Argentina',
    },
    AS: {
      name: 'American Samoa',
    },
    AT: {
      name: 'Austria',
    },
    AU: {
      name: 'Australia',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: '\u00c5land Islands',
    },
    AZ: {
      name: 'Azerbaijan',
    },
    BA: {
      name: 'Bosnia & Herzegovina',
      short: 'Bosnia',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesh',
    },
    BE: {
      name: 'Belgium',
    },
    BF: {
      name: 'Burkina Faso',
    },
    BG: {
      name: 'Bulgaria',
    },
    BH: {
      name: 'Bahrain',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'St. Barth\u00e9lemy',
    },
    BM: {
      name: 'Bermuda',
    },
    BN: {
      name: 'Brunei',
    },
    BO: {
      name: 'Bolivia',
    },
    BQ: {
      name: 'Caribbean Netherlands',
    },
    BR: {
      name: 'Brazil',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'Bhutan',
    },
    BV: {
      name: 'Bouvet Island',
    },
    BW: {
      name: 'Botswana',
    },
    BY: {
      name: 'Belarus',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Canada',
    },
    CC: {
      name: 'Cocos (Keeling) Islands',
    },
    CD: {
      name: 'Congo - Kinshasa',
      variant: 'Congo (DRC)',
    },
    CF: {
      name: 'Central African Republic',
    },
    CG: {
      name: 'Congo - Brazzaville',
      variant: 'Congo (Republic)',
    },
    CH: {
      name: 'Switzerland',
    },
    CI: {
      name: 'C\u00f4te d\u2019Ivoire',
      variant: 'Ivory Coast',
    },
    CK: {
      name: 'Cook Islands',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Cameroon',
    },
    CN: {
      name: 'China',
    },
    CO: {
      name: 'Colombia',
    },
    CP: {
      name: 'Clipperton Island',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Cuba',
    },
    CV: {
      name: 'Cape Verde',
      variant: 'Cabo Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Christmas Island',
    },
    CY: {
      name: 'Cyprus',
    },
    CZ: {
      name: 'Czechia',
      variant: 'Czech Republic',
    },
    DE: {
      name: 'Germany',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Djibouti',
    },
    DK: {
      name: 'Denmark',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Dominican Republic',
    },
    DZ: {
      name: 'Algeria',
    },
    EA: {
      name: 'Ceuta & Melilla',
    },
    EC: {
      name: 'Ecuador',
    },
    EE: {
      name: 'Estonia',
    },
    EG: {
      name: 'Egypt',
    },
    EH: {
      name: 'Western Sahara',
    },
    ER: {
      name: 'Eritrea',
    },
    ES: {
      name: 'Spain',
    },
    ET: {
      name: 'Ethiopia',
    },
    EU: {
      name: 'European Union',
    },
    EZ: {
      name: 'Eurozone',
    },
    FI: {
      name: 'Finland',
    },
    FJ: {
      name: 'Fiji',
    },
    FK: {
      name: 'Falkland Islands',
      variant: 'Falkland Islands (Islas Malvinas)',
    },
    FM: {
      name: 'Micronesia',
    },
    FO: {
      name: 'Faroe Islands',
    },
    FR: {
      name: 'France',
    },
    GA: {
      name: 'Gabon',
    },
    GB: {
      name: 'United Kingdom',
      short: 'UK',
    },
    GD: {
      name: 'Grenada',
    },
    GE: {
      name: 'Georgia',
    },
    GF: {
      name: 'French Guiana',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Ghana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Greenland',
    },
    GM: {
      name: 'Gambia',
    },
    GN: {
      name: 'Guinea',
    },
    GP: {
      name: 'Guadeloupe',
    },
    GQ: {
      name: 'Equatorial Guinea',
    },
    GR: {
      name: 'Greece',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guinea-Bissau',
    },
    GY: {
      name: 'Guyana',
    },
    HK: {
      name: 'Hong Kong SAR China',
      short: 'Hong Kong',
    },
    HM: {
      name: 'Heard & McDonald Islands',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Croatia',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Hungary',
    },
    IC: {
      name: 'Canary Islands',
    },
    ID: {
      name: 'Indonesia',
    },
    IE: {
      name: 'Ireland',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Isle of Man',
    },
    IN: {
      name: 'India',
    },
    IO: {
      name: 'British Indian Ocean Territory',
    },
    IQ: {
      name: 'Iraq',
    },
    IR: {
      name: 'Iran',
    },
    IS: {
      name: 'Iceland',
    },
    IT: {
      name: 'Italy',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaica',
    },
    JO: {
      name: 'Jordan',
    },
    JP: {
      name: 'Japan',
    },
    KE: {
      name: 'Kenya',
    },
    KG: {
      name: 'Kyrgyzstan',
    },
    KH: {
      name: 'Cambodia',
    },
    KI: {
      name: 'Kiribati',
    },
    KM: {
      name: 'Comoros',
    },
    KN: {
      name: 'St. Kitts & Nevis',
    },
    KP: {
      name: 'North Korea',
    },
    KR: {
      name: 'South Korea',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Cayman Islands',
    },
    KZ: {
      name: 'Kazakhstan',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'Lebanon',
    },
    LC: {
      name: 'St. Lucia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Liberia',
    },
    LS: {
      name: 'Lesotho',
    },
    LT: {
      name: 'Lithuania',
    },
    LU: {
      name: 'Luxembourg',
    },
    LV: {
      name: 'Latvia',
    },
    LY: {
      name: 'Libya',
    },
    MA: {
      name: 'Morocco',
    },
    MC: {
      name: 'Monaco',
    },
    MD: {
      name: 'Moldova',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'St. Martin',
    },
    MG: {
      name: 'Madagascar',
    },
    MH: {
      name: 'Marshall Islands',
    },
    MK: {
      name: 'North Macedonia',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Myanmar (Burma)',
      short: 'Myanmar',
    },
    MN: {
      name: 'Mongolia',
    },
    MO: {
      name: 'Macao SAR China',
      short: 'Macao',
    },
    MP: {
      name: 'Northern Mariana Islands',
    },
    MQ: {
      name: 'Martinique',
    },
    MR: {
      name: 'Mauritania',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Mauritius',
    },
    MV: {
      name: 'Maldives',
    },
    MW: {
      name: 'Malawi',
    },
    MX: {
      name: 'Mexico',
    },
    MY: {
      name: 'Malaysia',
    },
    MZ: {
      name: 'Mozambique',
    },
    NA: {
      name: 'Namibia',
    },
    NC: {
      name: 'New Caledonia',
    },
    NE: {
      name: 'Niger',
    },
    NF: {
      name: 'Norfolk Island',
    },
    NG: {
      name: 'Nigeria',
    },
    NI: {
      name: 'Nicaragua',
    },
    NL: {
      name: 'Netherlands',
    },
    NO: {
      name: 'Norway',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'New Zealand',
    },
    OM: {
      name: 'Oman',
    },
    PA: {
      name: 'Panama',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'French Polynesia',
    },
    PG: {
      name: 'Papua New Guinea',
    },
    PH: {
      name: 'Philippines',
    },
    PK: {
      name: 'Pakistan',
    },
    PL: {
      name: 'Poland',
    },
    PM: {
      name: 'St. Pierre & Miquelon',
    },
    PN: {
      name: 'Pitcairn Islands',
    },
    PR: {
      name: 'Puerto Rico',
    },
    PS: {
      name: 'Palestinian Territories',
      short: 'Palestine',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguay',
    },
    QA: {
      name: 'Qatar',
    },
    QO: {
      name: 'Outlying Oceania',
    },
    RE: {
      name: 'R\u00e9union',
    },
    RO: {
      name: 'Romania',
    },
    RS: {
      name: 'Serbia',
    },
    RU: {
      name: 'Russia',
    },
    RW: {
      name: 'Rwanda',
    },
    SA: {
      name: 'Saudi Arabia',
    },
    SB: {
      name: 'Solomon Islands',
    },
    SC: {
      name: 'Seychelles',
    },
    SD: {
      name: 'Sudan',
    },
    SE: {
      name: 'Sweden',
    },
    SG: {
      name: 'Singapore',
    },
    SH: {
      name: 'St. Helena',
    },
    SI: {
      name: 'Slovenia',
    },
    SJ: {
      name: 'Svalbard & Jan Mayen',
    },
    SK: {
      name: 'Slovakia',
    },
    SL: {
      name: 'Sierra Leone',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Somalia',
    },
    SR: {
      name: 'Suriname',
    },
    SS: {
      name: 'South Sudan',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'Syria',
    },
    SZ: {
      name: 'Eswatini',
      variant: 'Swaziland',
    },
    TA: {
      name: 'Tristan da Cunha',
    },
    TC: {
      name: 'Turks & Caicos Islands',
    },
    TD: {
      name: 'Chad',
    },
    TF: {
      name: 'French Southern Territories',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Thailand',
    },
    TJ: {
      name: 'Tajikistan',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: 'Timor-Leste',
      variant: 'East Timor',
    },
    TM: {
      name: 'Turkmenistan',
    },
    TN: {
      name: 'Tunisia',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'Turkey',
    },
    TT: {
      name: 'Trinidad & Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tanzania',
    },
    UA: {
      name: 'Ukraine',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'U.S. Outlying Islands',
    },
    US: {
      name: 'United States',
      short: 'US',
    },
    UY: {
      name: 'Uruguay',
    },
    UZ: {
      name: 'Uzbekistan',
    },
    VA: {
      name: 'Vatican City',
    },
    VC: {
      name: 'St. Vincent & Grenadines',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'British Virgin Islands',
    },
    VI: {
      name: 'U.S. Virgin Islands',
    },
    VN: {
      name: 'Vietnam',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis & Futuna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'Pseudo-Accents',
    },
    XB: {
      name: 'Pseudo-Bidi',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'Yemen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: 'South Africa',
    },
    ZM: {
      name: 'Zambia',
    },
    ZW: {
      name: 'Zimbabwe',
    },
  },
  da: {
    AC: {
      name: 'Ascension\u00f8en',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'De Forenede Arabiske Emirater',
    },
    AF: {
      name: 'Afghanistan',
    },
    AG: {
      name: 'Antigua og Barbuda',
    },
    AI: {
      name: 'Anguilla',
    },
    AL: {
      name: 'Albanien',
    },
    AM: {
      name: 'Armenien',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Antarktis',
    },
    AR: {
      name: 'Argentina',
    },
    AS: {
      name: 'Amerikansk Samoa',
    },
    AT: {
      name: '\u00d8strig',
    },
    AU: {
      name: 'Australien',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: '\u00c5land',
    },
    AZ: {
      name: 'Aserbajdsjan',
    },
    BA: {
      name: 'Bosnien-Hercegovina',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesh',
    },
    BE: {
      name: 'Belgien',
    },
    BF: {
      name: 'Burkina Faso',
    },
    BG: {
      name: 'Bulgarien',
    },
    BH: {
      name: 'Bahrain',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'Saint Barth\u00e9lemy',
    },
    BM: {
      name: 'Bermuda',
    },
    BN: {
      name: 'Brunei',
    },
    BO: {
      name: 'Bolivia',
    },
    BQ: {
      name: 'De tidligere Nederlandske Antiller',
    },
    BR: {
      name: 'Brasilien',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'Bhutan',
    },
    BV: {
      name: 'Bouvet\u00f8en',
    },
    BW: {
      name: 'Botswana',
    },
    BY: {
      name: 'Hviderusland',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Canada',
    },
    CC: {
      name: 'Cocos\u00f8erne',
    },
    CD: {
      name: 'Congo-Kinshasa',
      variant: 'Den Demokratiske Republik Congo (DRC)',
    },
    CF: {
      name: 'Den Centralafrikanske Republik',
    },
    CG: {
      name: 'Congo-Brazzaville',
      variant: 'Republikken Congo',
    },
    CH: {
      name: 'Schweiz',
    },
    CI: {
      name: 'Elfenbenskysten',
    },
    CK: {
      name: 'Cook\u00f8erne',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Cameroun',
    },
    CN: {
      name: 'Kina',
    },
    CO: {
      name: 'Colombia',
    },
    CP: {
      name: 'Clipperton\u00f8en',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Cuba',
    },
    CV: {
      name: 'Kap Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Jule\u00f8en',
    },
    CY: {
      name: 'Cypern',
    },
    CZ: {
      name: 'Tjekkiet',
      variant: 'Den Tjekkiske Republik',
    },
    DE: {
      name: 'Tyskland',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Djibouti',
    },
    DK: {
      name: 'Danmark',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Den Dominikanske Republik',
    },
    DZ: {
      name: 'Algeriet',
    },
    EA: {
      name: 'Ceuta og Melilla',
    },
    EC: {
      name: 'Ecuador',
    },
    EE: {
      name: 'Estland',
    },
    EG: {
      name: 'Egypten',
    },
    EH: {
      name: 'Vestsahara',
    },
    ER: {
      name: 'Eritrea',
    },
    ES: {
      name: 'Spanien',
    },
    ET: {
      name: 'Etiopien',
    },
    EU: {
      name: 'Den Europ\u00e6iske Union',
    },
    EZ: {
      name: 'eurozonen',
    },
    FI: {
      name: 'Finland',
    },
    FJ: {
      name: 'Fiji',
    },
    FK: {
      name: 'Falklands\u00f8erne',
      variant: 'Falklands\u00f8erne (Islas Malvinas)',
    },
    FM: {
      name: 'Mikronesien',
    },
    FO: {
      name: 'F\u00e6r\u00f8erne',
    },
    FR: {
      name: 'Frankrig',
    },
    GA: {
      name: 'Gabon',
    },
    GB: {
      name: 'Storbritannien',
      short: 'UK',
    },
    GD: {
      name: 'Grenada',
    },
    GE: {
      name: 'Georgien',
    },
    GF: {
      name: 'Fransk Guyana',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Ghana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Gr\u00f8nland',
    },
    GM: {
      name: 'Gambia',
    },
    GN: {
      name: 'Guinea',
    },
    GP: {
      name: 'Guadeloupe',
    },
    GQ: {
      name: '\u00c6kvatorialguinea',
    },
    GR: {
      name: 'Gr\u00e6kenland',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guinea-Bissau',
    },
    GY: {
      name: 'Guyana',
    },
    HK: {
      name: 'SAR Hongkong',
      short: 'Hongkong',
    },
    HM: {
      name: 'Heard Island og McDonald Islands',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Kroatien',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Ungarn',
    },
    IC: {
      name: 'Kanariske \u00f8er',
    },
    ID: {
      name: 'Indonesien',
    },
    IE: {
      name: 'Irland',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Isle of Man',
    },
    IN: {
      name: 'Indien',
    },
    IO: {
      name: 'Det Britiske Territorium i Det Indiske Ocean',
    },
    IQ: {
      name: 'Irak',
    },
    IR: {
      name: 'Iran',
    },
    IS: {
      name: 'Island',
    },
    IT: {
      name: 'Italien',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaica',
    },
    JO: {
      name: 'Jordan',
    },
    JP: {
      name: 'Japan',
    },
    KE: {
      name: 'Kenya',
    },
    KG: {
      name: 'Kirgisistan',
    },
    KH: {
      name: 'Cambodja',
    },
    KI: {
      name: 'Kiribati',
    },
    KM: {
      name: 'Comorerne',
    },
    KN: {
      name: 'Saint Kitts og Nevis',
    },
    KP: {
      name: 'Nordkorea',
    },
    KR: {
      name: 'Sydkorea',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Cayman\u00f8erne',
    },
    KZ: {
      name: 'Kasakhstan',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'Libanon',
    },
    LC: {
      name: 'Saint Lucia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Liberia',
    },
    LS: {
      name: 'Lesotho',
    },
    LT: {
      name: 'Litauen',
    },
    LU: {
      name: 'Luxembourg',
    },
    LV: {
      name: 'Letland',
    },
    LY: {
      name: 'Libyen',
    },
    MA: {
      name: 'Marokko',
    },
    MC: {
      name: 'Monaco',
    },
    MD: {
      name: 'Moldova',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'Saint Martin',
    },
    MG: {
      name: 'Madagaskar',
    },
    MH: {
      name: 'Marshall\u00f8erne',
    },
    MK: {
      name: 'Nordmakedonien',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Myanmar (Burma)',
    },
    MN: {
      name: 'Mongoliet',
    },
    MO: {
      name: 'SAR Macao',
      short: 'Macao',
    },
    MP: {
      name: 'Nordmarianerne',
    },
    MQ: {
      name: 'Martinique',
    },
    MR: {
      name: 'Mauretanien',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Mauritius',
    },
    MV: {
      name: 'Maldiverne',
    },
    MW: {
      name: 'Malawi',
    },
    MX: {
      name: 'Mexico',
    },
    MY: {
      name: 'Malaysia',
    },
    MZ: {
      name: 'Mozambique',
    },
    NA: {
      name: 'Namibia',
    },
    NC: {
      name: 'Ny Kaledonien',
    },
    NE: {
      name: 'Niger',
    },
    NF: {
      name: 'Norfolk Island',
    },
    NG: {
      name: 'Nigeria',
    },
    NI: {
      name: 'Nicaragua',
    },
    NL: {
      name: 'Holland',
    },
    NO: {
      name: 'Norge',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'New Zealand',
    },
    OM: {
      name: 'Oman',
    },
    PA: {
      name: 'Panama',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'Fransk Polynesien',
    },
    PG: {
      name: 'Papua Ny Guinea',
    },
    PH: {
      name: 'Filippinerne',
    },
    PK: {
      name: 'Pakistan',
    },
    PL: {
      name: 'Polen',
    },
    PM: {
      name: 'Saint Pierre og Miquelon',
    },
    PN: {
      name: 'Pitcairn',
    },
    PR: {
      name: 'Puerto Rico',
    },
    PS: {
      name: 'De pal\u00e6stinensiske omr\u00e5der',
      short: 'Pal\u00e6stina',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguay',
    },
    QA: {
      name: 'Qatar',
    },
    QO: {
      name: 'Ydre Oceanien',
    },
    RE: {
      name: 'R\u00e9union',
    },
    RO: {
      name: 'Rum\u00e6nien',
    },
    RS: {
      name: 'Serbien',
    },
    RU: {
      name: 'Rusland',
    },
    RW: {
      name: 'Rwanda',
    },
    SA: {
      name: 'Saudi-Arabien',
    },
    SB: {
      name: 'Salomon\u00f8erne',
    },
    SC: {
      name: 'Seychellerne',
    },
    SD: {
      name: 'Sudan',
    },
    SE: {
      name: 'Sverige',
    },
    SG: {
      name: 'Singapore',
    },
    SH: {
      name: 'St. Helena',
    },
    SI: {
      name: 'Slovenien',
    },
    SJ: {
      name: 'Svalbard og Jan Mayen',
    },
    SK: {
      name: 'Slovakiet',
    },
    SL: {
      name: 'Sierra Leone',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Somalia',
    },
    SR: {
      name: 'Surinam',
    },
    SS: {
      name: 'Sydsudan',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 og Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'Syrien',
    },
    SZ: {
      name: 'Eswatini',
      variant: 'Swaziland',
    },
    TA: {
      name: 'Tristan da Cunha',
    },
    TC: {
      name: 'Turks- og Caicos\u00f8erne',
    },
    TD: {
      name: 'Tchad',
    },
    TF: {
      name: 'De Franske Besiddelser i Det Sydlige Indiske Ocean og Antarktis',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Thailand',
    },
    TJ: {
      name: 'Tadsjikistan',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: 'Timor-Leste',
      variant: '\u00d8sttimor',
    },
    TM: {
      name: 'Turkmenistan',
    },
    TN: {
      name: 'Tunesien',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'Tyrkiet',
    },
    TT: {
      name: 'Trinidad og Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tanzania',
    },
    UA: {
      name: 'Ukraine',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'Amerikanske overs\u00f8iske \u00f8er',
    },
    US: {
      name: 'USA',
    },
    UY: {
      name: 'Uruguay',
    },
    UZ: {
      name: 'Usbekistan',
    },
    VA: {
      name: 'Vatikanstaten',
    },
    VC: {
      name: 'Saint Vincent og Grenadinerne',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'De Britiske Jomfru\u00f8er',
    },
    VI: {
      name: 'De Amerikanske Jomfru\u00f8er',
    },
    VN: {
      name: 'Vietnam',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis og Futuna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'pseudo-accenter',
    },
    XB: {
      name: 'pseudo-bidi',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'Yemen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: 'Sydafrika',
    },
    ZM: {
      name: 'Zambia',
    },
    ZW: {
      name: 'Zimbabwe',
    },
  },
  nb: {
    AC: {
      name: 'Ascension',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'De forente arabiske emirater',
    },
    AF: {
      name: 'Afghanistan',
    },
    AG: {
      name: 'Antigua og Barbuda',
    },
    AI: {
      name: 'Anguilla',
    },
    AL: {
      name: 'Albania',
    },
    AM: {
      name: 'Armenia',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Antarktis',
    },
    AR: {
      name: 'Argentina',
    },
    AS: {
      name: 'Amerikansk Samoa',
    },
    AT: {
      name: '\u00d8sterrike',
    },
    AU: {
      name: 'Australia',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: '\u00c5land',
    },
    AZ: {
      name: 'Aserbajdsjan',
    },
    BA: {
      name: 'Bosnia-Hercegovina',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesh',
    },
    BE: {
      name: 'Belgia',
    },
    BF: {
      name: 'Burkina Faso',
    },
    BG: {
      name: 'Bulgaria',
    },
    BH: {
      name: 'Bahrain',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'Saint-Barth\u00e9lemy',
    },
    BM: {
      name: 'Bermuda',
    },
    BN: {
      name: 'Brunei',
    },
    BO: {
      name: 'Bolivia',
    },
    BQ: {
      name: 'Karibisk Nederland',
    },
    BR: {
      name: 'Brasil',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'Bhutan',
    },
    BV: {
      name: 'Bouvet\u00f8ya',
    },
    BW: {
      name: 'Botswana',
    },
    BY: {
      name: 'Hviterussland',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Canada',
    },
    CC: {
      name: 'Kokos\u00f8yene',
    },
    CD: {
      name: 'Kongo-Kinshasa',
      variant: 'Den demokratiske republikken Kongo',
    },
    CF: {
      name: 'Den sentralafrikanske republikk',
    },
    CG: {
      name: 'Kongo-Brazzaville',
      variant: 'Republikken Kongo',
    },
    CH: {
      name: 'Sveits',
    },
    CI: {
      name: 'Elfenbenskysten',
    },
    CK: {
      name: 'Cook\u00f8yene',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Kamerun',
    },
    CN: {
      name: 'Kina',
    },
    CO: {
      name: 'Colombia',
    },
    CP: {
      name: 'Clipperton\u00f8ya',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Cuba',
    },
    CV: {
      name: 'Kapp Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Christmas\u00f8ya',
    },
    CY: {
      name: 'Kypros',
    },
    CZ: {
      name: 'Tsjekkia',
      variant: 'Den tsjekkiske republikk',
    },
    DE: {
      name: 'Tyskland',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Djibouti',
    },
    DK: {
      name: 'Danmark',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Den dominikanske republikk',
    },
    DZ: {
      name: 'Algerie',
    },
    EA: {
      name: 'Ceuta og Melilla',
    },
    EC: {
      name: 'Ecuador',
    },
    EE: {
      name: 'Estland',
    },
    EG: {
      name: 'Egypt',
    },
    EH: {
      name: 'Vest-Sahara',
    },
    ER: {
      name: 'Eritrea',
    },
    ES: {
      name: 'Spania',
    },
    ET: {
      name: 'Etiopia',
    },
    EU: {
      name: 'Den europeiske union',
    },
    EZ: {
      name: 'eurosonen',
    },
    FI: {
      name: 'Finland',
    },
    FJ: {
      name: 'Fiji',
    },
    FK: {
      name: 'Falklands\u00f8yene',
      variant: 'Falklands\u00f8yene (Islas Malvinas)',
    },
    FM: {
      name: 'Mikronesiaf\u00f8derasjonen',
    },
    FO: {
      name: 'F\u00e6r\u00f8yene',
    },
    FR: {
      name: 'Frankrike',
    },
    GA: {
      name: 'Gabon',
    },
    GB: {
      name: 'Storbritannia',
    },
    GD: {
      name: 'Grenada',
    },
    GE: {
      name: 'Georgia',
    },
    GF: {
      name: 'Fransk Guyana',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Ghana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Gr\u00f8nland',
    },
    GM: {
      name: 'Gambia',
    },
    GN: {
      name: 'Guinea',
    },
    GP: {
      name: 'Guadeloupe',
    },
    GQ: {
      name: 'Ekvatorial-Guinea',
    },
    GR: {
      name: 'Hellas',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guinea-Bissau',
    },
    GY: {
      name: 'Guyana',
    },
    HK: {
      name: 'Hongkong S.A.R. Kina',
      short: 'Hongkong',
    },
    HM: {
      name: 'Heard- og McDonald\u00f8yene',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Kroatia',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Ungarn',
    },
    IC: {
      name: 'Kanari\u00f8yene',
    },
    ID: {
      name: 'Indonesia',
    },
    IE: {
      name: 'Irland',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Man',
    },
    IN: {
      name: 'India',
    },
    IO: {
      name: 'Det britiske territoriet i Indiahavet',
    },
    IQ: {
      name: 'Irak',
    },
    IR: {
      name: 'Iran',
    },
    IS: {
      name: 'Island',
    },
    IT: {
      name: 'Italia',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaica',
    },
    JO: {
      name: 'Jordan',
    },
    JP: {
      name: 'Japan',
    },
    KE: {
      name: 'Kenya',
    },
    KG: {
      name: 'Kirgisistan',
    },
    KH: {
      name: 'Kambodsja',
    },
    KI: {
      name: 'Kiribati',
    },
    KM: {
      name: 'Komorene',
    },
    KN: {
      name: 'Saint Kitts og Nevis',
    },
    KP: {
      name: 'Nord-Korea',
    },
    KR: {
      name: 'S\u00f8r-Korea',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Cayman\u00f8yene',
    },
    KZ: {
      name: 'Kasakhstan',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'Libanon',
    },
    LC: {
      name: 'St. Lucia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Liberia',
    },
    LS: {
      name: 'Lesotho',
    },
    LT: {
      name: 'Litauen',
    },
    LU: {
      name: 'Luxemburg',
    },
    LV: {
      name: 'Latvia',
    },
    LY: {
      name: 'Libya',
    },
    MA: {
      name: 'Marokko',
    },
    MC: {
      name: 'Monaco',
    },
    MD: {
      name: 'Moldova',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'Saint-Martin',
    },
    MG: {
      name: 'Madagaskar',
    },
    MH: {
      name: 'Marshall\u00f8yene',
    },
    MK: {
      name: 'Nord-Makedonia',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Myanmar (Burma)',
    },
    MN: {
      name: 'Mongolia',
    },
    MO: {
      name: 'Macao S.A.R. Kina',
      short: 'Macao',
    },
    MP: {
      name: 'Nord-Marianene',
    },
    MQ: {
      name: 'Martinique',
    },
    MR: {
      name: 'Mauritania',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Mauritius',
    },
    MV: {
      name: 'Maldivene',
    },
    MW: {
      name: 'Malawi',
    },
    MX: {
      name: 'Mexico',
    },
    MY: {
      name: 'Malaysia',
    },
    MZ: {
      name: 'Mosambik',
    },
    NA: {
      name: 'Namibia',
    },
    NC: {
      name: 'Ny-Caledonia',
    },
    NE: {
      name: 'Niger',
    },
    NF: {
      name: 'Norfolk\u00f8ya',
    },
    NG: {
      name: 'Nigeria',
    },
    NI: {
      name: 'Nicaragua',
    },
    NL: {
      name: 'Nederland',
    },
    NO: {
      name: 'Norge',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'New Zealand',
    },
    OM: {
      name: 'Oman',
    },
    PA: {
      name: 'Panama',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'Fransk Polynesia',
    },
    PG: {
      name: 'Papua Ny-Guinea',
    },
    PH: {
      name: 'Filippinene',
    },
    PK: {
      name: 'Pakistan',
    },
    PL: {
      name: 'Polen',
    },
    PM: {
      name: 'Saint-Pierre-et-Miquelon',
    },
    PN: {
      name: 'Pitcairn\u00f8yene',
    },
    PR: {
      name: 'Puerto Rico',
    },
    PS: {
      name: 'Det palestinske omr\u00e5det',
      short: 'Palestina',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguay',
    },
    QA: {
      name: 'Qatar',
    },
    QO: {
      name: 'Ytre Oseania',
    },
    RE: {
      name: 'R\u00e9union',
    },
    RO: {
      name: 'Romania',
    },
    RS: {
      name: 'Serbia',
    },
    RU: {
      name: 'Russland',
    },
    RW: {
      name: 'Rwanda',
    },
    SA: {
      name: 'Saudi-Arabia',
    },
    SB: {
      name: 'Salomon\u00f8yene',
    },
    SC: {
      name: 'Seychellene',
    },
    SD: {
      name: 'Sudan',
    },
    SE: {
      name: 'Sverige',
    },
    SG: {
      name: 'Singapore',
    },
    SH: {
      name: 'St. Helena',
    },
    SI: {
      name: 'Slovenia',
    },
    SJ: {
      name: 'Svalbard og Jan Mayen',
    },
    SK: {
      name: 'Slovakia',
    },
    SL: {
      name: 'Sierra Leone',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Somalia',
    },
    SR: {
      name: 'Surinam',
    },
    SS: {
      name: 'S\u00f8r-Sudan',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 og Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'Syria',
    },
    SZ: {
      name: 'Eswatini',
      variant: 'Swaziland',
    },
    TA: {
      name: 'Tristan da Cunha',
    },
    TC: {
      name: 'Turks- og Caicos\u00f8yene',
    },
    TD: {
      name: 'Tsjad',
    },
    TF: {
      name: 'De franske s\u00f8rterritorier',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Thailand',
    },
    TJ: {
      name: 'Tadsjikistan',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: '\u00d8st-Timor',
      variant: 'Timor-Leste',
    },
    TM: {
      name: 'Turkmenistan',
    },
    TN: {
      name: 'Tunisia',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'Tyrkia',
    },
    TT: {
      name: 'Trinidad og Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tanzania',
    },
    UA: {
      name: 'Ukraina',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'USAs ytre \u00f8yer',
    },
    US: {
      name: 'USA',
    },
    UY: {
      name: 'Uruguay',
    },
    UZ: {
      name: 'Usbekistan',
    },
    VA: {
      name: 'Vatikanstaten',
    },
    VC: {
      name: 'St. Vincent og Grenadinene',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'De britiske jomfru\u00f8yene',
    },
    VI: {
      name: 'De amerikanske jomfru\u00f8yene',
    },
    VN: {
      name: 'Vietnam',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis og Futuna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'pseudospr\u00e5k \u2013 aksenter',
    },
    XB: {
      name: 'pseudospr\u00e5k \u2013 RTL',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'Jemen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: 'S\u00f8r-Afrika',
    },
    ZM: {
      name: 'Zambia',
    },
    ZW: {
      name: 'Zimbabwe',
    },
  },
  sv: {
    AC: {
      name: 'Ascension',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'F\u00f6renade Arabemiraten',
    },
    AF: {
      name: 'Afghanistan',
    },
    AG: {
      name: 'Antigua och Barbuda',
    },
    AI: {
      name: 'Anguilla',
    },
    AL: {
      name: 'Albanien',
    },
    AM: {
      name: 'Armenien',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Antarktis',
    },
    AR: {
      name: 'Argentina',
    },
    AS: {
      name: 'Amerikanska Samoa',
    },
    AT: {
      name: '\u00d6sterrike',
    },
    AU: {
      name: 'Australien',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: '\u00c5land',
    },
    AZ: {
      name: 'Azerbajdzjan',
    },
    BA: {
      name: 'Bosnien och Hercegovina',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesh',
    },
    BE: {
      name: 'Belgien',
    },
    BF: {
      name: 'Burkina Faso',
    },
    BG: {
      name: 'Bulgarien',
    },
    BH: {
      name: 'Bahrain',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'S:t Barth\u00e9lemy',
    },
    BM: {
      name: 'Bermuda',
    },
    BN: {
      name: 'Brunei',
    },
    BO: {
      name: 'Bolivia',
    },
    BQ: {
      name: 'Karibiska Nederl\u00e4nderna',
    },
    BR: {
      name: 'Brasilien',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'Bhutan',
    },
    BV: {
      name: 'Bouvet\u00f6n',
    },
    BW: {
      name: 'Botswana',
    },
    BY: {
      name: 'Vitryssland',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Kanada',
    },
    CC: {
      name: 'Kokos\u00f6arna',
    },
    CD: {
      name: 'Kongo-Kinshasa',
      variant: 'Demokratiska republiken Kongo',
    },
    CF: {
      name: 'Centralafrikanska republiken',
    },
    CG: {
      name: 'Kongo-Brazzaville',
      variant: 'Republiken Kongo',
    },
    CH: {
      name: 'Schweiz',
    },
    CI: {
      name: 'C\u00f4te d\u2019Ivoire',
    },
    CK: {
      name: 'Cook\u00f6arna',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Kamerun',
    },
    CN: {
      name: 'Kina',
    },
    CO: {
      name: 'Colombia',
    },
    CP: {
      name: 'Clipperton\u00f6n',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Kuba',
    },
    CV: {
      name: 'Kap Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Jul\u00f6n',
    },
    CY: {
      name: 'Cypern',
    },
    CZ: {
      name: 'Tjeckien',
    },
    DE: {
      name: 'Tyskland',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Djibouti',
    },
    DK: {
      name: 'Danmark',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Dominikanska republiken',
    },
    DZ: {
      name: 'Algeriet',
    },
    EA: {
      name: 'Ceuta och Melilla',
    },
    EC: {
      name: 'Ecuador',
    },
    EE: {
      name: 'Estland',
    },
    EG: {
      name: 'Egypten',
    },
    EH: {
      name: 'V\u00e4stsahara',
    },
    ER: {
      name: 'Eritrea',
    },
    ES: {
      name: 'Spanien',
    },
    ET: {
      name: 'Etiopien',
    },
    EU: {
      name: 'Europeiska unionen',
    },
    EZ: {
      name: 'eurozonen',
    },
    FI: {
      name: 'Finland',
    },
    FJ: {
      name: 'Fiji',
    },
    FK: {
      name: 'Falklands\u00f6arna',
    },
    FM: {
      name: 'Mikronesien',
    },
    FO: {
      name: 'F\u00e4r\u00f6arna',
    },
    FR: {
      name: 'Frankrike',
    },
    GA: {
      name: 'Gabon',
    },
    GB: {
      name: 'Storbritannien',
    },
    GD: {
      name: 'Grenada',
    },
    GE: {
      name: 'Georgien',
    },
    GF: {
      name: 'Franska Guyana',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Ghana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Gr\u00f6nland',
    },
    GM: {
      name: 'Gambia',
    },
    GN: {
      name: 'Guinea',
    },
    GP: {
      name: 'Guadeloupe',
    },
    GQ: {
      name: 'Ekvatorialguinea',
    },
    GR: {
      name: 'Grekland',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guinea-Bissau',
    },
    GY: {
      name: 'Guyana',
    },
    HK: {
      name: 'Hongkong',
    },
    HM: {
      name: 'Heard\u00f6n och McDonald\u00f6arna',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Kroatien',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Ungern',
    },
    IC: {
      name: 'Kanarie\u00f6arna',
    },
    ID: {
      name: 'Indonesien',
    },
    IE: {
      name: 'Irland',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Isle of Man',
    },
    IN: {
      name: 'Indien',
    },
    IO: {
      name: 'Brittiska territoriet i Indiska oceanen',
    },
    IQ: {
      name: 'Irak',
    },
    IR: {
      name: 'Iran',
    },
    IS: {
      name: 'Island',
    },
    IT: {
      name: 'Italien',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaica',
    },
    JO: {
      name: 'Jordanien',
    },
    JP: {
      name: 'Japan',
    },
    KE: {
      name: 'Kenya',
    },
    KG: {
      name: 'Kirgizistan',
    },
    KH: {
      name: 'Kambodja',
    },
    KI: {
      name: 'Kiribati',
    },
    KM: {
      name: 'Komorerna',
    },
    KN: {
      name: 'S:t Kitts och Nevis',
    },
    KP: {
      name: 'Nordkorea',
    },
    KR: {
      name: 'Sydkorea',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Cayman\u00f6arna',
    },
    KZ: {
      name: 'Kazakstan',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'Libanon',
    },
    LC: {
      name: 'S:t Lucia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Liberia',
    },
    LS: {
      name: 'Lesotho',
    },
    LT: {
      name: 'Litauen',
    },
    LU: {
      name: 'Luxemburg',
    },
    LV: {
      name: 'Lettland',
    },
    LY: {
      name: 'Libyen',
    },
    MA: {
      name: 'Marocko',
    },
    MC: {
      name: 'Monaco',
    },
    MD: {
      name: 'Moldavien',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'Saint-Martin',
    },
    MG: {
      name: 'Madagaskar',
    },
    MH: {
      name: 'Marshall\u00f6arna',
    },
    MK: {
      name: 'Nordmakedonien',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Myanmar (Burma)',
    },
    MN: {
      name: 'Mongoliet',
    },
    MO: {
      name: 'Macao',
    },
    MP: {
      name: 'Nordmarianerna',
    },
    MQ: {
      name: 'Martinique',
    },
    MR: {
      name: 'Mauretanien',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Mauritius',
    },
    MV: {
      name: 'Maldiverna',
    },
    MW: {
      name: 'Malawi',
    },
    MX: {
      name: 'Mexiko',
    },
    MY: {
      name: 'Malaysia',
    },
    MZ: {
      name: 'Mo\u00e7ambique',
    },
    NA: {
      name: 'Namibia',
    },
    NC: {
      name: 'Nya Kaledonien',
    },
    NE: {
      name: 'Niger',
    },
    NF: {
      name: 'Norfolk\u00f6n',
    },
    NG: {
      name: 'Nigeria',
    },
    NI: {
      name: 'Nicaragua',
    },
    NL: {
      name: 'Nederl\u00e4nderna',
    },
    NO: {
      name: 'Norge',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'Nya Zeeland',
    },
    OM: {
      name: 'Oman',
    },
    PA: {
      name: 'Panama',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'Franska Polynesien',
    },
    PG: {
      name: 'Papua Nya Guinea',
    },
    PH: {
      name: 'Filippinerna',
    },
    PK: {
      name: 'Pakistan',
    },
    PL: {
      name: 'Polen',
    },
    PM: {
      name: 'S:t Pierre och Miquelon',
    },
    PN: {
      name: 'Pitcairn\u00f6arna',
    },
    PR: {
      name: 'Puerto Rico',
    },
    PS: {
      name: 'Palestinska territorierna',
      short: 'Palestina',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguay',
    },
    QA: {
      name: 'Qatar',
    },
    QO: {
      name: 'yttre \u00f6ar i Oceanien',
    },
    RE: {
      name: 'R\u00e9union',
    },
    RO: {
      name: 'Rum\u00e4nien',
    },
    RS: {
      name: 'Serbien',
    },
    RU: {
      name: 'Ryssland',
    },
    RW: {
      name: 'Rwanda',
    },
    SA: {
      name: 'Saudiarabien',
    },
    SB: {
      name: 'Salomon\u00f6arna',
    },
    SC: {
      name: 'Seychellerna',
    },
    SD: {
      name: 'Sudan',
    },
    SE: {
      name: 'Sverige',
    },
    SG: {
      name: 'Singapore',
    },
    SH: {
      name: 'S:t Helena',
    },
    SI: {
      name: 'Slovenien',
    },
    SJ: {
      name: 'Svalbard och Jan Mayen',
    },
    SK: {
      name: 'Slovakien',
    },
    SL: {
      name: 'Sierra Leone',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Somalia',
    },
    SR: {
      name: 'Surinam',
    },
    SS: {
      name: 'Sydsudan',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 och Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'Syrien',
    },
    SZ: {
      name: 'Swaziland',
      variant: 'Eswatini',
    },
    TA: {
      name: 'Tristan da Cunha',
    },
    TC: {
      name: 'Turks- och Caicos\u00f6arna',
    },
    TD: {
      name: 'Tchad',
    },
    TF: {
      name: 'Franska sydterritorierna',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Thailand',
    },
    TJ: {
      name: 'Tadzjikistan',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: '\u00d6sttimor',
      variant: 'Timor-Leste',
    },
    TM: {
      name: 'Turkmenistan',
    },
    TN: {
      name: 'Tunisien',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'Turkiet',
    },
    TT: {
      name: 'Trinidad och Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tanzania',
    },
    UA: {
      name: 'Ukraina',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'USA:s yttre \u00f6ar',
    },
    US: {
      name: 'USA',
    },
    UY: {
      name: 'Uruguay',
    },
    UZ: {
      name: 'Uzbekistan',
    },
    VA: {
      name: 'Vatikanstaten',
    },
    VC: {
      name: 'S:t Vincent och Grenadinerna',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'Brittiska Jungfru\u00f6arna',
    },
    VI: {
      name: 'Amerikanska Jungfru\u00f6arna',
    },
    VN: {
      name: 'Vietnam',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis- och Futuna\u00f6arna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'fejkade accenter (f\u00f6r test)',
    },
    XB: {
      name: 'fejkad bidi (f\u00f6r test)',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'Jemen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: 'Sydafrika',
    },
    ZM: {
      name: 'Zambia',
    },
    ZW: {
      name: 'Zimbabwe',
    },
  },
  de: {
    AC: {
      name: 'Ascension',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'Vereinigte Arabische Emirate',
    },
    AF: {
      name: 'Afghanistan',
    },
    AG: {
      name: 'Antigua und Barbuda',
    },
    AI: {
      name: 'Anguilla',
    },
    AL: {
      name: 'Albanien',
    },
    AM: {
      name: 'Armenien',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Antarktis',
    },
    AR: {
      name: 'Argentinien',
    },
    AS: {
      name: 'Amerikanisch-Samoa',
    },
    AT: {
      name: '\u00d6sterreich',
    },
    AU: {
      name: 'Australien',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: '\u00c5landinseln',
    },
    AZ: {
      name: 'Aserbaidschan',
    },
    BA: {
      name: 'Bosnien und Herzegowina',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesch',
    },
    BE: {
      name: 'Belgien',
    },
    BF: {
      name: 'Burkina Faso',
    },
    BG: {
      name: 'Bulgarien',
    },
    BH: {
      name: 'Bahrain',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'St. Barth\u00e9lemy',
    },
    BM: {
      name: 'Bermuda',
    },
    BN: {
      name: 'Brunei Darussalam',
    },
    BO: {
      name: 'Bolivien',
    },
    BQ: {
      name: 'Bonaire, Sint Eustatius und Saba',
    },
    BR: {
      name: 'Brasilien',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'Bhutan',
    },
    BV: {
      name: 'Bouvetinsel',
    },
    BW: {
      name: 'Botsuana',
    },
    BY: {
      name: 'Belarus',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Kanada',
    },
    CC: {
      name: 'Kokosinseln',
    },
    CD: {
      name: 'Kongo-Kinshasa',
      variant: 'Kongo (Demokratische Republik)',
    },
    CF: {
      name: 'Zentralafrikanische Republik',
    },
    CG: {
      name: 'Kongo-Brazzaville',
      variant: 'Kongo (Republik)',
    },
    CH: {
      name: 'Schweiz',
    },
    CI: {
      name: 'C\u00f4te d\u2019Ivoire',
      variant: 'Elfenbeink\u00fcste',
    },
    CK: {
      name: 'Cookinseln',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Kamerun',
    },
    CN: {
      name: 'China',
    },
    CO: {
      name: 'Kolumbien',
    },
    CP: {
      name: 'Clipperton-Insel',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Kuba',
    },
    CV: {
      name: 'Cabo Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Weihnachtsinsel',
    },
    CY: {
      name: 'Zypern',
    },
    CZ: {
      name: 'Tschechien',
      variant: 'Tschechische Republik',
    },
    DE: {
      name: 'Deutschland',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Dschibuti',
    },
    DK: {
      name: 'D\u00e4nemark',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Dominikanische Republik',
    },
    DZ: {
      name: 'Algerien',
    },
    EA: {
      name: 'Ceuta und Melilla',
    },
    EC: {
      name: 'Ecuador',
    },
    EE: {
      name: 'Estland',
    },
    EG: {
      name: '\u00c4gypten',
    },
    EH: {
      name: 'Westsahara',
    },
    ER: {
      name: 'Eritrea',
    },
    ES: {
      name: 'Spanien',
    },
    ET: {
      name: '\u00c4thiopien',
    },
    EU: {
      name: 'Europ\u00e4ische Union',
    },
    EZ: {
      name: 'Eurozone',
    },
    FI: {
      name: 'Finnland',
    },
    FJ: {
      name: 'Fidschi',
    },
    FK: {
      name: 'Falklandinseln',
      variant: 'Falklandinseln (Malwinen)',
    },
    FM: {
      name: 'Mikronesien',
    },
    FO: {
      name: 'F\u00e4r\u00f6er',
    },
    FR: {
      name: 'Frankreich',
    },
    GA: {
      name: 'Gabun',
    },
    GB: {
      name: 'Vereinigtes K\u00f6nigreich',
      short: 'GB',
    },
    GD: {
      name: 'Grenada',
    },
    GE: {
      name: 'Georgien',
    },
    GF: {
      name: 'Franz\u00f6sisch-Guayana',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Ghana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Gr\u00f6nland',
    },
    GM: {
      name: 'Gambia',
    },
    GN: {
      name: 'Guinea',
    },
    GP: {
      name: 'Guadeloupe',
    },
    GQ: {
      name: '\u00c4quatorialguinea',
    },
    GR: {
      name: 'Griechenland',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guinea-Bissau',
    },
    GY: {
      name: 'Guyana',
    },
    HK: {
      name: 'Sonderverwaltungsregion Hongkong',
      short: 'Hongkong',
    },
    HM: {
      name: 'Heard und McDonaldinseln',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Kroatien',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Ungarn',
    },
    IC: {
      name: 'Kanarische Inseln',
    },
    ID: {
      name: 'Indonesien',
    },
    IE: {
      name: 'Irland',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Isle of Man',
    },
    IN: {
      name: 'Indien',
    },
    IO: {
      name: 'Britisches Territorium im Indischen Ozean',
    },
    IQ: {
      name: 'Irak',
    },
    IR: {
      name: 'Iran',
    },
    IS: {
      name: 'Island',
    },
    IT: {
      name: 'Italien',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaika',
    },
    JO: {
      name: 'Jordanien',
    },
    JP: {
      name: 'Japan',
    },
    KE: {
      name: 'Kenia',
    },
    KG: {
      name: 'Kirgisistan',
    },
    KH: {
      name: 'Kambodscha',
    },
    KI: {
      name: 'Kiribati',
    },
    KM: {
      name: 'Komoren',
    },
    KN: {
      name: 'St. Kitts und Nevis',
    },
    KP: {
      name: 'Nordkorea',
    },
    KR: {
      name: 'S\u00fcdkorea',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Kaimaninseln',
    },
    KZ: {
      name: 'Kasachstan',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'Libanon',
    },
    LC: {
      name: 'St. Lucia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Liberia',
    },
    LS: {
      name: 'Lesotho',
    },
    LT: {
      name: 'Litauen',
    },
    LU: {
      name: 'Luxemburg',
    },
    LV: {
      name: 'Lettland',
    },
    LY: {
      name: 'Libyen',
    },
    MA: {
      name: 'Marokko',
    },
    MC: {
      name: 'Monaco',
    },
    MD: {
      name: 'Republik Moldau',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'St. Martin',
    },
    MG: {
      name: 'Madagaskar',
    },
    MH: {
      name: 'Marshallinseln',
    },
    MK: {
      name: 'Nordmazedonien',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Myanmar',
    },
    MN: {
      name: 'Mongolei',
    },
    MO: {
      name: 'Sonderverwaltungsregion Macau',
      short: 'Macau',
    },
    MP: {
      name: 'N\u00f6rdliche Marianen',
    },
    MQ: {
      name: 'Martinique',
    },
    MR: {
      name: 'Mauretanien',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Mauritius',
    },
    MV: {
      name: 'Malediven',
    },
    MW: {
      name: 'Malawi',
    },
    MX: {
      name: 'Mexiko',
    },
    MY: {
      name: 'Malaysia',
    },
    MZ: {
      name: 'Mosambik',
    },
    NA: {
      name: 'Namibia',
    },
    NC: {
      name: 'Neukaledonien',
    },
    NE: {
      name: 'Niger',
    },
    NF: {
      name: 'Norfolkinsel',
    },
    NG: {
      name: 'Nigeria',
    },
    NI: {
      name: 'Nicaragua',
    },
    NL: {
      name: 'Niederlande',
    },
    NO: {
      name: 'Norwegen',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'Neuseeland',
    },
    OM: {
      name: 'Oman',
    },
    PA: {
      name: 'Panama',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'Franz\u00f6sisch-Polynesien',
    },
    PG: {
      name: 'Papua-Neuguinea',
    },
    PH: {
      name: 'Philippinen',
    },
    PK: {
      name: 'Pakistan',
    },
    PL: {
      name: 'Polen',
    },
    PM: {
      name: 'St. Pierre und Miquelon',
    },
    PN: {
      name: 'Pitcairninseln',
    },
    PR: {
      name: 'Puerto Rico',
    },
    PS: {
      name: 'Pal\u00e4stinensische Autonomiegebiete',
      short: 'Pal\u00e4stina',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguay',
    },
    QA: {
      name: 'Katar',
    },
    QO: {
      name: '\u00c4u\u00dferes Ozeanien',
    },
    RE: {
      name: 'R\u00e9union',
    },
    RO: {
      name: 'Rum\u00e4nien',
    },
    RS: {
      name: 'Serbien',
    },
    RU: {
      name: 'Russland',
    },
    RW: {
      name: 'Ruanda',
    },
    SA: {
      name: 'Saudi-Arabien',
    },
    SB: {
      name: 'Salomonen',
    },
    SC: {
      name: 'Seychellen',
    },
    SD: {
      name: 'Sudan',
    },
    SE: {
      name: 'Schweden',
    },
    SG: {
      name: 'Singapur',
    },
    SH: {
      name: 'St. Helena',
    },
    SI: {
      name: 'Slowenien',
    },
    SJ: {
      name: 'Spitzbergen und Jan Mayen',
    },
    SK: {
      name: 'Slowakei',
    },
    SL: {
      name: 'Sierra Leone',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Somalia',
    },
    SR: {
      name: 'Suriname',
    },
    SS: {
      name: 'S\u00fcdsudan',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 und Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'Syrien',
    },
    SZ: {
      name: 'Eswatini',
      variant: 'Swasiland',
    },
    TA: {
      name: 'Tristan da Cunha',
    },
    TC: {
      name: 'Turks- und Caicosinseln',
    },
    TD: {
      name: 'Tschad',
    },
    TF: {
      name: 'Franz\u00f6sische S\u00fcd- und Antarktisgebiete',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Thailand',
    },
    TJ: {
      name: 'Tadschikistan',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: 'Timor-Leste',
      variant: 'Osttimor',
    },
    TM: {
      name: 'Turkmenistan',
    },
    TN: {
      name: 'Tunesien',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'T\u00fcrkei',
    },
    TT: {
      name: 'Trinidad und Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tansania',
    },
    UA: {
      name: 'Ukraine',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'Amerikanische \u00dcberseeinseln',
    },
    US: {
      name: 'Vereinigte Staaten',
      short: 'USA',
    },
    UY: {
      name: 'Uruguay',
    },
    UZ: {
      name: 'Usbekistan',
    },
    VA: {
      name: 'Vatikanstadt',
    },
    VC: {
      name: 'St. Vincent und die Grenadinen',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'Britische Jungferninseln',
    },
    VI: {
      name: 'Amerikanische Jungferninseln',
    },
    VN: {
      name: 'Vietnam',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis und Futuna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'Pseudo-Akzente',
    },
    XB: {
      name: 'Pseudo-Bidi',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'Jemen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: 'S\u00fcdafrika',
    },
    ZM: {
      name: 'Sambia',
    },
    ZW: {
      name: 'Simbabwe',
    },
  },
  pt: {
    AC: {
      name: 'Ilha de Ascens\u00e3o',
    },
    AD: {
      name: 'Andorra',
    },
    AE: {
      name: 'Emirados \u00c1rabes Unidos',
    },
    AF: {
      name: 'Afeganist\u00e3o',
    },
    AG: {
      name: 'Ant\u00edgua e Barbuda',
    },
    AI: {
      name: 'Anguila',
    },
    AL: {
      name: 'Alb\u00e2nia',
    },
    AM: {
      name: 'Arm\u00eania',
    },
    AO: {
      name: 'Angola',
    },
    AQ: {
      name: 'Ant\u00e1rtida',
    },
    AR: {
      name: 'Argentina',
    },
    AS: {
      name: 'Samoa Americana',
    },
    AT: {
      name: '\u00c1ustria',
    },
    AU: {
      name: 'Austr\u00e1lia',
    },
    AW: {
      name: 'Aruba',
    },
    AX: {
      name: 'Ilhas Aland',
    },
    AZ: {
      name: 'Azerbaij\u00e3o',
    },
    BA: {
      name: 'B\u00f3snia e Herzegovina',
    },
    BB: {
      name: 'Barbados',
    },
    BD: {
      name: 'Bangladesh',
    },
    BE: {
      name: 'B\u00e9lgica',
    },
    BF: {
      name: 'Burquina Faso',
    },
    BG: {
      name: 'Bulg\u00e1ria',
    },
    BH: {
      name: 'Bahrein',
    },
    BI: {
      name: 'Burundi',
    },
    BJ: {
      name: 'Benin',
    },
    BL: {
      name: 'S\u00e3o Bartolomeu',
    },
    BM: {
      name: 'Bermudas',
    },
    BN: {
      name: 'Brunei',
    },
    BO: {
      name: 'Bol\u00edvia',
    },
    BQ: {
      name: 'Pa\u00edses Baixos Caribenhos',
    },
    BR: {
      name: 'Brasil',
    },
    BS: {
      name: 'Bahamas',
    },
    BT: {
      name: 'But\u00e3o',
    },
    BV: {
      name: 'Ilha Bouvet',
    },
    BW: {
      name: 'Botsuana',
    },
    BY: {
      name: 'Bielorr\u00fassia',
    },
    BZ: {
      name: 'Belize',
    },
    CA: {
      name: 'Canad\u00e1',
    },
    CC: {
      name: 'Ilhas Cocos (Keeling)',
    },
    CD: {
      name: 'Congo - Kinshasa',
      variant: 'Rep\u00fablica Democr\u00e1tica do Congo',
    },
    CF: {
      name: 'Rep\u00fablica Centro-Africana',
    },
    CG: {
      name: 'Rep\u00fablica do Congo',
      variant: 'Congo',
    },
    CH: {
      name: 'Su\u00ed\u00e7a',
    },
    CI: {
      name: 'Costa do Marfim',
      variant: 'C\u00f4te d\u2019Ivoire',
    },
    CK: {
      name: 'Ilhas Cook',
    },
    CL: {
      name: 'Chile',
    },
    CM: {
      name: 'Camar\u00f5es',
    },
    CN: {
      name: 'China',
    },
    CO: {
      name: 'Col\u00f4mbia',
    },
    CP: {
      name: 'Ilha de Clipperton',
    },
    CR: {
      name: 'Costa Rica',
    },
    CU: {
      name: 'Cuba',
    },
    CV: {
      name: 'Cabo Verde',
    },
    CW: {
      name: 'Cura\u00e7ao',
    },
    CX: {
      name: 'Ilha Christmas',
    },
    CY: {
      name: 'Chipre',
    },
    CZ: {
      name: 'Tch\u00e9quia',
      variant: 'Rep\u00fablica Tcheca',
    },
    DE: {
      name: 'Alemanha',
    },
    DG: {
      name: 'Diego Garcia',
    },
    DJ: {
      name: 'Djibuti',
    },
    DK: {
      name: 'Dinamarca',
    },
    DM: {
      name: 'Dominica',
    },
    DO: {
      name: 'Rep\u00fablica Dominicana',
    },
    DZ: {
      name: 'Arg\u00e9lia',
    },
    EA: {
      name: 'Ceuta e Melilla',
    },
    EC: {
      name: 'Equador',
    },
    EE: {
      name: 'Est\u00f4nia',
    },
    EG: {
      name: 'Egito',
    },
    EH: {
      name: 'Saara Ocidental',
    },
    ER: {
      name: 'Eritreia',
    },
    ES: {
      name: 'Espanha',
    },
    ET: {
      name: 'Eti\u00f3pia',
    },
    EU: {
      name: 'Uni\u00e3o Europeia',
    },
    EZ: {
      name: 'zona do euro',
    },
    FI: {
      name: 'Finl\u00e2ndia',
    },
    FJ: {
      name: 'Fiji',
    },
    FK: {
      name: 'Ilhas Malvinas',
      variant: 'Ilhas Malvinas (Ilhas Falkland)',
    },
    FM: {
      name: 'Micron\u00e9sia',
    },
    FO: {
      name: 'Ilhas Faroe',
    },
    FR: {
      name: 'Fran\u00e7a',
    },
    GA: {
      name: 'Gab\u00e3o',
    },
    GB: {
      name: 'Reino Unido',
    },
    GD: {
      name: 'Granada',
    },
    GE: {
      name: 'Ge\u00f3rgia',
    },
    GF: {
      name: 'Guiana Francesa',
    },
    GG: {
      name: 'Guernsey',
    },
    GH: {
      name: 'Gana',
    },
    GI: {
      name: 'Gibraltar',
    },
    GL: {
      name: 'Groenl\u00e2ndia',
    },
    GM: {
      name: 'G\u00e2mbia',
    },
    GN: {
      name: 'Guin\u00e9',
    },
    GP: {
      name: 'Guadalupe',
    },
    GQ: {
      name: 'Guin\u00e9 Equatorial',
    },
    GR: {
      name: 'Gr\u00e9cia',
    },
    GS: {
      name: 'Ilhas Ge\u00f3rgia do Sul e Sandwich do Sul',
    },
    GT: {
      name: 'Guatemala',
    },
    GU: {
      name: 'Guam',
    },
    GW: {
      name: 'Guin\u00e9-Bissau',
    },
    GY: {
      name: 'Guiana',
    },
    HK: {
      name: 'Hong Kong, RAE da China',
      short: 'Hong Kong',
    },
    HM: {
      name: 'Ilhas Heard e McDonald',
    },
    HN: {
      name: 'Honduras',
    },
    HR: {
      name: 'Cro\u00e1cia',
    },
    HT: {
      name: 'Haiti',
    },
    HU: {
      name: 'Hungria',
    },
    IC: {
      name: 'Ilhas Can\u00e1rias',
    },
    ID: {
      name: 'Indon\u00e9sia',
    },
    IE: {
      name: 'Irlanda',
    },
    IL: {
      name: 'Israel',
    },
    IM: {
      name: 'Ilha de Man',
    },
    IN: {
      name: '\u00cdndia',
    },
    IO: {
      name: 'Territ\u00f3rio Brit\u00e2nico do Oceano \u00cdndico',
    },
    IQ: {
      name: 'Iraque',
    },
    IR: {
      name: 'Ir\u00e3',
    },
    IS: {
      name: 'Isl\u00e2ndia',
    },
    IT: {
      name: 'It\u00e1lia',
    },
    JE: {
      name: 'Jersey',
    },
    JM: {
      name: 'Jamaica',
    },
    JO: {
      name: 'Jord\u00e2nia',
    },
    JP: {
      name: 'Jap\u00e3o',
    },
    KE: {
      name: 'Qu\u00eania',
    },
    KG: {
      name: 'Quirguist\u00e3o',
    },
    KH: {
      name: 'Camboja',
    },
    KI: {
      name: 'Quiribati',
    },
    KM: {
      name: 'Comores',
    },
    KN: {
      name: 'S\u00e3o Crist\u00f3v\u00e3o e N\u00e9vis',
    },
    KP: {
      name: 'Coreia do Norte',
    },
    KR: {
      name: 'Coreia do Sul',
    },
    KW: {
      name: 'Kuwait',
    },
    KY: {
      name: 'Ilhas Cayman',
    },
    KZ: {
      name: 'Cazaquist\u00e3o',
    },
    LA: {
      name: 'Laos',
    },
    LB: {
      name: 'L\u00edbano',
    },
    LC: {
      name: 'Santa L\u00facia',
    },
    LI: {
      name: 'Liechtenstein',
    },
    LK: {
      name: 'Sri Lanka',
    },
    LR: {
      name: 'Lib\u00e9ria',
    },
    LS: {
      name: 'Lesoto',
    },
    LT: {
      name: 'Litu\u00e2nia',
    },
    LU: {
      name: 'Luxemburgo',
    },
    LV: {
      name: 'Let\u00f4nia',
    },
    LY: {
      name: 'L\u00edbia',
    },
    MA: {
      name: 'Marrocos',
    },
    MC: {
      name: 'M\u00f4naco',
    },
    MD: {
      name: 'Moldova',
    },
    ME: {
      name: 'Montenegro',
    },
    MF: {
      name: 'S\u00e3o Martinho',
    },
    MG: {
      name: 'Madagascar',
    },
    MH: {
      name: 'Ilhas Marshall',
    },
    MK: {
      name: 'Maced\u00f4nia do Norte',
    },
    ML: {
      name: 'Mali',
    },
    MM: {
      name: 'Mianmar (Birm\u00e2nia)',
    },
    MN: {
      name: 'Mong\u00f3lia',
    },
    MO: {
      name: 'Macau, RAE da China',
      short: 'Macau',
    },
    MP: {
      name: 'Ilhas Marianas do Norte',
    },
    MQ: {
      name: 'Martinica',
    },
    MR: {
      name: 'Maurit\u00e2nia',
    },
    MS: {
      name: 'Montserrat',
    },
    MT: {
      name: 'Malta',
    },
    MU: {
      name: 'Maur\u00edcio',
    },
    MV: {
      name: 'Maldivas',
    },
    MW: {
      name: 'Malaui',
    },
    MX: {
      name: 'M\u00e9xico',
    },
    MY: {
      name: 'Mal\u00e1sia',
    },
    MZ: {
      name: 'Mo\u00e7ambique',
    },
    NA: {
      name: 'Nam\u00edbia',
    },
    NC: {
      name: 'Nova Caled\u00f4nia',
    },
    NE: {
      name: 'N\u00edger',
    },
    NF: {
      name: 'Ilha Norfolk',
    },
    NG: {
      name: 'Nig\u00e9ria',
    },
    NI: {
      name: 'Nicar\u00e1gua',
    },
    NL: {
      name: 'Pa\u00edses Baixos',
    },
    NO: {
      name: 'Noruega',
    },
    NP: {
      name: 'Nepal',
    },
    NR: {
      name: 'Nauru',
    },
    NU: {
      name: 'Niue',
    },
    NZ: {
      name: 'Nova Zel\u00e2ndia',
    },
    OM: {
      name: 'Om\u00e3',
    },
    PA: {
      name: 'Panam\u00e1',
    },
    PE: {
      name: 'Peru',
    },
    PF: {
      name: 'Polin\u00e9sia Francesa',
    },
    PG: {
      name: 'Papua-Nova Guin\u00e9',
    },
    PH: {
      name: 'Filipinas',
    },
    PK: {
      name: 'Paquist\u00e3o',
    },
    PL: {
      name: 'Pol\u00f4nia',
    },
    PM: {
      name: 'S\u00e3o Pedro e Miquel\u00e3o',
    },
    PN: {
      name: 'Ilhas Pitcairn',
    },
    PR: {
      name: 'Porto Rico',
    },
    PS: {
      name: 'Territ\u00f3rios palestinos',
      short: 'Palestina',
    },
    PT: {
      name: 'Portugal',
    },
    PW: {
      name: 'Palau',
    },
    PY: {
      name: 'Paraguai',
    },
    QA: {
      name: 'Catar',
    },
    QO: {
      name: 'Oceania Remota',
    },
    RE: {
      name: 'Reuni\u00e3o',
    },
    RO: {
      name: 'Rom\u00eania',
    },
    RS: {
      name: 'S\u00e9rvia',
    },
    RU: {
      name: 'R\u00fassia',
    },
    RW: {
      name: 'Ruanda',
    },
    SA: {
      name: 'Ar\u00e1bia Saudita',
    },
    SB: {
      name: 'Ilhas Salom\u00e3o',
    },
    SC: {
      name: 'Seicheles',
    },
    SD: {
      name: 'Sud\u00e3o',
    },
    SE: {
      name: 'Su\u00e9cia',
    },
    SG: {
      name: 'Singapura',
    },
    SH: {
      name: 'Santa Helena',
    },
    SI: {
      name: 'Eslov\u00eania',
    },
    SJ: {
      name: 'Svalbard e Jan Mayen',
    },
    SK: {
      name: 'Eslov\u00e1quia',
    },
    SL: {
      name: 'Serra Leoa',
    },
    SM: {
      name: 'San Marino',
    },
    SN: {
      name: 'Senegal',
    },
    SO: {
      name: 'Som\u00e1lia',
    },
    SR: {
      name: 'Suriname',
    },
    SS: {
      name: 'Sud\u00e3o do Sul',
    },
    ST: {
      name: 'S\u00e3o Tom\u00e9 e Pr\u00edncipe',
    },
    SV: {
      name: 'El Salvador',
    },
    SX: {
      name: 'Sint Maarten',
    },
    SY: {
      name: 'S\u00edria',
    },
    SZ: {
      name: 'Essuat\u00edni',
      variant: 'Suazil\u00e2ndia',
    },
    TA: {
      name: 'Trist\u00e3o da Cunha',
    },
    TC: {
      name: 'Ilhas Turcas e Caicos',
    },
    TD: {
      name: 'Chade',
    },
    TF: {
      name: 'Territ\u00f3rios Franceses do Sul',
    },
    TG: {
      name: 'Togo',
    },
    TH: {
      name: 'Tail\u00e2ndia',
    },
    TJ: {
      name: 'Tadjiquist\u00e3o',
    },
    TK: {
      name: 'Tokelau',
    },
    TL: {
      name: 'Timor-Leste',
      variant: 'Rep\u00fablica Democr\u00e1tica de Timor-Leste',
    },
    TM: {
      name: 'Turcomenist\u00e3o',
    },
    TN: {
      name: 'Tun\u00edsia',
    },
    TO: {
      name: 'Tonga',
    },
    TR: {
      name: 'Turquia',
    },
    TT: {
      name: 'Trinidad e Tobago',
    },
    TV: {
      name: 'Tuvalu',
    },
    TW: {
      name: 'Taiwan',
    },
    TZ: {
      name: 'Tanz\u00e2nia',
    },
    UA: {
      name: 'Ucr\u00e2nia',
    },
    UG: {
      name: 'Uganda',
    },
    UM: {
      name: 'Ilhas Menores Distantes dos EUA',
    },
    UN: {
      name: 'Na\u00e7\u00f5es Unidas',
      short: 'ONU',
    },
    US: {
      name: 'Estados Unidos',
      short: 'EUA',
    },
    UY: {
      name: 'Uruguai',
    },
    UZ: {
      name: 'Uzbequist\u00e3o',
    },
    VA: {
      name: 'Cidade do Vaticano',
    },
    VC: {
      name: 'S\u00e3o Vicente e Granadinas',
    },
    VE: {
      name: 'Venezuela',
    },
    VG: {
      name: 'Ilhas Virgens Brit\u00e2nicas',
    },
    VI: {
      name: 'Ilhas Virgens Americanas',
    },
    VN: {
      name: 'Vietn\u00e3',
    },
    VU: {
      name: 'Vanuatu',
    },
    WF: {
      name: 'Wallis e Futuna',
    },
    WS: {
      name: 'Samoa',
    },
    XA: {
      name: 'Pseudossotaques',
    },
    XB: {
      name: 'Pseudobidirecional',
    },
    XK: {
      name: 'Kosovo',
    },
    YE: {
      name: 'I\u00eamen',
    },
    YT: {
      name: 'Mayotte',
    },
    ZA: {
      name: '\u00c1frica do Sul',
    },
    ZM: {
      name: 'Z\u00e2mbia',
    },
    ZW: {
      name: 'Zimb\u00e1bue',
    },
    ZZ: {
      name: 'Regi\u00e3o desconhecida',
    },
  },
};

export default {
  name: 'CountrySelect',
  data() {
    return {
      form: {
        country: this.country,
      },
    };
  },
  props: ['value', 'disabled'],
  computed: {
    ...mapGetters('appSettings', [
      'locale',
    ]),
    countries() {
      const translatedCountries = countryNames[this.locale];
      const countryObjects = _values(
          _mapValues(translatedCountries, (country, countryCode) => ({
            name: country.name,
            code: countryCode,
          })),
      );
      return _sortBy(countryObjects, 'name');
    },
  },
  created() {
    this.form.country = this.value;
  },
  watch: {
    'form.country': function (newCountry) {
      this.$emit('input', newCountry);
    },
    value(newPropCountry) {
      this.form.country = newPropCountry;
    },
  },

};


</script>
