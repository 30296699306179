<template>

  <div class="membership">
    <div
        :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
    >

      <h2>{{ $t('profile.myMembershipLabel') }}</h2>
      <div class="space2"></div>
      <ul class="widget__list--standard">
        <li v-for="membership in memberships" :key="'membership_' + membership.id">
          <p class="upper">
            {{ membership.membership_type.name }} {{
              membership.payment_option ? ' (' + membership.payment_option.name +
                  ')' : ''
            }}
          </p>
          <div v-if="membership.start_date > todayIso">
            {{ $t('membership.StartsOnDate', { date: formatDate(membership.start_date) }) }}
          </div>
          <div v-if="membership.current_or_upcoming_membership_pause">
            <div class="space2"></div>
            <p>
              <span v-if="membership.current_membership_pause">
                {{ $t('membership.Paused') }}
              </span>
              <span v-else>
                {{ $t('membership.ScheduledMembershipPause') }}:
              </span>
              {{ formatDate(membership.current_or_upcoming_membership_pause.start_date) }}
              - {{
                membership.current_or_upcoming_membership_pause.end_date
                    ? formatDate(membership.current_or_upcoming_membership_pause.end_date)
                    : '?'
              }}
            </p>
          </div>
          <div class="space2"></div>
          <div v-if="membership.next_payment">
            <p>
              {{ $t('profile.renewedNextTime') }}: {{
                membership.next_payment.date ? formatDate(membership.next_payment.date) : '--'
              }}
            </p>
            <p v-if="!membership.payment_subscriptions.length" class="red bold">
              - {{ $t('profile.PaymentCardMissing') }} -
            </p>
            <p v-else-if="membership.renewal_failed">
              - {{ $t('profile.paymentErrorPleaseUpdate') }} -
            </p>
            <div v-if="membership.pending_no_show_fees.length">
              {{ membership.pending_no_show_fees.length }}
              {{
                membership.pending_no_show_fees.length > 1 ? $t('global.pendingNoShowFees') : $t(
                    'global.pendingNoShowFee')
              }}
            </div>
          </div>

          <div v-if="membership.status === 'cancelled_running'">
            <p>
              {{ $t('profile.cancelledMembership') }} {{
                formatDate(membership.cancelled_from_date)
              }}
            </p>

          </div>

          <div class="space4"></div>

          <router-link :to="{ name: 'Membership', params:{id: membership.id }}">
            <button
                class="btn btn-primary"
                :class="{ 'btn-secondary-app': mobileAppMode }"
            >
              {{ $t('global.showMembership') }}
            </button>
          </router-link>
          <div class="space4"></div>

          <div class="space1"></div>
        </li>
      </ul>
    </div>
  </div>

</template>

<script>

import { DateTime } from 'luxon';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import { mapGetters } from 'vuex';

export default {
  name: 'MyMembership',
  mixins: [dateTimeFunctions],
  data() {
    return {

    };
  },
  props: [
    'memberships',
  ],
  computed: {
    ...mapGetters([
      'mobileAppMode',
      'client',
    ]),
    todayIso() {
      if (!this.client) {
        return null;
      }
      return DateTime.now().setZone(this.client.timezone).toISODate();
    }
  },
};
</script>


<style>


</style>
