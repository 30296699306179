<template>
  <img :src="profileImageSrc(image, {w:size*2, h: size*2})"
       alt=""
       :style="{width: `${size}px`, height: `${size}px`}"
  >
</template>

<script>

import Imgix from '@/services/Imgix';

export default {
  name: 'ProfileImage',
  mixins: [Imgix],
  props: {
    image: Object,
    size: {
      type: Number,
      default: 80,
    },
  },
};

</script>

<style lang="scss" scoped>

img {
  border-radius: 50%;
}

</style>
