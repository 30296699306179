<template>
  <div>
    <loading-spinner/>
  </div>
</template>

<script>

import LoadingSpinner from '@/components/LoadingSpinner';
import YogoApi from '@/gateways/YogoApi';

export default {
  name: 'StripeClassSeriesUpdatePaymentMethodSuccess',
  components: { LoadingSpinner },
  async created() {
    const classSeriesId = this.$route.params.classSeriesId;
    console.log('About to update payment method for class series', classSeriesId);
    await YogoApi.post('/payments/stripe/process-class-series-payment-method-update-if-completed',
        { classSeries: classSeriesId });
    console.log('Payment method updated');
    console.log()
    this.$store.commit('setClassSeriesFlashMessage', { type: 'updated' });
    this.$router.replace({ name: 'ClassSeriesInstallments', params: { id: classSeriesId } });
  },
};

</script>
