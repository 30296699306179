<template>
  <transition name="modal-fade">
    <div class="modal-backdrop z-50" @click="close" v-show="show">
      <div class="modal relative" :class="{narrow}" @click.stop>
        <button
          type="button"
          class="btn-close flex justify-end absolute right-0 top-0"
          @click="close"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none" stroke="#aaa" stroke-width="2" stroke-linecap="round"
               stroke-linejoin="round" class="feather feather-x h-6 w-6">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <div class="modal-header font-semibold text-lg">
          <slot name="title"></slot>
        </div>
        <div class="modal-body whitespace-pre-line">
          <slot name="content"></slot>
        </div>
        <div class="modal-footer flex-wrap">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    show: Boolean,
    narrow: Boolean,
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style lang="scss" scoped>

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .modal {
    background: #FFFFFF;
    margin: 20px;
    width: 95%;
    border-radius: 3px;
    max-width: 700px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    line-height: 1.4;
    @media (min-width: 768px) {
      margin: 40px;
    }

    &.narrow {
      max-width: 400px;
    }
  }

  .modal-header {
    padding: 30px 60px 30px 30px;
  }

  .modal-header {
    justify-content: space-between;
  }

  .modal-footer {
    padding: 30px;
    display: flex;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 0 50px 0 30px;
  }

  .btn-close {
    border: none;
    font-size: 20px;
    padding: 20px;
    cursor: pointer;
    background: transparent;

    &:focus {
      outline: none;
    }
  }

  .modal-fade-enter,
  .modal-fade-leave-active {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .3s ease
  }


</style>
