<template>
  <div></div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  computed: {
    ...mapGetters(['client']),
  },
  methods: {
    formatCurrency(amount, forceInternationalCurrencySymbol = false) {

      let locale = this.client.settings.locale;
      if (forceInternationalCurrencySymbol) {
        locale = 'en';
      }

      return new Intl.NumberFormat(
          locale,
          {
            style: 'currency',
            currency: this.client.currency,
          },
      ).format(amount);
    },
    getCurrencySymbol() {
      return (0).toLocaleString(
          this.client.settings.locale,
          {
            style: 'currency',
            currency: this.client.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          },
      )
          .replace(/\d/g, '')
          .trim();
    },
  },
};

</script>
