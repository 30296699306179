<template>
  <div></div>
</template>

<script>

  import moment from 'moment-timezone';
  import _assign from 'lodash/assign';
  import _isInteger from 'lodash/isInteger';
  import _isString from 'lodash/isString';
  import {mapGetters} from 'vuex';
  import {DateTime} from 'luxon';

  export default {
    computed: {
      ...mapGetters('appSettings', [
        'locale',
      ]),
      ...mapGetters([
        'client',
      ]),
      timezone() {
        return this.client
            ? this.client.timezone
            : 'UTC';
      },
    },
    methods: {
      formatDate(date, options) {
        let luxonDate;
        if (_isInteger(date)) {
          luxonDate = DateTime.fromMillis(date)
              .setZone(this.timezone);
        } else if (_isString(date) && date.match(/^\d\d\d\d-\d\d-\d\d$/)) {
          luxonDate = DateTime.fromISO(date);
        } else if (_isString(date) && date.match(/^\d\d\d\d-\d\d-\d\d \d\d:\d\d$/)) {
          luxonDate = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm');
        } else if (_isString(date) && date.match(/^\d\d\d\d-\d\d-\d\d \d\d:\d\d:\d\d$/)) {
          luxonDate = DateTime.fromFormat(date, 'yyyy-MM-dd HH:mm:ss');
        } else if (DateTime.isDateTime(date)) {
          luxonDate = date;
        } else if (date instanceof Date) {
            luxonDate = DateTime.fromJSDate(date);
        } else if (moment.isDate(date) || moment.isMoment(date)) {
          const momentDate = moment(date)
              .tz(this.timezone)
              .format('YYYY-MM-DD HH:mm');
          luxonDate = DateTime.fromFormat(momentDate,
              'yyyy-MM-dd HH:mm',
              { zone: this.timezone });
        } else if (moment(date, moment.ISO_8601)
            .isValid()) {
          luxonDate = DateTime.fromFormat(
              moment(date, moment.ISO_8601)
                  .tz(this.timezone)
                  .format('YYYY-MM-DD HH:mm'),
              'yyyy-MM-dd HH:mm',
              { zone: this.timezone },
          );
        }

        const optionsWithDefaults = _assign({
          weekday: false,
          year: true,
          month: 'long',
          time: false,
          capitalize: false,
        }, options);

        const formattingOptions = {
          month: optionsWithDefaults.month === 'long' || optionsWithDefaults.month === true ? 'long' : 'short',
          day: 'numeric',
        };
        if (optionsWithDefaults.year) {
          formattingOptions.year = 'numeric';
        }
        if (optionsWithDefaults.weekday) {
          formattingOptions.weekday = optionsWithDefaults.weekday === 'long' || optionsWithDefaults.weekday === true ? 'long' : 'short';
        }
        if (optionsWithDefaults.time) {
          formattingOptions.hour = '2-digit';
          formattingOptions.minute = '2-digit';
          formattingOptions.hour12 = false;
        }

        let formattedDate = luxonDate.toLocaleString(formattingOptions, {locale: this.locale});

        if (optionsWithDefaults.weekday && optionsWithDefaults.capitalize) {
          formattedDate = formattedDate.charAt(0)
              .toUpperCase() + formattedDate.slice(1);
        }
        return formattedDate;
      },
      formatTime(time, options) {
        let luxonTime;
        if (_isString(time) && time.match(/^\d\d:\d\d$/)) {
          luxonTime = DateTime.fromFormat(time, 'HH:mm');
        } else if (_isString(time) && time.match(/^\d\d:\d\d:\d\d$/)) {
          luxonTime = DateTime.fromFormat(time, 'HH:mm:ss');
        }

        const optionsWithDefaults = _assign({
          seconds: false,
        }, options);

        const formattingOptions = {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        };
        if (optionsWithDefaults.seconds) {
          formattingOptions.seconds = '2-digit';
        }

        return luxonTime.toLocaleString(formattingOptions, {locale: this.locale});
      },
    },
  }

</script>
