<template>
  <div class="my-videos">
    <div
        :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
    >

      <div v-if="client.extended_video_enabled">

        <h2>
          {{ client.settings.customer_profile_video_widget_title }}
        </h2>

        <div class="flex items-end flex-wrap mt-4">
          <button
              class="mb-4 md:my-4 mr-2 inline-block btn btn-primary"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              @click="$router.push({ name: 'VideoExtended' })"
          >
            <span class="flex items-center">
              <SearchIcon class="h-4 w-4 mr-2"></SearchIcon>
              {{ $t('global.Search') }}
            </span>
          </button>

          <button
              class="mb-4 md:my-4 inline-block btn btn-primary"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              @click="$router.push({ name: 'VideoExtended', params: {initialSort: 'favorites'} })"
          >
            <span class="flex items-center">
              <HeartIcon class="h-4 w-4 mr-2"></HeartIcon>
              {{ $t('global.MyFavorites') }}
            </span>
          </button>

          <button
              class="mb-4 md:my-4 inline-block btn btn-secondary"
              :class="{ 'btn-small-app': mobileAppMode }"
              @click="openVideoPreferencesDialog"
              v-if="client.settings.video_filter_values_available_for_customer_preferences.length"
          >
          <span class="flex items-center">
            <AdjustIcon class="h-4 w-4 mr-2"></AdjustIcon>
            {{ $t('global.SetPreferences') }}
            </span>
          </button>

        </div>

        <div class="font-bold text-lg mt-2"
             v-if="activeVideoGroup">
          {{ $t('global.RecommendedVideos') }}
        </div>

        <div v-if="customer.recommended_videos.length">
          <transition class="video-preview" name="fade" mode="in-out" v-if="!showVideos">
          <div
              @click.prevent="toggleShowVideos"
              class="pointer"
          >
            <div class="flex flex-wrap cards-container relative">
              <div class="absolute w-full h-full bg-gradient-to-t from-white z-10"></div>
              <div
                v-for="video in customer.recommended_videos.slice(0,2)"
                class="video-card text-ellipsis overflow-hidden"
                :key="`video_${video.id}_preview`"
              >
                <div class="video-thumb-container">

                  <div
                      class="video-thumb thumbnail"
                      :ref="'videoThumbnail_' + video.id"
                      :style="{'background-image':'url(\'' + getThumbnailUrl(video, 800) + '\')', 'padding-bottom': video.video_provider_data.height / video.video_provider_data.width * 100 + '%'}"
                      @click="playVideo(video)"
                  >

                  </div>
                  <div
                      class="video-duration"
                      :ref="'videoDuration_' + video.id"
                  >
                    {{ formatVideoDuration(video.video_provider_data.duration) }}
                  </div>
                </div>
                <div class="p-4">
                  <div class="video-title mb-2 bold text-gray">
                    {{ video.video_provider_data.name }}
                  </div>
                  <div class="text-black text-sm leading-normal text-ellipsis overflow-hidden">
                    <nl2br
                        tag="div"
                        className="video-description"
                        v-if="video.video_provider_data.description"
                        :text="(video.has_long_description && !video.show_full_description ? video.description_preview : video.video_provider_data.description) || ''"
                    >
                    </nl2br>
                    <a
                        class="video-description-more-switch mt-2"
                        href="#"
                        @click.prevent="switchShowFullVideoDescription(video)"
                        v-if="video.has_long_description"
                    >
                      {{ video.show_full_description ? $t('global.less') : $t('global.more') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

          <transition name="slide-fade" mode="out-in" v-if="showVideos">

          <div v-if="activeVideoGroup" :key="updateVideoComponent">
            <div class="flex flex-wrap cards-container">
              <div
                v-for="video in customer.recommended_videos.slice(0,25)"
                class="video-card text-ellipsis overflow-hidden"
                :key="'video_' + video.id">
                <div class="video-thumb-container" :ref="'videoContainer_' + video.id">

                  <div
                      class="video-thumb thumbnail"
                      :ref="'videoThumbnail_' + video.id"
                      :style="{'background-image':'url(\'' + getThumbnailUrl(video, 800) + '\')', 'padding-bottom': video.video_provider_data.height / video.video_provider_data.width * 100 + '%'}"
                      @click="playVideo(video)"
                  >

                  </div>
                  <div
                      class="video-duration"
                      :ref="'videoDuration_' + video.id"
                  >
                    {{ formatVideoDuration(video.video_provider_data.duration) }}
                  </div>
                </div>
                <div class="p-4">
                  <div class="video-title mb-2 bold text-gray">
                    {{ video.video_provider_data.name }}
                  </div>
                  <div class="text-black text-sm leading-normal text-ellipsis overflow-hidden">
                    <nl2br
                        tag="div"
                        className="video-description"
                        v-if="video.video_provider_data.description"
                        :text="video.has_long_description && !video.show_full_description ? video.description_preview : video.video_provider_data.description"
                    >
                    </nl2br>
                    <a
                        class="video-description-more-switch mt-2"
                        href="#"
                        @click.prevent="switchShowFullVideoDescription(video)"
                        v-if="video.has_long_description"
                    >
                      {{ video.show_full_description ? $t('global.less') : $t('global.more') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </transition>
        </div>
        <div v-else>
          {{ $t('video.NoRecommendedVideosForYourPreferences') }}
        </div>

      </div>

      <div v-else>

        <div class="flex space-between pointer" @click.prevent="toggleShowVideos">
          <h2>
            {{ client.settings.customer_profile_video_widget_title }}
          </h2>

          <div class="flex items-center pointer ml2">
            <span :class="{ 'rotate': showVideos }" class="block icon-arrowdown"></span>
          </div>
        </div>


        <div class="yogo-input my-4 lg:w-6/12 lg:pr-4" v-if="selectedVideoGroupId">
          <select v-model="selectedVideoGroupId">
            <option
                v-for="(videoGroup) in videoGroups"
                :value="videoGroup.id"
                :key="'videoGroup_' + videoGroup.id"
            >
              {{ videoGroup.name }}
            </option>
          </select>
        </div>

        <transition name="slide-fade" mode="out-in" v-if="showVideos">
          <div v-if="noVideos" class="my-4">
            {{ $t('profile.noVideos') }}
          </div>
        </transition>

        <transition class="video-preview" name="fade" mode="in-out" v-if="!showVideos">
          <div
              v-if="activeVideoGroup"
              :key="updateVideoComponent"
              @click.prevent="toggleShowVideos"
              class="pointer"
          >
            <div class="flex flex-wrap cards-container relative">
              <div class="absolute w-full h-full bg-gradient-to-t from-white z-10"></div>
              <div
                v-for="(video, videoIdx) in activeVideoGroup.videos.slice(0,2)"
                class="video-card text-ellipsis overflow-hidden"
                :key="'video_' + video.id"
              >
                <div class="video-thumb-container" :ref="'videoContainer_' + video.id">

                  <div
                      class="video-thumb thumbnail"
                      :ref="'videoThumbnail_' + video.id"
                      :style="{'background-image':'url(\'' + getThumbnailUrl(video, 800) + '\')', 'padding-bottom': video.video_provider_data.height / video.video_provider_data.width * 100 + '%'}"
                      @click="playVideo(activeVideoGroup.videos[videoIdx])"
                  >

                  </div>
                  <div
                      class="video-duration"
                      :ref="'videoDuration_' + video.id"
                  >
                    {{ formatVideoDuration(video.video_provider_data.duration) }}
                  </div>
                </div>
                <div class="p-4">
                  <div class="video-title mb-2 bold text-gray">
                    {{ video.video_provider_data.name }}
                  </div>
                  <div class="text-black text-sm leading-normal text-ellipsis overflow-hidden">
                    <nl2br
                        tag="div"
                        className="video-description"
                        v-if="activeVideoGroup.show_video_descriptions && video.video_provider_data.description"
                        :text="video.has_long_description && !video.show_full_description ? video.description_preview : video.video_provider_data.description"
                    >
                    </nl2br>
                    <a
                        class="video-description-more-switch mt-2"
                        href="#"
                        @click.prevent="switchShowFullVideoDescription(video)"
                        v-if="activeVideoGroup.show_video_descriptions && video.has_long_description"
                    >
                      {{ video.show_full_description ? $t('global.less') : $t('global.more') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <transition name="slide-fade" mode="out-in" v-if="showVideos">

          <div v-if="activeVideoGroup" :key="updateVideoComponent">
            <div class="flex flex-wrap cards-container">
              <div
                v-for="(video, videoIdx) in activeVideoGroup.videos"
                class="video-card text-ellipsis overflow-hidden"
                :key="'video_' + video.id"
              >
                <div class="video-thumb-container" :ref="'videoContainer_' + video.id">

                  <div
                      class="video-thumb thumbnail"
                      :ref="'videoThumbnail_' + video.id"
                      :style="{'background-image':'url(\'' + getThumbnailUrl(video, 800) + '\')', 'padding-bottom': video.video_provider_data.height / video.video_provider_data.width * 100 + '%'}"
                      @click="playVideo(activeVideoGroup.videos[videoIdx])"
                  >

                  </div>
                  <div
                      class="video-duration"
                      :ref="'videoDuration_' + video.id"
                  >
                    {{ formatVideoDuration(video.video_provider_data.duration) }}
                  </div>
                </div>
                <div class="p-4">
                  <div class="video-title mb-2 bold text-gray">
                    {{ video.video_provider_data.name }}
                  </div>
                  <div class="text-black text-sm leading-normal text-ellipsis overflow-hidden">
                    <nl2br
                        tag="div"
                        className="video-description"
                        v-if="activeVideoGroup.show_video_descriptions && video.video_provider_data.description"
                        :text="video.has_long_description && !video.show_full_description ? video.description_preview : video.video_provider_data.description"
                    >
                    </nl2br>
                    <a
                        class="video-description-more-switch mt-2"
                        href="#"
                        @click.prevent="switchShowFullVideoDescription(video)"
                        v-if="activeVideoGroup.show_video_descriptions && video.has_long_description"
                    >
                      {{ video.show_full_description ? $t('global.less') : $t('global.more') }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </transition>

        <div
            class="w-full flex justify-center"
            v-if="!showVideos"
            @click.prevent="toggleShowVideos"
        >
          <div class="btn btn-secondary">
            {{ $t('global.ShowMore') }}
          </div>
        </div>
      </div>

    </div>

    <video-preferences-modal
        v-if="showVideoPreferencesModal"
        @close="showVideoPreferencesModal = false"
        @preferencesChanged="$emit('reload')"/>

  </div>
</template>

<script>

import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import _each from 'lodash/each';
import _find from 'lodash/find';
import _padStart from 'lodash/padStart';

import VimeoPlayer from '@vimeo/player';
import { mapGetters } from 'vuex';
import VideoPreferencesModal from '@/components/VideoPreferencesModal';

import SearchIcon from '../graphics/icons/SearchIcon.vue';
import HeartIcon from '../graphics/icons/HeartIcon.vue';
import AdjustIcon from '../graphics/icons/AdjustIcon.vue';

import YogoApi from '@/gateways/YogoApi';
import MobileAppInterface from '@/mixins/MobileAppInterface.vue';

export default {
  components: {
    VideoPreferencesModal,
    SearchIcon,
    HeartIcon,
    AdjustIcon,
  },
  mixins: [MobileAppInterface],
  data() {
    return {
      vimeoPlayers: {},
      selectedVideoGroupId: null,
      updateVideoComponent: 0,
      videoExtended: true,
      showVideos: false,
      showVideoPreferencesModal: false,
    };
  },
  mounted() {
    this.init();
  },
  props: ['videoGroups', 'customer'],
  computed: {
    ...mapGetters([
      'client',
      'user',
      'mobileAppMode',
    ]),
    noVideos() {
      return !_flatten(_map(this.videoGroups, 'videos')).length;
    },
    activeVideoGroup() {
      if (!this.videoGroups) {
        return [];
      }
      const video = _find(this.videoGroups, { id: this.selectedVideoGroupId });
      return video ? video : this.videoGroups[0];
    },
    customerHasAccessToAllVideos() {
      if (!this.customer) return false;
      return !!_find(this.customer.memberships, 'membership_type.access_all_videos')
          || !!_find(this.customer.class_passes, 'class_pass_type.access_all_videos');
    },
  },

  beforeDestroy() {
    this.destroyAllPlayers();
  },
  watch: {
    selectedVideoGroupId() {
      this.destroyAllPlayers();
      this.updateVideoComponent++;
    },
    customer() {
      this.init();
    },
  },
  methods: {

    init() {
      this.selectedVideoGroupId = this.selectedVideoGroupId || (this.videoGroups && this.videoGroups.length ? this.videoGroups[0].id : null);

      const intervalBeforeNewPreferencesPrompt = 1000 * 60 * 60 * 24 * 182; // 182 days ~ 1/2 year
      if (
          this.client.extended_video_enabled
          && this.client.settings.video_filter_values_available_for_customer_preferences.length
          && this.customerHasAccessToAllVideos
          && !this.customer.settings.video_preferences.length
          && Date.now() > this.customer.settings.video_preferences_dialog_last_opened_at + intervalBeforeNewPreferencesPrompt
      ) {
        this.openVideoPreferencesDialog();
      }
    },

    toggleShowVideos() {
      // this.$set(this.showVideos)
      this.showVideos = !this.showVideos;
    },

    destroyAllPlayers() {
      _each(this.vimeoPlayers, (player, key) => {
        player.destroy();
        delete this.vimeoPlayers[key];
      });
    },
    getThumbnailUrl(video, minWidth) {
      const sortedSizes = _sortBy(video.video_provider_data.pictures.sizes, 'width');
      let thumbnail = _find(sortedSizes, size => size.width >= minWidth);
      if (!thumbnail) {
        thumbnail = sortedSizes[sortedSizes.length - 1];
      }
      return thumbnail.link_with_play_button;
    },

    formatVideoDuration(seconds) {
      return _padStart(Math.floor(seconds / 60), 2, '0') + ':' + _padStart(seconds % 60, 2, '0');
    },

    switchShowFullVideoDescription(video) {
      video.show_full_description = !video.show_full_description;
      this.$forceUpdate();
    },

    async playVideo(video) {

      this.vimeoPlayers['player_' + video.id] = await new VimeoPlayer(
          this.$refs['videoContainer_' + video.id][0],
          {
            id: video.video_provider_id,
            width: this.$refs['videoThumbnail_' + video.id][0].offsetWidth,
            height: this.$refs['videoThumbnail_' + video.id][0].offsetHeight,
            autoplay: true,
          },
      );
      this.vimeoPlayers['player_' + video.id].on('fullscreenchange', (data) => {
        this.callMobileApp('videoFullscreenChange', data.fullscreen);
      })
      this.vimeoPlayers['player_' + video.id].element.style.height = this.$refs['videoThumbnail_' + video.id][0].offsetHeight + 'px';
      this.$refs['videoThumbnail_' + video.id][0].style.display = 'none';
      this.$refs['videoDuration_' + video.id][0].style.display = 'none';
      //this.$refs['videoGroupName_' + videoIdx][0].style.display = 'none'
    },

    openVideoPreferencesDialog() {
      this.showVideoPreferencesModal = true;
      YogoApi.post(`/users/${this.user.id}/settings`, {
        video_preferences_dialog_last_opened_at: Date.now(),
      });
    },

  },
};
</script>


<style lang="scss" scoped>

.video-title {
  font-size: 1.2em;
}

img.thumbnail {
  width: 100%;
}

.video-description {
  margin-top: 10px;
}

.cards-container {
  margin-left: -15px;
  margin-right: -15px;
}

.video-card {
  border-radius: 3px;
  border: 1px solid #ece8e8;
  width: 100%;
  background: #fff;
  margin: 15px;
  @media (min-width: 1024px) {
    width: calc(50% - 30px);
  }
}

.video-thumb-container {
  position: relative;
}

.video-thumb {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 0;
  cursor: pointer;
}

.video-group-name {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  top: 15px;
  left: 15px;
  color: #fff;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: .8em;
}

.video-duration {
  background: rgba(0, 0, 0, .5);
  position: absolute;
  bottom: 15px;
  right: 15px;
  color: #fff;
  padding: 3px 5px;
  border-radius: 2px;
  font-size: .8em;
}

.video-description-more-switch {
  display: block;
  text-align: right;
}

.yogo-filter-value.selected {
  border-color: rgba(255, 116, 119, .5);
  background: rgba(255, 116, 119, .1);
}

.slide-fade-enter-active {
  transition: all .3s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-active {
  transition: all .2s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

</style>
