import qs from 'qs';
import avatar from '@/graphics/avatar';
import _defaults from 'lodash/defaults';

const buildURL = (filename, options) => {
  if (!filename) {
    return '';
  }
  return `https://${process.env.VUE_APP_IMAGE_SERVER_IMGIX}/${filename}?${qs.stringify(options)}`;
};

const profileImageSrc = (image, options) => {
  if (!image) {
    return avatar;
  }
  const optionsWithDefaults = _defaults(
    options,
    {
      fit: 'crop',
    },
  );
  return buildURL(image.filename, optionsWithDefaults);
};

export default {
  methods: {
    Imgix: buildURL,
    profileImageSrc,
  },
};


