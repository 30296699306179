<template>
  <div :class="{ 'wrapper' : !mobileAppMode }">
    <div class="theme--frame-box">
      <div class="m-2">
        <loading-spinner v-if="loading"></loading-spinner>
        <div v-else-if="noValidMembershipsOrClassPasses">
          <div>{{ $t('login.ThereAreCurrentlyNoMembershipsOrClassPassesAvailableForThisClassType') }}</div>
        </div>
        <div v-else>

          <h2 class="mb-4 font-bold">
            {{ $t('login.PleaseSelectAMembershipOrClassPass') }}
          </h2>

          <div class="font-bold text-xl mb-4 text-gray-700">
            <span v-if="seatCount === 1">
              {{ $t('login.HowWouldYouLikeToPayFor') }}
              {{
                requestedClass.class_type.name
              }} {{
                formatDate(requestedClass.date)
              }}, {{
                requestedClass.start_time.substring(0, 5)
              }}
            </span>
            <span v-else>
              {{ $t('login.youNeedValidMembershipDescMultipleSeatsPart1') }}
              {{
                  $tc('global.NSeats',
                      seatCount,
                      { n: seatCount })
                }}
              <span class="underline" @click.prevent="openSeatCountDialog">({{ $t('calendar.change') }})</span>
              {{ $t('login.youNeedValidMembershipDescMultipleSeatsPart2') }}
              {{ requestedClass.class_type.name }}
              {{ formatDate(requestedClass.date) }},
              {{ requestedClass.start_time.substring(0, 5) }}
            </span>
            <span v-if="attendanceType === 'class_livestream'">({{ $t('global.livestream') }})</span>
            <span v-if="attendanceType === 'class_waiting_list'">({{ $t('global.waitingList') }})</span>
            ?
          </div>

          <button
              class="btn-primary mb-2"
              v-if="seatCount === 1 && client.settings.customer_can_reserve_multiple_seats_for_class"
            @click.prevent="openSeatCountDialog"
          >
            {{ $t('calendar.BookMultipleSeats') }}
          </button>

          <div
              class="block text-sm font-medium text-gray-700 w-full pointer"
              v-for="(membership_type, idx) in classValidMembershipTypes"
              :key="'membership_type' + idx"
          >
            <label
                v-for="(payment_option, idx) in membership_type.payment_options"
                :key="'payment_option_' + idx"
            >
              <div class="flex items-center pt-1">
                <input
                    type="radio"
                    v-model="selectedClassPaymentOption"
                    :value="`membership_type-${membership_type.id}-${payment_option.id}`"
                    class="focus:ring-black pointer h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                    :style="{'accent-color': client.settings.theme_primary_color}"
                >
                <span class="w-full flex space-between border-b pb-1 flex-wrap pointer">
                    <span class="font-bold">
                      {{ membership_type.name }} ({{ payment_option.nameThatUserSees }})
                    </span>
                    <span>{{ payment_option.priceTextThatUserSees }}</span>
                  </span>
              </div>
            </label>
          </div>
          <label
              class="block text-sm font-medium text-gray-700 w-full"
              v-for="(class_pass_type, idx) in classValidClassPassTypes"
              :key="'class_pass_type' + idx"
          >
            <div class="flex items-center pt-1">
              <input
                  type="radio"
                  v-model="selectedClassPaymentOption"
                  :value="`class_pass_type-${class_pass_type.id}`"
                  class="focus:ring-black pointer h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                  :style="{'accent-color': client.settings.theme_primary_color}"
              >
              <div class="w-full flex space-between border-b pb-1 flex-wrap pointer align__items--center">
                <span class="font-bold">
                  {{ class_pass_type.name }}
                  <span
                      v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < seatCount">
                    ({{
                      purchaseAccessMultipleClassPassText(class_pass_type, seatCount)
                    }})
                  </span>
                </span>
                <div class="text-right">
                  <span :class="{strikethrough: class_pass_type.has_member_discount}">
                    <span
                        v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < seatCount">
                      {{
                        formatCurrency(class_pass_type.price * Math.ceil(seatCount / class_pass_type.number_of_classes))
                      }}
                    </span>
                    <span v-else>{{ formatCurrency(class_pass_type.price) }}</span>
                  </span>
                  <span class="bold" v-if="class_pass_type.has_member_discount">
                    <br>
                    {{ $t('global.MemberPrice') }}
                      <span
                          v-if="class_pass_type.pass_type === 'fixed_count' && class_pass_type.number_of_classes < seatCount">
                      {{
                          formatCurrency(class_pass_type.price_with_member_discount * Math.ceil(seatCount / class_pass_type.number_of_classes))
                        }}
                    </span>
                    <span v-else>{{ formatCurrency(class_pass_type.price_with_member_discount) }}</span>
                  </span>
                </div>
              </div>
            </div>
          </label>

          <button
              class="btn btn-primary mt-4"
              :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              :disabled="!selectedClassPaymentOption"
              @click="goToCheckout"
          >
            {{ $t('global.ContinueToPayment') }}
          </button>

        </div>
      </div>
    </div>

    <md-dialog :md-active.sync="showSeatCountDialog" v-if="requestedClass">
      <md-dialog-title>{{ $t('calendar.NumberOfSeats')}}</md-dialog-title>
      <md-dialog-content>
        <YogoNumberInput
            v-model="seatCountDialogSeatCount"
            :min="1"
            :max="maxSeatCount"
        ></YogoNumberInput>
      </md-dialog-content>
      <md-dialog-actions>
        <button class="btn mr-4" @click.prevent="showSeatCountDialog = false">{{ $t('global.Cancel') }}</button>
        <button class="btn-primary" @click.prevent="updateSeatCount">{{ $t('global.Ok') }}</button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import YogoApi from '../gateways/YogoApi';
import _sortBy from 'lodash/sortBy';
import LoadingSpinner from '@/components/LoadingSpinner';
import currencyFormatters from '@/mixins/currencyFormatters';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import _find from 'lodash/find';
import qs from 'qs';
import YogoNumberInput from '@/components/ui/YogoNumberInput.vue';

export default {
  name: 'PurchaseAccessForClass',
  components: {
    YogoNumberInput,
    LoadingSpinner,
  },
  mixins: [currencyFormatters, dateTimeFunctions],
  data() {
    return {
      loading: true,

      attendanceType: '',
      seatCount: 0,

      classId: null,
      requestedClass: null,

      showSeatCountDialog: false,
      seatCountDialogSeatCount: 1,

      classValidClassPassTypes: [],
      classValidMembershipTypes: [],

      noValidMembershipsOrClassPasses: false,

      selectedClassPaymentOption: null,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'mobileAppMode',
    ]),
    maxSeatCount() {
      return this.requestedClass
        ? this.requestedClass.seats - this.requestedClass.signup_count + this.requestedClass.user_signup_count
        : 0;
    }
  },
  async created() {

    const queryParams = qs.parse(document.location.search.substring(1));
    if (this.$route.query.attendanceType) {
      // Test for $route.query first. Frontend and mobile app use $route.query
      this.attendanceType = this.$route.query.attendanceType;
      this.classId = this.$route.query.classId;
      this.seatCount = parseInt(this.$route.query.seatCount);
    } else {
      // Widgets still use basic query params (document.location.search)
      this.attendanceType = queryParams.attendanceType;
      this.classId = queryParams.classId;
      this.seatCount = parseInt(queryParams.seatCount);
    }

    this.fetchAccessItems();
  },
  methods: {
    async fetchAccessItems() {
      const queryString = qs.stringify({
        id: this.classId,
        populate: [
          'class_type',
          'signup_count',
          'user_signup_count',
        ],
      });

      ({ classes: [this.requestedClass] } = await YogoApi.get(`/classes?${queryString}`));

      ({
        classPassTypes: this.classValidClassPassTypes,
        membershipTypes: this.classValidMembershipTypes,
      } = await YogoApi.post(
          `/class-types/${this.requestedClass.class_type.id}/purchase-options`,
          {
            user: this.user.id,
            attendanceType: this.attendanceType === 'class_waiting_list' ? 'class' : this.attendanceType, // 'class' or 'class_livestream'
            requiredNumberOfSeats: this.seatCount,
          },
      ));

      this.classValidClassPassTypes = _sortBy(
          this.classValidClassPassTypes,
          ['price'],
      );

      if (!this.classValidMembershipTypes.length && !this.classValidClassPassTypes.length) {
        this.noValidMembershipsOrClassPasses = true;
        this.loading = false;
        return;
      }

      this.selectedClassPaymentOption = this.classValidMembershipTypes.length
          ? `membership_type-${this.classValidMembershipTypes[0].id}-${this.classValidMembershipTypes[0].payment_options[0].id}`
          : `class_pass_type-${this.classValidClassPassTypes[0].id}`;

      this.loading = false;
    },
    openSeatCountDialog() {
      console.log('openSeatCountDialog');
      this.seatCountDialogSeatCount = this.seatCount;
      this.showSeatCountDialog = true;
    },
    updateSeatCount() {
      this.seatCount = this.seatCountDialogSeatCount;
      if (this.seatCount < 1) {
        this.seatCount = 1;
      }
      if (this.seatCount > this.maxSeatCount) {
        this.seatCount = this.maxSeatCount;
      }
      this.showSeatCountDialog = false;
      this.loading = true;
      this.fetchAccessItems();
    },
    purchaseAccessMultipleClassPassText(classPassType, count) {
      const requiredNumberOfClassPasses = Math.ceil(count / classPassType.number_of_classes);
      return `${requiredNumberOfClassPasses} x ${this.formatCurrency(classPassType.price)}`;
    },
    async goToCheckout() {
      // Buying a class pass or a membership to sign up for a class
      const [itemType, itemId, paymentOptionId] = this.selectedClassPaymentOption.split('-');

      // Show the loading indicator since it can take a while to create the cart items
      // if there are many
      this.loading = true;
      if (this.seatCount > 1) {
        const classPassType = _find(this.classValidClassPassTypes, { id: parseInt(itemId) });
        const numberOfItems = Math.ceil(this.seatCount / classPassType.number_of_classes);
        for (let i = 0; i < numberOfItems; i++) {
          const cartItem = {
            item_type: itemType,
            item_id: itemId,
            use_immediately_after_purchase: {
              item_type: this.attendanceType, // 'class' or 'class_livestream' or 'class_waiting_list
              class_id: this.requestedClass.id,
              numberOfSeats: i === 0
                  ? (this.seatCount % classPassType.number_of_classes) || classPassType.number_of_classes
                  : classPassType.number_of_classes,
            },
            payment_option: paymentOptionId || undefined,
            user: this.user.id,
            destroyOthers: i === 0,
          };
          console.log('cartItem:', cartItem);
          await YogoApi.post('/cart-items', cartItem);
        }
      } else {
        const cartItem = {
          item_type: itemType,
          item_id: itemId,
          use_immediately_after_purchase: {
            item_type: this.attendanceType, // 'class' or 'class_livestream' or 'class_waiting_list'
            class_id: this.requestedClass.id,
            numberOfSeats: this.seatCount,
          },
          payment_option: paymentOptionId || undefined,
          user: this.user.id,
          destroyOthers: true,
        };
        if (itemType === 'membership_type') {
          const membershipType = _find(this.classValidMembershipTypes, {id: parseInt(itemId)});
          const paymentOption = _find(membershipType.payment_options, {id: parseInt(paymentOptionId)});
          if (membershipType.userIsEligibleForCampaign && paymentOption.number_of_months_payment_covers === 1) {
            cartItem.membership_campaign = membershipType.active_campaign_id;
          }
        }
        await YogoApi.post('/cart-items', cartItem);
      }

      this.$router.push('Checkout');
    },
  },
};
</script>
