<template>
  <div class="events">
    <div
      :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
    >

      <h2>{{ $t('profile.myEventsLabel') }}</h2>

      <div class="space4"></div>
      <div v-if="!eventSignups.length">
        {{ $t('profile.noEvents') }}
      </div>

      <ul class="widget__list--standard" v-else>

        <li
          v-for="eventSignup in eventSignups" :key="'eventSignup_' + eventSignup.id"
          class="border-b"
        >
          <div>
            <p class="font-bold text-base">
              {{ eventSignup.event.name }}
            </p>
            <p v-if="eventSignup.event.room && !eventSignup.event.time_slots_have_separate_rooms_and_teachers" class="ml-2">
              {{ eventSignup.event.room.name }} <span v-if="eventSignup.event.room.branch">{{ eventSignup.event.room.branch.name }}</span>
            </p>
            <p v-if="eventSignup.event.teachers.length && !eventSignup.event.time_slots_have_separate_rooms_and_teachers" class="ml-2">
              {{ eventSignup.event.teachers.map(t => `${t.first_name} ${t.last_name}`.trim()).join(', ') }}
            </p>
            <button
              class="btn btn-primary"
              :class="{ 'btn-secondary-app my-2': mobileAppMode }"
              v-if="parseInt(eventSignup.event.price) === 0"
              @click="signOffFromEvent(eventSignup)">
                {{ $t('global.cancelBooking') }}
            </button>
          </div>
          <div
            v-if="eventSignup.installment_payments_enabled"
            class="inline-flex items-center mt-2 rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer"
            :class="{
            'bg-green-100 text-green-800': eventSignup.installment_payments_status === 'paid_in_full',
            'bg-yellow-100 text-yellow-800': eventSignup.installment_payments_status === 'on_track',
            'bg-red-100 text-red-800': eventSignup.installment_payments_status === 'failed',
          }"
               @click="$router.push({name: 'EventSignupInstallments', params: { eventSignupId: eventSignup.id}})"
          >
            {{
              $t(
                  'event.XOfYInstallmentsPaid',
                  {
                    x: eventSignup.number_of_installments_paid,
                    y: eventSignup.installments.length,
                  },
              )
            }}
          </div>
          <div v-if="eventSignup.event.use_time_slots">
            <p
              v-for="timeSlot in eventSignup.event.time_slots"
              :key="'timeSlot_' + timeSlot.id"
              class="mb-2"
            >
              <span class="font-bold">
                {{ formatDate(`${timeSlot.date} ${timeSlot.start_time}`, { month: 'short', year: false, time: true }) }} - {{ formatTime(timeSlot.end_time) }}
              </span>
              <span class="block" v-if="eventSignup.event.time_slots_have_separate_rooms_and_teachers">
                <span class="flex items-center" v-if="timeSlot.room">
                  <LocationIcon class="mr-1 pr-1"></LocationIcon>
                  {{ timeSlot.room.name }} <span v-if="timeSlot.room.branch">({{ timeSlot.room.branch.name }})</span>
                </span>
                <span class="block">
                  {{ timeSlot.teachers.map(t => `${t.first_name} ${t.last_name}`.trim()).join(', ') }}
                </span>
              </span>
            </p>
          </div>
          <nl2br
              v-else-if="!!eventSignup.event.time_freetext"
              tag="p" :text="eventSignup.event.time_freetext" />
          <p v-else>
            {{ formatDate(eventSignup.event.start_date) }}
          </p>
          <div class="space2"></div>
        </li>
      </ul>

      <md-snackbar md-position="center" :md-duration="4000"
                   :md-active.sync="showSnackbar" md-persistent>
        {{ $t('calendar.youAreNoLongerSignedUpFor', {className: snackbarEvent ? snackbarEvent.name : ''}) }}
      </md-snackbar>
    </div>
  </div>
</template>

<script>

  import YogoApi from '@/gateways/YogoApi'
  import { mapGetters } from 'vuex';
  import dateTimeFunctions from '@/mixins/dateTimeFunctions';
  import LocationIcon from '../graphics/icons/LocationIcon.vue';

export default {
  components: {
    LocationIcon
  },
  name: 'MyEvents',
  data() {
    return {
      showSnackbar: false,
      snackbarEvent: null
    };
  },
  computed: {
    ...mapGetters([
      'mobileAppMode'
    ]),
  },
  props: ['eventSignups'],
  mixins: [dateTimeFunctions],
  methods: {
    async signOffFromEvent(eventSignup) {
      await YogoApi.delete('/event-signups/' + eventSignup.id);
      this.snackbarEvent = eventSignup.event;
      this.showSnackbar = true;
      this.$emit('refresh');
    },
  },
};
</script>


<style>


</style>
