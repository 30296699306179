<template>
  <div>
    <div class="yogo-widget">
      <div class="yogo-book-appointment-button">

        <div>
          <button
              disabled
              v-if="errorMessage"
              class="btn btn-primary"
              :class="normalizedCssClasses" ref="button"
          >
            {{ errorMessage }}
          </button>

          <button
              v-else
              @click.prevent="buttonClick"
              class="btn btn-primary mb-1"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              ref="button"
          >
            {{ ctaWithDefault }}
          </button>

        </div>
      </div>
    </div>

    <!-- This modal shows available services, timeslots, teachers,
      and we can continue to AboutToBookAppointment modal -->
    <modal
        v-show="modalIsOpen"
        @close="closeModal">
      <template slot="body">
        <loading-spinner v-if="loading"/>
        <div v-else>
          <div class="md:flex justify-between pt-2 mb-4">
            <div class="flex-1 md:mr-2 mb-2">
              <div class="font-semibold mb-2">{{ $t('global.Service') }}</div>
              <yogo-select v-model="selectedAppointmentTypeId">
                <option v-if="!appointmentTypeOptions.length" disabled>{{
                    $t('global.NoAvailableServices')
                  }}
                </option>
                <option v-for="appointmentType in appointmentTypeOptions"
                        :value="appointmentType.id"
                        :key="appointmentType.id">
                  {{ appointmentType.name }}
                </option>
              </yogo-select>
            </div>

            <div class="flex-1 md:mr-2 mb-2"
                 v-if="client && client.branches.length > 1">
              <div class="font-semibold mb-2">{{ $t('global.Branch') }}</div>
              <yogo-select v-model="selectedBranchId">
                <option value="0">
                  - - {{ $t('global.AnyBranch') }} - -
                </option>
                <option v-for="branch in client.branches" :value="branch.id"
                        :key="branch.id">
                  {{ branch.name }}
                </option>
              </yogo-select>
            </div>

            <div class="flex flex-1 items-start">
              <div class="w-full flex flex-1 flex-col">
                <div class="font-semibold mb-2">{{ $t('global.Teacher') }}</div>
                <yogo-select v-model="selectedTeacherId">
                  <option value="0">
                    - - {{ $t('global.AnyTeacher') }} - -
                  </option>
                  <option v-for="teacher in teacherOptions" :value="teacher.id"
                          :key="teacher.id">
                    {{ teacher.first_name + ' ' + teacher.last_name }}
                  </option>
                </yogo-select>
              </div>
            </div>
          </div>
          <div class="md:flex justify-between pt-2"
               v-if="showDatepickerAndAvailableTimes">
            <div class="datepicker-container flex-1 mb-4">
              <datepicker
                  :highlighted="{customPredictor: calendarHighlightedFn}"
                  :monday-first="true"
                  :inline="true"
                  :language="datepickerLanguage"
                  v-model="selectedDate"
                  @changedMonth="datepickerChangedMonth"
              >
              </datepicker>

            </div>

            <div class="flex flex-1 w-full md:ml-4">
              <div>
                <div class="md:flex">
                  <h2 class="mb-4 font-bold"> {{ this.selectedDateFormat }}</h2>
                </div>

                <div class="md:flex flex-wrap pt-2">
                  <div class="-ml-2" v-if="visibleTimes.length > 0">
                    <button
                        :class="{'book-time':true, 'selected-time':(time === selectedTime)}"
                        v-for="time in visibleTimes" :key="time"
                        @click="selectBookTime(time)"
                    >
                      {{ time }}
                    </button>
                  </div>
                  <span v-else>{{ $t('global.NoAvailableTimesThisDate') }}</span>
                </div>

              </div>
            </div>
          </div>

          <!--<div v-if="showQuestionsAndBookButton">
            <div class="md:flex pt-2" v-for="item in questions" :key="item.id">
              <md-field class="flex--50">
                <label>{{ item.text }}</label>
                <md-input v-model="item.answer" type="text" :required="item.required"></md-input>
              </md-field>
            </div>
          </div>-->

          <button
              v-if="showQuestionsAndBookButton"
              :disabled="!allowBook"
              @click.prevent="bookAppointment"
              class="button btn-primary btn btn-primary book mt-2 w-full"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              ref="button"
          >
            {{ $t('global.Continue') }}
          </button>
        </div>
      </template>
    </modal>

    <!-- This modal shows How would you like to pay with total price -->
    <modal
        v-if="aboutToBookModalIsOpen"
        @close="closeAboutToBookModal"
    >
      <!-- Should use v-if to creat modal after continue button clicked  -->
      <template slot="body">
        <AboutToBookAppointment
            :appointmentCartItem="appointmentCartItem"
            @closePopup="closeAboutToBookModal"
            @updateProfile="updateProfile"
        >
        </AboutToBookAppointment>
      </template>
    </modal>
  </div>
</template>

<script>

import YogoApi from '@/gateways/YogoApi';
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import qs from 'qs';
import Modal from '@/components/Modal';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _includes from 'lodash/includes';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _padEnd from 'lodash/padEnd';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import YogoSelect from '@/components/ui/YogoSelect';

import AboutToBookAppointment from '@/components/AboutToBookAppointment';
import LoadingSpinner from '@/components/LoadingSpinner';
import { DateTime } from 'luxon';
import { da, en, nbNO, sv } from 'vuejs-datepicker/dist/locale';

export default {
  name: 'BookAppointmentButton',
  mixins: [dateTimeFunctions],
  components: {
    LoadingSpinner,
    YogoSelect,
    Modal,
    Datepicker,
    AboutToBookAppointment,
  },
  data: function () {
    return {
      loading: true,

      appointmentTypes: [],
      upcomingAppointmentTypes: [],

      appointmentTypeOptions: [],
      selectedAppointmentTypeId: null,

      selectedBranchId: '0',

      teachers: [],
      visibleTeacherIds: [],
      teacherOptions: [],
      selectedTeacherId: '0',

      errorMessage: '',

      selectedDate: new Date(),

      modalIsOpen: false,
      aboutToBookModalIsOpen: false,

      appointmentAvailabilities: [],

      showDatepickerAndAvailableTimes: false,

      times: [],
      visibleTimes: [],
      selectedTime: '',
      noTimeSelected: false,

      appointmentCartItem: {},

    };
  },

  props: {
    buttonText: {
      type: String,
      default: 'Button',
    },
    cta: {
      type: String,
      default: '',
    },
    cssClasses: {
      type: String,
      default: '',
    },
  },

  computed: {
    ...mapGetters([
      'user',
      'classesCounter',
      'client',
      'mobileAppMode'
    ]),
    normalizedCssClasses() {
      return this.cssClasses.replace(/,/g, ' ').replace(/ {2,}/g, ' ');
    },
    ctaWithDefault() {
      return this.cta || this.buttonText || this.$t('global.book');
    },
    selectedDateFormat() {
      return this.formatDate(this.selectedDate);
      // return this.formatDateTime(this.selectedDate);
    },
    showQuestionsAndBookButton() {
      return !!this.selectedTime;
    },
    /*questions() {
      // return questions from the selected appointment type, if there is one
      return this.appointmentTypes
      && this.selectedAppointmentTypeId
          ? _find(
              this.appointmentTypes,
              { id: this.selectedAppointmentTypeId },
          ).questions
          : [];
    },*/
    allowBook() {
      if (!this.showDatepickerAndAvailableTimes) return false;
      return !!this.selectedTime;

      /*const requiredQuestions = _filter(
          this.questions,
          {required: true}
      );
      return !_find(requiredQuestions, q => !q.answer);*/
    },
    selectedAppointmentType() {
      return _find(this.appointmentTypes, { id: parseInt(this.selectedAppointmentTypeId) });
    },
    datepickerLanguage() {
      const languages = { en, da, nb: nbNO, sv };
      const locale = this.client && this.client.settings ? this.client.settings.locale : 'en';
      return languages[locale];
    },
  },

  async created() {
  },

  mounted() {
    // this.updateButtonClasses();
  },

  watch: {
    async selectedAppointmentTypeId() {
      this.getAppointmentAvailableTimes();
      this.updateTeacherOptions();
    },
    selectedTeacherId() {
      this.getAppointmentAvailableTimes();
      this.selectedTime = '';
    },
    selectedDate() {
      this.updateTimes();
      this.selectedTime = '';
    },
    selectedBranchId(newSelectedBranchId) {
      this.getAppointmentAvailableTimes();
      if (newSelectedBranchId !== '0') {
        this.selectedTime = '';
      }
    },
  },

  methods: {

    async init() {
      this.loading = true;
      this.selectedAppointmentTypeId = null;
      this.selectedBranchId = '0';
      this.selectedTeacherId = '0';
      this.selectedTime = '';

      // Get all initial data in parallel
      [
        this.appointmentTypes,
        this.teachers,
        this.upcomingAppointmentTypes,
      ] = await Promise.all([
        YogoApi.get('/appointment-types?populate[]=questions'),
        YogoApi.get('/users?teacher=1'),
        YogoApi.get('/appointment-available-intervals/upcoming-appointment-types'),
      ]);


      // Create appointment type dropdown options
      this.appointmentTypeOptions = _filter(
          this.appointmentTypes,
          at => _find(
              this.upcomingAppointmentTypes,
              { appointment_type_id: at.id },
          ),
      );
      this.appointmentTypeOptions = _sortBy(
          this.appointmentTypeOptions,
          'name',
      );

      // Select the first option.
      if (this.appointmentTypeOptions.length > 0) {
        this.selectedAppointmentTypeId = this.appointmentTypeOptions[0].id;
      }

      await this.updateTeacherOptions();

      /*this.appointmentTypes.map(item => {
        if (item.id == this.appointmentType) {
          this.questions = item.questions;
        }
      });*/
      /*console.log("aaa", this.questions);
      var selectedAppointmentTypeId = null;
      this.appointmentTypes.map(item => {
        if (item.id == this.appointmentType) {
          selectedAppointmentTypeId = item;
        }
      });
*/
      await this.getAppointmentAvailableTimes();

      this.loading = false;

    },

    async updateTeacherOptions() {
      // Show teachers that offer the selected appointment type

      const upcomingAppointmentType = _find(
          this.upcomingAppointmentTypes,
          at => parseInt(at.appointment_type_id) === parseInt(this.selectedAppointmentTypeId),
      );
      this.visibleTeacherIds = upcomingAppointmentType
          ? upcomingAppointmentType.teacher_ids
          : [];


      // Create teacher dropdown options
      this.teacherOptions = _filter(
          this.teachers,
          t => _includes(this.visibleTeacherIds, t.id),
      );
      this.teacherOptions = _sortBy(
          this.teacherOptions,
          ['first_name', 'last_name'],
      );


    },

    async getAppointmentAvailableTimes() {
      if (this.selectedAppointmentTypeId) {
        // If a date in the current month is selected, show available times from today forward,
        // otherwise show all available times for the selected month
        const start_date = DateTime.now().setZone(this.client.timezone).toFormat('YYYY-MM') === DateTime.fromJSDate(this.selectedDate).toFormat('YYYY-MM')
            ? DateTime.fromJSDate(this.selectedDate).toISODate()
            : DateTime.fromJSDate(this.selectedDate).startOf('month').toISODate();
        const queryString = qs.stringify({
          start_date: start_date,
          end_date: DateTime.fromJSDate(this.selectedDate).endOf('month').toISODate(),
          appointment_type_id: this.selectedAppointmentTypeId,
          teacher_id: this.selectedTeacherId || undefined,
          branch_id: this.selectedBranchId || undefined,
        });
        this.showDatepickerAndAvailableTimes = false;
        this.times = await YogoApi.get(`/appointment-available-timeslots?${queryString}`);
        this.showDatepickerAndAvailableTimes = true;
      } else {
        this.times = [];
      }

      this.updateTimes();

    },

    calendarHighlightedFn(date) {
      const isoDate = DateTime.fromJSDate(date).toISODate();
      const todayIso = DateTime.now().setZone(this.client.timezone).toISODate();
      return isoDate >= todayIso
          && this.times
          && this.times[isoDate]
          && !!this.times[isoDate].length;
    },

    async datepickerChangedMonth(param1) {
      this.selectedDate = param1;
      await this.getAppointmentAvailableTimes();
      return false;
    },

    selectBookTime(time) {
      this.selectedTime = time;
    },

    async updateTimes() {
      const dateISO = DateTime.fromJSDate(this.selectedDate).toISODate();

      const todayISO = DateTime.now().setZone(this.client.timezone).toISODate();

      // Don't show available times before todayISO
      if (dateISO < todayISO) {
        this.visibleTimes = [];
      }

      this.visibleTimes = this.times[dateISO];

      // We only need the time point itself
      this.visibleTimes = _map(
          this.visibleTimes,
          t => t.start_time.substr(0, 5),
      );

      // Only show one item per time
      this.visibleTimes = _uniq(
          this.visibleTimes,
      );

      // If today is selected, only show time slots later than todayISO
      if (dateISO === todayISO) {
        const nowTimeShort = DateTime.now().setZone(this.client.timezone).toFormat('HH:mm');
        this.visibleTimes = _filter(
            this.visibleTimes,
            time => time > nowTimeShort,
        );
      }

      // Sort time slots
      this.visibleTimes.sort();

    },

    async onTeacherChange() {

    },

    async buttonClick() {
      this.modalIsOpen = true;
      await this.init();
      this.loading = false;
    },

    closeModal() {
      this.modalIsOpen = false;
    },

    closeAboutToBookModal() {
      this.aboutToBookModalIsOpen = false;
    },

    async bookAppointment() {
      if (!this.allowBook) {
        this.noTimeSelected = true;
        return;
      }

      if (this.selectedAppointmentType.customer_point_of_payment === 'outsideYogo') {
        this.loading = true;
        const response = await YogoApi.post(
            '/appointments',
            {
              appointment_type_id: this.selectedAppointmentTypeId,
              date: DateTime.fromJSDate(this.selectedDate).toISODate(),
              start_time: _padEnd(this.selectedTime, '8',':00'),
              customer_id: this.user.id,
              teacher_id: this.selectedTeacherId || undefined,
              branch_id: this.selectedBranchId || undefined,
              paymentOption: 'outsideYogo',
            },
            {
              headers: {
                'X-Yogo-Client-Accepts-Extended-Error-Format': 1,
              },
            },
        );
        if (response.error) {
          this.loading = false;
          return;
        }
        alert(this.$t('global.TheAppointmentHasBeenCreated'));
        this.modalIsOpen = false;
        this.$emit('refresh');
        return;
      }

      const cartItem = {
        itemType: 'appointment',
        appointment_type_id: this.selectedAppointmentTypeId,
        date: DateTime.fromJSDate(this.selectedDate).toISODate(),
        start_time: this.selectedTime + ':00',
        //answers: _map(this.questions, 'answer'),
      };
      if (parseInt(this.selectedTeacherId)) {
        cartItem.teacher_id = this.selectedTeacherId;
      }
      if (parseInt(this.selectedBranchId)) {
        cartItem.branch_id = this.selectedBranchId;
      }

      this.appointmentCartItem = cartItem;

      // const loginWithCartRouteParams = {
      //   cartItemJson: JSON.stringify(cartItem),
      // };
      // console.log(loginWithCartRouteParams.cartItemJson);

      // YogoFrontend.open('/system-route/LoginWithCart/' + encodeURIComponent(JSON.stringify(
      //     loginWithCartRouteParams)));

      this.modalIsOpen = false;

      // open AboutToBookAppointment modal
      this.aboutToBookModalIsOpen = true;

    },

    updateButtonClasses() {
      if (this.cssClasses) {
        this.$nextTick(() => {
          if (this.$refs.button && this.$refs.button.$el) {
            this.$refs.button.$el.className = this.normalizedCssClasses;
          }
        });
      }
    },
    updateProfile() {
      this.$emit('refresh');
    }

  },
};
</script>

<style lang="scss" scoped>

.datepicker-container /deep/ .vdp-datepicker__calendar {
  border: none;
  width: 100% !important;
  font-weight: bold;

  header { /* Will often be overwritten by host CSS */
    height: auto;
    border: none;
    background: none;
    z-index: auto;
    position: static;
    left: 0;
    top: 0;
  }

  .highlighted {
    background: transparent;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -2px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #2563eb;
    }
  }

  .selected {
    background: #ccc;
  }

  .cell {
    font-size: 12px;
    border: 2px solid transparent;
    border-radius: 5px;
    line-height: 3;
    @media screen and (min-width: 768px) {
      line-height: 2.3;
      font-size: 16px;
    }
  }

  .cell:hover {
    border: 2px solid #2563eb;
  }

  .cell.selected:hover {
    color: #2563eb;
    background: #dceafe;
  }

  .cell:not(.blank):not(.disabled).day:hover,
  .cell:not(.blank):not(.disabled).month:hover,
  .cell:not(.blank):not(.disabled).year:hover {
    border: 2px solid transparent;
  }

  .selected.highlighted {
    color: #fff;
    background: #2563eb;
    border: 2px solid transparent;

  }
}

@media screen and (min-width: 1368px) {
  .yogo-modal-content {
    overflow: initial;
  }
}

.book-time {
  position: relative;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 8px;
  font-weight: 600;
  background: #dbeafe;
  color: #2563eb;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #2563eb;
  }
}

.selected-time {
  background-color: #2563eb;
  color: #fff;
}

.date-format {

}

</style>
