<template>

  <loadingspinner v-if="loading"></loadingspinner>
  <div v-else>

    <router-link v-if="mobileAppMode" :to="{ name: 'Prices' }" class="p-2 flex aic">
      <ArrowLeftIcon/>
      <span class="pl-1">
          {{ $t('global.back') }}
        </span>
    </router-link>
    <a v-if="clientWebsiteReferrerUrl() && !isRunningInIframe()" :href="clientWebsiteReferrerUrl()"
       class="p-2 flex aic">
      <ArrowLeftIcon/>
      <span class="pl-1">
          {{ $t('global.back') }}
        </span>
    </a>
    <div class="with__item--right with__item--container">
      <div
          class="flex__align-center wrapper narrow-wrapper flex-column min-height--100 flex-center">

        <ModalLogo/>
        <div class="space4"></div>
        <h2>{{ $t('global.BuyGiftCard', { clientName: client.name }) }}</h2>
        <div class="space8"></div>

        <div class="flex flex--row-tablet flex__align--start width--100">

          <div class="flex--3 width--100">
            <div class="flex-center">

              <div class="wrapper narrow-wrapper padding__right--20 width--100">
                <form @submit.prevent="buy">
                  <md-field :class="getValidationClass('form.giver_name')">
                    <label>{{ $t('global.YourName') }}</label>
                    <md-input required type="text" v-model="form.giver_name"></md-input>
                    <span class="md-error"
                          v-if="!$v.form.giver_name.required">{{
                        $t('global.Required')
                      }}</span>
                  </md-field>

                  <md-field :class="getValidationClass('form.giver_email')">
                    <label>{{ $t('global.YourEmail') }}</label>
                    <md-input required type="email" v-model="form.giver_email"></md-input>
                    <span class="md-error"
                          v-if="!$v.form.giver_email.required || !$v.form.giver_email.email">{{
                        $t('global.NotAValidEmail')
                      }}</span>
                  </md-field>

                  <md-field :class="getValidationClass('form.recipient_name')">
                    <label>{{ $t('global.RecipientName') }}</label>
                    <md-input required type="text" v-model="form.recipient_name"></md-input>
                    <span class="md-error"
                          v-if="!$v.form.recipient_name.required">{{
                        $t('global.Required')
                      }}</span>
                  </md-field>

                  <!--<md-field :class="getValidationClass('form.recipient_email')">
                    <label>{{ $t('global.RecipientEmail') }}</label>
                    <md-input required type="email" v-model="form.recipient_email"></md-input>
                    <span class="md-error" v-if="!$v.form.recipient_email.required">{{
                        $t('global.Required')
                      }}</span>
                    <span class="md-error"
                          v-else-if="!$v.form.recipient_email.required || !$v.form.recipient_email.email">{{
                        $t('global.NotAValidEmail')
                      }}</span>
                  </md-field>-->

                  <md-field v-if="client.settings.gift_card_delivery_mode === 'manual'">
                    <label>{{ $t('global.MessageToRecipient') }}</label>
                    <md-textarea maxlength="300" v-model="form.message"></md-textarea>
                  </md-field>

                  <md-field :class="getValidationClass('form.amount')">
                    <label>{{ $t('global.Amount') }}</label>
                    <md-input required type="number" v-model="form.amount"></md-input>
                    <span class="md-suffix">{{ getCurrencySymbol() }}</span>
                    <span class="md-error" v-if="!$v.form.amount.required">
                    {{ $t('global.Required') }}
                  </span>
                    <span class="md-error" v-if="!$v.form.amount.minimumAmount">
                    {{ $t('giftCard.TheMinimumAmountIs', { amount: minimumAmountText }) }}
                  </span>
                  </md-field>

                  <div class="flex items-center justify-between mt-8 flex-wrap mb-8">
                    <div>
                      <div>
                        <md-checkbox v-model="termsAccepted">
                          <accept-terms-text/>
                        </md-checkbox>
                      </div>

                      <div>
                        <md-checkbox v-model="privacyPolicyAccepted"
                                     v-if="client.settings.customer_must_accept_the_privacy_policy">
                          <accept-privacy-policy-text/>
                        </md-checkbox>
                      </div>

                      <div>
                        <md-checkbox v-model="consentFormAccepted"
                                     v-if="client.settings.customer_must_accept_the_consent_form">
                          <accept-consent-form-text/>
                        </md-checkbox>
                      </div>
                    </div>


                    <button
                        @click="buy"
                        class="btn btn-primary mt-8 md:mt-0"
                        :class="{ 'btn-primary-app w-full': mobileAppMode }"
                        :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
                    >
                      {{ $t('global.BuyGiftCard') }}
                    </button>
                  </div>

                </form>
              </div>

            </div>
          </div>

        </div>

      </div>

    </div>

    <forgot-to-accept-terms-alert :show.sync="showForgotToAcceptTermsAlert"/>
    <forgot-to-accept-privacy-policy-alert :show.sync="showForgotToAcceptPrivacyPolicyAlert"/>
    <forgot-to-accept-consent-form-alert :show.sync="showForgotToAcceptConsentFormAlert"/>

  </div>
</template>

<script>
import qs from 'qs';
import { validationMixin } from 'vuelidate';
import ClientSite from '../gateways/ClientSite';
import { mapGetters } from 'vuex';
import Loadingspinner from './LoadingSpinner';
import { email, minValue, required } from 'vuelidate/lib/validators';
import _get from 'lodash/get';

import Imgix from '@/services/Imgix';
import ModalLogo from './ModalLogo';
import ReepayPaymentMixin from './ReepayPaymentMixin';
import StripePaymentMixin from '@/components/StripePaymentMixin';
import currencyFormatters from '@/mixins/currencyFormatters';
import semver from 'semver';
import ArrowLeftIcon from '@/graphics/icons/ArrowLeftIcon.vue';
import AcceptTermsText from '@/components/fragments/AcceptTermsText.vue';
import AcceptPrivacyPolicyText from '@/components/fragments/AcceptPrivacyPolicyText.vue';
import ForgotToAcceptTermsAlert from '@/components/fragments/ForgotToAcceptTermsAlert.vue';
import ForgotToAcceptPrivacyPolicyAlert
  from '@/components/fragments/ForgotToAcceptPrivacyPolicyAlert.vue';
import ForgotToAcceptConsentFormAlert
  from '@/components/fragments/ForgotToAcceptConsentFormAlert.vue';
import AcceptConsentFormText from '@/components/fragments/AcceptConsentFormText.vue';

export default {
  mixins: [validationMixin, Imgix, ReepayPaymentMixin, StripePaymentMixin, currencyFormatters],
  data() {
    return {
      loading: false,
      form: {
        recipient_name: '',
        //recipient_email: '',
        giver_name: '',
        giver_email: '',
        message: '',
        amount: 500,
      },
      termsAccepted: false,
      showForgotToAcceptTermsAlert: false,

      privacyPolicyAccepted: false,
      showForgotToAcceptPrivacyPolicyAlert: false,

      consentFormAccepted: false,
      showForgotToAcceptConsentFormAlert: false,
    };
  },
  components: {
    AcceptConsentFormText,
    ForgotToAcceptConsentFormAlert,
    ForgotToAcceptPrivacyPolicyAlert,
    ForgotToAcceptTermsAlert,
    AcceptPrivacyPolicyText,
    AcceptTermsText,
    ArrowLeftIcon,
    ModalLogo,
    Loadingspinner,
  },
  computed: {
    ...mapGetters([
      'client',
      'mobileAppMode',
      'user',
      'giftCardFlashMessage',
      'giftCardDetails',
    ]),
    minimumAmountText() {
      return this.formatCurrency(this.client.settings.gift_card_minimum_amount);
    },
  },

  watch: {
    client() {
      this.setDefaultAmount();
    },
  },

  created: async function () {

    const queryString = window.location.search.substr(1);
    const queryParams = qs.parse(queryString);

    if (queryParams.amount) {
      this.form.amount = queryParams.amount;
    }

  },

  mounted: function () {
    if (this.giftCardFlashMessage) {
      this.giftCardPaid(JSON.parse(localStorage.getItem('yogoFrontend_giftCardDetails')));
    } else {
      try {
        const giftCardDataFromSession = JSON.parse(localStorage.getItem(
            'yogoFrontend_giftCardDetails'));
        this.form.giver_name = giftCardDataFromSession.giver_name;
        this.form.giver_email = giftCardDataFromSession.giver_email;
        this.form.recipient_name = giftCardDataFromSession.recipient_name;
        this.form.amount = giftCardDataFromSession.amount;
      } catch (e) {
        //
      }

    }
    if (this.client) {
      this.setDefaultAmount();
    }
  },

  methods: {
    isRunningInIframe() {
      return ClientSite.isRunningInIframe();
    },
    clientWebsiteReferrerUrl() {
      return ClientSite.clientWebsiteReferrerUrl();
    },
    setDefaultAmount() {
      if (this.client.currency === 'EUR' || this.client.currency === 'USD') {
        this.form.amount = 50;
      } else {
        this.form.amount = 500;
      }
    },
    getValidationClass(fieldName) {
      const field = _get(this.$v, fieldName);

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
    },


    async buy() {

      this.$v.form.$touch();

      if (this.$v.form.$invalid) return;

      if (!this.termsAccepted) {
        this.showForgotToAcceptTermsAlert = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyAlert = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormAlert = true;
        return;
      }


      switch (this.client.settings.payment_service_provider) {
        case 'reepay':
          this.reepayStartGiftCardPayment(this.form);
          break;
        case 'stripe':
          this.stripeStartGiftCardPayment(this.form);
          break;
      }

    },

    giftCardPaid(giftCardDetails) {
      const successMessage = this.client.settings.gift_card_delivery_mode === 'automatic'
          ? this.$t('giftCard.PurchaseSuccessAutoSend', {
            recipientName: giftCardDetails.recipient_name,
            giverEmail: giftCardDetails.giver_email,
          })
          : this.$t('giftCard.PurchaseSuccessManualSend', {
            recipientName: giftCardDetails.recipient_name,
            giverEmail: giftCardDetails.giver_email,
          });
      alert(successMessage);
      this.$store.commit('setGiftCardFlashMessage', null);
      localStorage.removeItem('yogoFrontend_giftCardDetails');
      if (ClientSite.isRunningInIframe()) {
        ClientSite.closeOwnPopup();
      } else if (this.user) {
        this.navigateToMyProfile();

        // The app might keep the webview as it is, so we need to reset it back to the Prices page.
        if (this.mobileAppMode && semver.satisfies(this.mobileAppVersion, '>=2.11.2')) {
          this.$router.push({ name: 'Prices' });

          // Remove the Reepay dialog.
          location.reload();
        }
      } else if (this.client.website.trim()) {
        document.location = this.client.website;
      }
    },

  },
  validations() {
    return {
      form: {
        recipient_name: {
          required,
        },
        giver_email: {
          required,
          email,
        },
        giver_name: {
          required,
        },
        amount: {
          required,
          minimumAmount: minValue(this.client ? this.client.settings.gift_card_minimum_amount : 0),
        },
      },
    };
  },
};
</script>
