<template>
  <div>
    <loading-spinner/>
  </div>
</template>

<script>

import LoadingSpinner from '@/components/LoadingSpinner';
import YogoApi from '@/gateways/YogoApi';

export default {
  name: 'StripeEventSignupUpdatePaymentMethodSuccess',
  components: { LoadingSpinner },
  async created() {
    const eventSignupId = this.$route.params.eventSignupId;
    await YogoApi.post('/payments/stripe/process-event-signup-payment-method-update-if-completed', { eventSignup: eventSignupId });
    this.$store.commit('setEventSignupFlashMessage', {type: 'updated'});

    this.$router.replace({ name: 'EventSignupInstallments', params: {id: eventSignupId} });
  },
};

</script>
