<template>

  <div>

    <md-dialog :md-active="showClassDialog" v-on:update:mdActive="updateShowClassDialog">
      <loadingspinner v-if="loading"></loadingspinner>
      <template v-else>
        <a class="close-signup-modal" @click="$emit('update:showClassDialog',false)"><span
            class="icon-close"></span></a>
        <div class="modal-content">

          <div class="md:flex pt-2">
            <div class="flex-1 md:mr-4">

              <h1 class="mr-2">{{ dialogClass.class_type.name }}{{
                  dialogClass.cancelled ? ' - ' +
                      $t('global.cancelled') : ''
                }}</h1>
              <h2>{{ dialogClass.subtitle }}</h2>
              <h3>{{ formatDate(dialogClass.date, { weekday: 'long', capitalize: true }) }}</h3>
              <h3>{{ dbTimeToHumanTime(dialogClass.start_time) }} - {{
                  dbTimeToHumanTime(dialogClass.end_time)
                }}</h3>
              <div class="space2"></div>
              <p class="bold mb-0 leading-loose" v-if="dialogClass.branchName">
                {{ dialogClass.branchName }}
              </p>
              <div v-if="client.settings.calendar_show_room && dialogClass.room">
                {{ dialogClass.room.name }}
              </div>
              <h3 v-if="dialogClass.capacity_text !== ''">{{ dialogClass.capacity_text }}<br/><br/>
              </h3>


              <div
                  v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length"
                  class="bg-gray-50 md:inline-block rounded p-4"
              >
                <div class="mb-2 font-bold flex items-center text-green-700">
                  <TwoCirclesIcon class="mr-1"></TwoCirclesIcon>
                  {{ $t('calendar.classIsPartOfClassSeries') }}
                </div>

                <div
                    v-for="classSeriesType in dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream)"
                    :key="dialogClass.id + 'class_series_type_' + classSeriesType.id"
                    class="mr-2 mt-1 mb-2"
                >
                  <div
                      class="font-bold flex items-center"
                  >
                    {{ classSeriesType.name }}
                  </div>
                  <div v-if="classSeriesType.is_available_for_purchase">
                    {{ formatCurrency(classSeriesType.current_price) }}
                    <div v-if="classSeriesType.has_started">
                      ({{
                        $t('global.ReducedPriceNClassesRemaining',
                            { n: classSeriesType.number_of_remaining_classes_and_livestream_classes })
                      }})
                    </div>
                  </div>
                  <div v-else-if="classSeriesType.fully_booked_remaining_classes.length > 0">
                    {{ $t('global.FullyBooked') }}
                  </div>
                  <div v-else>
                    {{ $t('global.NotAvailable') }}
                  </div>
                  <div
                      class="font-bold text-gray-600"
                      v-if="classSeriesType.customer_has_class_series_type"
                  >
                    {{ $t('prices.YouAlreadyHaveThisClassSeries') }}
                  </div>
                </div>
              </div>

              <DialogTeacherInfo :teachers="dialogClass.teachers"></DialogTeacherInfo>

            </div>

            <div class="flex flex-1 items-start">
              <img
                  class="modal__image--standard"
                  :src="Imgix(dialogClass.class_type.image.filename, {w:900, h:600, fit:'crop'})"
                  v-if="dialogClass.class_type.image"
                  alt=""
              />
            </div>

          </div>

          <div class="line my-4"></div>

          <DialogAttendeesInfo
              v-if="client.settings.calendar_show_public_class_attendee_list && dialogClass.studio_attendance_enabled"
              :attendees="dialogClass.public_attendee_list"
              class="my-4"
          >
          </DialogAttendeesInfo>

          <nl2br tag="p" :text="dialogClass.class_type.description"></nl2br>

        </div>
        <md-dialog-actions class="flex-wrap items-end">

          <div
              v-if="dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream).length"
              class="w-full md:w-auto"
          >
            <div
                v-for="classSeriesType in dialogClass.class_series_types.concat(dialogClass.class_series_types_livestream)"
                :key="dialogClass.id + 'class_series_type_' + classSeriesType.id"
            >
              <button
                  @click="buyClassSeriesType(classSeriesType)"
                  class="btn btn-primary w-full md:w-auto mr-2 mb-2 mt-1"
                  v-if="classSeriesType.customer_can_buy_class_series_type"
              >
                {{ $t('classDialog.BuyClassSeriesName', { classSeriesName: classSeriesType.name }) }}
              </button>
            </div>
          </div>

          <div class="w-full md:w-auto md:ml-2 flex flex-wrap">
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto" disabled
                    v-if="dialogClass.cancelled">
              {{ $t('calendar.classIsCancelled') }}
            </button>
            <button
                class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                disabled
                v-else-if="dialogClass.user_is_signed_up_for_class"
            >
              {{ $t('global.youAreSignedUp') }}
            </button>

            <span v-if="currentlyEditingSignupCount" class="flex w-full md:w-auto">
               <YogoNumberInput
                   v-if="currentlyEditingSignupCount"
                   v-model="userSignupCount"
                   :min="0"
                   :max="dialogClass.seats - dialogClass.signup_count + dialogClass.user_signup_count"
               ></YogoNumberInput>
              <button @click.prevent="changeSignupCountSave"
                      class="btn btn-primary mb-2 mt-1 md:mr-2">
                {{ $t('global.Ok') }}
              </button>
            </span>

            <span v-else-if="client.settings.customer_can_reserve_multiple_seats_for_class
            && dialogClass.user_is_signed_up_for_class" class="w-full md:w-auto mr-2">
              <button @click.prevent="changeSignupCountClick"
                      class="btn btn-primary mb-2 mt-1 md:mr-2 w-full md:w-auto">
                {{ $tc('global.NSeats', dialogClass.user_signup_count) }}
                |
                {{ $t('global.Change') }}
              </button>
            </span>


            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto" disabled

                    v-if="dialogClass.user_is_signed_up_for_livestream">
              {{ $t('global.youAreSignedUpForLivestream') }}
            </button>
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto" disabled
                    v-else-if="dialogClass.user_is_signed_up_for_waiting_list">
              {{
                $t('global.youAreNumberXOnTheWaitlist', {
                  number:
                  dialogClass.user_number_on_waiting_list,
                })
              }}
            </button>
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto" disabled
                    v-else-if="dialogClass.class_has_started">
              {{ $t('calendar.classHasStarted') }}
            </button>
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto" disabled
                    v-else-if="dialogClass.class_is_fully_booked">
              {{ $t('calendar.classIsFull') }}
            </button>

            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="startLivestream(dialogClass)"
                    v-if="dialogClass.user_can_start_livestream">
              {{ $t('global.startLivestream') }}
            </button>

            <div class="mr-2 w-full md:w-auto flex justify-center">
              <!-- This number input is only used for initial signups, not adjusting number of signups -->
              <YogoNumberInput
                  v-if="showSignupButton
                  && client.settings.customer_can_reserve_multiple_seats_for_class
                  && !dialogClass.class_is_part_of_locked_class_series_type"
                  v-model="userSignupCount"
                  :min="1"
                  :max="dialogClass.seats - dialogClass.signup_count"
              ></YogoNumberInput>
            </div>
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="$emit('update:showClassDialog',false);signUpForClass(dialogClass);"
                    v-if="showSignupButton">
              <span
                  v-if="dialogClass.class_series_types.length || dialogClass.class_series_types_livestream.length">{{
                  $t('global.signUpForThisClassOnly')
                }}</span>
              <span v-else>{{ $t('global.signup') }}</span>
            </button>

            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="$emit('update:showClassDialog',false);signOffFromClass(dialogClass);"
                    v-if="dialogClass.user_can_sign_off_from_class || dialogClass.user_can_sign_off_from_livestream"
            >
              <span v-if="dialogClass.user_signup_count > 1">{{
                  $t('global.cancelNBookings',
                      { n: dialogClass.user_signup_count })
                }}</span>
              <span v-else>{{ $t('global.cancelBooking') }}</span>
            </button>

            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="$emit('update:showClassDialog',false);signUpForWaitingList(dialogClass);"
                    v-if="showWaitingListSignupButton">
              {{ $t('global.joinWaitlist') }}
            </button>
            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="$emit('update:showClassDialog',false);signOffFromWaitingList(dialogClass);"
                    v-if="dialogClass.user_can_sign_off_from_waiting_list"
            >
              {{ $t('global.removeFromWaitlist') }}
            </button>

            <button class="btn btn-primary mb-2 mt-1 w-full md:w-auto"
                    @click="$emit('update:showClassDialog',false);signUpForLivestream(dialogClass);"
                    v-if="showLivestreamSignupButton">
              <span
                  v-if="dialogClass.class_series_types.length || dialogClass.class_series_types_livestream.length">
                {{ $t('global.SignUpForLivestreamThisClassOnly') }}
              </span>
              <span v-else>
                {{ $t('global.signUpForLivestream') }}
              </span>
            </button>

          </div>

        </md-dialog-actions>
      </template>
    </md-dialog> <!-- modal -->


    <ClassSignup
        ref="classSignupComponent"
        @classSignupSuccess="onClassSignupSuccess"
        @classSignoffSuccess="onClassSignoffSuccess"
        @classWaitingListSignupSuccess="onClassWaitingListSignupSuccess"
        @classWaitingListSignoffSuccess="onClassWaitingListSignoffSuccess"
        @classLivestreamSignupSuccess="onClassLivestreamSignupSuccess"
        @classLivestreamSignoffSuccess="onClassLivestreamSignoffSuccess"
    ></ClassSignup>

  </div>
</template>

<script>

import ClassSignup from '@/components/ClassSignup.vue';
import DialogTeacherInfo from "./DialogTeacherInfo";
import DialogAttendeesInfo from "./DialogAttendeesInfo";
import LoadingSpinner from '../LoadingSpinner.vue';

import moment from 'moment';
import { mapGetters } from 'vuex';

import Imgix from '../../services/Imgix';
import currencyFormatters from '@/mixins/currencyFormatters';
import YogoApi from '../../gateways/YogoApi';
import TwoCirclesIcon from "../../graphics/icons/TwoCirclesIcon";
import YogoNumberInput from '@/components/ui/YogoNumberInput';
import dateTimeFunctions from '@/mixins/dateTimeFunctions.vue';

export default {
  components: {
    ClassSignup,
    DialogTeacherInfo,
    DialogAttendeesInfo,
    loadingspinner: LoadingSpinner,
    TwoCirclesIcon,
    YogoNumberInput,
  },
  mixins: [Imgix, currencyFormatters, dateTimeFunctions],
  props: [
    'showClassDialog',
    'dialogClass',
  ],
  data() {
    return {
      loading: false,
      userSignupCount: 1,
      currentlyEditingSignupCount: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
    ]),
    showSignupButton() {
      // If the user is already signed up, there is another counter for increasing number of seats
      return this.dialogClass.class_accepts_customer_signups
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_livestream
          && !(
              this.dialogClass.class_is_part_of_locked_class_series_type
              && !this.dialogClass.user_can_sign_up_for_class_locked_by_class_series
          )
          ;
    },
    showLivestreamSignupButton() {
      return this.dialogClass.class_accepts_livestream_signups
          && !this.dialogClass.user_is_signed_up_for_livestream
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_waiting_list;
    },
    showWaitingListSignupButton() {
      return this.dialogClass.class_accepts_waiting_list_signups
          && !this.dialogClass.user_is_signed_up_for_livestream
          && !this.dialogClass.user_is_signed_up_for_class
          && !this.dialogClass.user_is_signed_up_for_waiting_list
          && !(
              this.dialogClass.class_is_part_of_locked_class_series_type
              && !this.dialogClass.user_can_sign_up_for_class_locked_by_class_series
          );
    },
  },

  methods: {

    moment(date) {
      return moment(date);
    },
    dbTimeToHumanTime(dbTime) {
      return dbTime.substring(0, 5);
    },
    updateShowClassDialog(showClassDialog) {
      this.$emit('update:showClassDialog', showClassDialog);
    },

    async signUpForClass(classItem) {
      await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(classItem,
          this.userSignupCount);
    },

    async onClassSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    async signOffFromClass(classItem) {
      if (classItem.user_is_signed_up_for_livestream) {
        this.$refs.classSignupComponent.$emit('signOffFromClass', classItem);
      } else {
        await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(classItem, 0);
      }
    },

    async onClassSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signUpForWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForWaitingList', classItem);
    },

    async onClassWaitingListSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signOffFromWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromWaitingList', classItem);
    },

    async onClassWaitingListSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signUpForLivestream(classItem) {
      this.$refs.classSignupComponent.$emit('signUpForLivestream', classItem);
    },

    async onClassLivestreamSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    signOffFromLivestream(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromLivestream', classItem);
    },

    async onClassLivestreamSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showClassDialog', false);
    },

    primaryActionClick(action) {
      this.$refs.classSignupComponent.$emit('classPrimaryActionClick', action);
    },

    startLivestream(classItem) {
      this.$router.push({ name: 'LivestreamClassPreloader', params: { classId: classItem.id } });
    },

    async buyClassSeriesType(classSeriesType) {
      this.addToCart({
        item_type: 'class_series_type',
        item_id: classSeriesType.id,
        user: this.user.id,
      });
    },

    async addToCart(cartItem) {

      this.loading = true;

      await YogoApi.post('/cart-items', cartItem);
      await this.$store.dispatch('updateUser');

      this.$router.push({ name: 'Checkout' });
      this.loading = false;

    },

    changeSignupCountClick() {
      this.userSignupCount = this.dialogClass.signup_count;
      this.currentlyEditingSignupCount = true;
    },

    async changeSignupCountSave() {
      if (!window.confirm(this.$t(
          'classDialog.ChangeNumberOfSignupsConfirm',
          {
            from: this.dialogClass.signup_count,
            to: this.userSignupCount,
          },
      ))) {
        return;
      }
      await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(this.dialogClass,
          this.userSignupCount);
    },

  },

  watch: {
    showClassDialog(newShowClassDialog) {
      if (newShowClassDialog) {
        this.currentlyEditingSignupCount = false;
        if (this.dialogClass.user_is_signed_up_for_class) {
          this.userSignupCount = this.dialogClass.user_signup_count;
        } else {
          this.userSignupCount = 1;
        }
      }
    },
  },

};

</script>
