<template>
  <div :class="{ 'wrapper' : !mobileAppMode }">

    <div class="theme--frame-box">

      <loadingspinner v-if="loading"></loadingspinner>

      <div v-else>

        <router-link :to="{ name: 'MyProfile' }" class="flex aic">
          <arrow-left-icon/>
          <span class="pl-1">
          {{ $t('global.back') }}
        </span>
        </router-link>

        <div class="my-10" v-if="bulletinBoardEnabled">

          <h1 class="mb-4">{{ client.settings.bulletin_board_title }}</h1>

          <p class="text-base user-html" v-html="client.settings.bulletin_board_content_html"/>

        </div>

        <div v-else>
          <h3 class="mt-4">{{ $t('bulletinBoard.TheBulletinBoardIsNotEnabled') }}</h3>
        </div>


      </div>
    </div>

    <div v-if="!mobileAppMode">
      <div class="space8"></div>
      <div class="space8"></div>
      <div class="space8"></div>
    </div>

  </div>
</template>

<script>

import Loadingspinner from "./LoadingSpinner.vue";
import { mapGetters } from 'vuex';
import ArrowLeftIcon from '@/graphics/icons/ArrowLeftIcon.vue';
import YogoApi from '@/gateways/YogoApi';
import qs from 'qs';

export default {
  name: 'BulletinBoard',
  components: {
    Loadingspinner,
    ArrowLeftIcon,
  },
  data() {
    return {

      loading: true,

      bulletinBoardEnabled: false,
      bulletinBoardTitle: '',
      bulletinBoardContentHtml: '',

    };
  },
  computed: {
    ...mapGetters([
      'client',
      'mobileAppMode',
    ]),
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {

      const clientSettingsQueryString = qs.stringify({
        keys: [
          'bulletin_board_enabled',
          'bulletin_board_title',
          'bulletin_board_content_html',
        ],
      });

      ({
        bulletin_board_enabled: this.bulletinBoardEnabled,
        bulletin_board_title: this.bulletinBoardTitle,
        bulletin_board_content_html: this.bulletinBoardContentHtml,
      } = await YogoApi.get(`/clients/${this.client.id}/settings?${clientSettingsQueryString}`));
      this.loading = false;
    },
  },
};
</script>

<style>


</style>
