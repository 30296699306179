<template>
  <div class="cursor-pointer h-20 svg-icon">
    <svg
      width="auto"
      height="auto"
      viewBox="0 0 343 799"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
    <title>talk</title>
    <g id="Page-1" stroke="currentColor" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="talk" transform="translate(8.000000, 9.000000)" stroke="currentColor" stroke-width="15.84">
            <path d="M42.72,299.11 L150.94,312.31 C150.94,312.31 182.61,336.06 187.89,343.98" id="Path" stroke-linejoin="round"></path>
            <line x1="83.67" y1="248.35" x2="83.67" y2="307" id="Path" stroke-linejoin="round"></line>
            <path d="M86.67,338.7 C74.39,507.63 99.86,678.6 99.86,789.61" id="Path" stroke-linejoin="round"></path>
            <path d="M206.28,248.35 C219.57,486.51 195.73,656 195.73,789.61" id="Path" stroke-linejoin="round"></path>
            <line x1="147.79" y1="448.11" x2="147.79" y2="789.61" id="Path" stroke-linejoin="round"></line>
            <path d="M327,190.89 L327,232.52 L246,328.14 C237.48,318.67 217.57,298.03 209.05,288.55" id="Path" stroke-linejoin="round"></path>
            <ellipse id="Oval" transform="translate(130.149480, 75.448605) rotate(-10.340000) translate(-130.149480, -75.448605) " cx="130.14948" cy="75.4486047" rx="48.2" ry="67.05"></ellipse>
            <path d="M174.69,370.37 L153.58,370.37 L117.18,338.7 L0.48,336.06 C0.48,336.06 26.88,243.68 77.38,185.79 C77.64,185.49 111.13,177.97 144.23,178.07 C179.57,178.18 213.78,186.21 213.78,186.21 L248.6,272.71 C248.6,272.71 296.79,222.56 296.79,213.97 C296.79,190.88 291.42,151.85 322.79,145.24" id="Path" stroke-linejoin="round"></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>

  export default {}

</script>

<style lang="scss">
  .svg-icon {
    svg {
      width: 100%;
      height: 100%;
    }
  }
</style>
