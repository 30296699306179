import Vue from 'vue';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';

import App from './App';
import store from './store';
import router from './router';
import i18n from './includes/i18n';
import VueBrowserUpdate from 'vue-browserupdate';
// VueMaterial
import VueMaterial from 'vue-material';
import 'vue-material/dist/vue-material.min.css';
// Nl2br
import Nl2br from 'vue-nl2br';

Bugsnag.start({
  apiKey: 'fcfbeba85c51b0a0dbaffacc4cc2e9da',
  plugins: [new BugsnagPluginVue()],
  appVersion: process.env.VUE_APP_GIT_COMMIT ? process.env.VUE_APP_GIT_COMMIT.substr(0,8) : undefined,
  releaseStage: process.env.VUE_APP_RELEASE_STAGE || undefined,
});
//const bugsnagVue = Bugsnag.getPlugin('vue');
//bugsnagVue.installVueErrorHandler(Vue);

Vue.config.productionTip = false;

// All styles
require('./assets/styl/style.styl');
require('./assets/scss/style.scss');

Vue.use(VueBrowserUpdate, {
  options: {},
});

// Load all filters
require('./filters/index');

//import './assets/external/vue-material/vue-material.scss';
// Material theme is loaded dynamically from S3/Lambda

Vue.use(VueMaterial);

Vue.component('nl2br', Nl2br);

// UGLY hack to circumvent a bug in Vue material... sorry...
// The error is thrown by md-select, but everything seems to work.
/*Vue.config.errorHandler = (err) => {
  //if (process.env.NODE_ENV !== 'production') {
  // Show any error but this one
  if (err.message !== "Cannot read properties of undefined (reading 'badInput')") {
    console.error(err);
  }
  //}
};*/

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
}).$mount('#app');
