<template>
  <div class="checkout" :class="{ 'wrapper' : !mobileAppMode }">
    <div v-if="!mobileAppMode">
      <h1>{{ $t('checkout.checkoutReady') }}</h1>
      <div class="space8"></div>
    </div>
    <div class="theme--frame-box">
      <loadingspinner v-if="loading" :text="loadingText"></loadingspinner>
      <div class="checkout__content--wrapper" v-else-if="this.cartItems.length">
        <md-table v-model="sortedCartItems">
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell :md-label="$t('checkout.cartItemName')">
              {{ item.cartTitle }}
              <div v-if="item.cartSubText">
                {{ item.cartSubText }}
              </div>
            </md-table-cell>
            <md-table-cell md-numeric :md-label="$t('global.price')">
              {{ formatCurrency(item.cartPrice) }}
            </md-table-cell>
            <md-table-cell>
              <button type="submit" @click="deleteCartItem(item)" v-if="!item.suppressRemoveIcon">
                <span class="icon-close pointer"></span>
                <md-tooltip md-direction="top">{{ $t('checkout.removeFromBasket') }}</md-tooltip>
              </button>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="line"></div>
        <div class="space8"></div>
        <form class="flex--row" action="" @submit.stop.prevent="applyDiscountCode"
              v-if="showDiscountCodeInput">
          <div class="flex col-md-4 mr-md-4">
            <md-field>
              <label>{{ $t('checkout.DiscountCodeSlashGiftCard') }}</label>
              <md-input v-model="discountCodeInput"></md-input>
            </md-field>
          </div>
          <button
              class="btn btn-primary mb-btn-align"
              :class="{ 'btn-secondary-app': mobileAppMode }"
              type="submit"
          >
            {{ $t('checkout.ApplyCode') }}
          </button>
        </form>
        <div class="flex--row space--between align--top mt-4">
          <div>
            <label
                class="checkbox"
            >
              <input
                  v-model="termsAccepted"
                  type="checkbox"
              >
              <span class="leading-tight">
                <accept-terms-text @linkClick="showTermsDialog = true" />
              </span>
            </label>
            <div class="mt-2">
              <label
                  class="checkbox"
                  v-if="showRecurringTerms"
              >
                <input
                    v-model="membershipTermsAccepted"
                    type="checkbox"
                >
                <span class="leading-tight">
                  <accept-membership-terms-text @linkClick="showMembershipTermsDialog = true" />
                </span>
              </label>
            </div>
            <div class="mt-2" v-if="client.settings.customer_must_accept_the_privacy_policy">
              <label
                  class="checkbox"
              >
                <input
                    v-model="privacyPolicyAccepted"
                    type="checkbox"
                >
                <accept-privacy-policy-text class="leading-tight" @linkClick="showPrivacyPolicyDialog = true"/>
              </label>
            </div>
            <div class="mt-2" v-if="client.settings.customer_must_accept_the_consent_form">
              <label
                  class="checkbox"
              >
                <input
                    v-model="consentFormAccepted"
                    type="checkbox"
                >
                <accept-consent-form-text class="leading-tight" @linkClick="showConsentFormDialog = true"/>
              </label>
            </div>
          </div>
          <div class="space4"></div>
          <h3 class="bold">Total: {{
              formatCurrency(totalPrice,
                  client.settings.payment_show_dankort_logo)
            }} </h3>
        </div>
        <div class="space8"></div>
        <button
            class="btn btn-primary"
            :class="{ 'btn-primary-app w-full mt-2': mobileAppMode }"
            :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
            @click="pay"
            :disabled="!this.cartItems.length"
        >
          {{ $t('checkout.proceedToPayment') }}
        </button>
        <div class="space6"></div>
        <div class="flex flex-wrap flex--row payment--symbols">
          <img
            class="mb-1"
            v-if="client.country === 'DK' && client.settings.payment_show_dankort_logo"
            src="../assets/img/dankort.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.settings.payment_show_visa_mastercard_logos"
            src="../assets/img/visa.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.settings.payment_show_visa_mastercard_logos"
            src="../assets/img/visaelectron.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.settings.payment_show_visa_mastercard_logos"
            src="../assets/img/mastercard.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.settings.payment_show_visa_mastercard_logos"
            src="../assets/img/maestro.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.country === 'DK' && client.settings.payment_show_mobilepay_logo"
            src="../assets/img/mobilepay.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.country === 'NO' && client.settings.payment_show_vipps_logo"
            src="../assets/img/vipps.svg" alt=""
          />
          <img
            class="mb-1"
            v-if="client.country === 'DE' && client.settings.payment_show_sepa_logo"
            src="../assets/img/SepaLogoDE.jpg" alt=""
          />
        </div>
      </div>
      <div v-else>
        {{ $t('checkout.noItemsInBasket') }}
      </div>
    </div>
    <div class="space8"></div>
    <div class="space8"></div>

    <forgot-to-accept-terms-alert :show.sync="showForgotToAcceptTermsDialog"/>
    <forgot-to-accept-membership-terms-alert :show.sync="showForgotToAcceptMembershipTermsDialog"/>
    <forgot-to-accept-privacy-policy-alert :show.sync="showForgotToAcceptPrivacyPolicyDialog"/>
    <forgot-to-accept-consent-form-alert :show.sync="showForgotToAcceptConsentFormDialog"/>

    <md-snackbar
        :md-position="position"
        :md-duration="isInfinity ? Infinity : duration"
        :md-active.sync="showItemDeletedSnackbar" md-persistent>
      {{ removedItemName }} {{ $t('checkout.isRemovedFromBasket') }}
    </md-snackbar>

  </div>
</template>
<script>
import YogoApi from '../gateways/YogoApi';
import { mapGetters } from 'vuex';
import _each from 'lodash/each';
import _find from 'lodash/find';
import Loadingspinner from "./LoadingSpinner.vue";
import ReepayPaymentMixin from './ReepayPaymentMixin';
import StripePaymentMixin from './StripePaymentMixin';
import currencyFormatters from '@/mixins/currencyFormatters';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import { DateTime } from 'luxon';
import AcceptTermsText from '@/components/fragments/AcceptTermsText.vue';
import AcceptMembershipTermsText from '@/components/fragments/AcceptMembershipTermsText.vue';
import AcceptPrivacyPolicyText from '@/components/fragments/AcceptPrivacyPolicyText.vue';
import AcceptConsentFormText from '@/components/fragments/AcceptConsentFormText.vue';
import ForgotToAcceptConsentFormAlert
  from '@/components/fragments/ForgotToAcceptConsentFormAlert.vue';
import ForgotToAcceptTermsAlert from '@/components/fragments/ForgotToAcceptTermsAlert.vue';
import ForgotToAcceptMembershipTermsAlert
  from '@/components/fragments/ForgotToAcceptMembershipTermsAlert.vue';
import ForgotToAcceptPrivacyPolicyAlert
  from '@/components/fragments/ForgotToAcceptPrivacyPolicyAlert.vue';

export default {
  components: {
    ForgotToAcceptPrivacyPolicyAlert,
    ForgotToAcceptMembershipTermsAlert,
    ForgotToAcceptTermsAlert,
    ForgotToAcceptConsentFormAlert,
    AcceptConsentFormText,
    AcceptPrivacyPolicyText,
    AcceptMembershipTermsText,
    AcceptTermsText,
    Loadingspinner },
  mixins: [ReepayPaymentMixin, StripePaymentMixin, currencyFormatters, dateTimeFunctions],
  data() {
    return {
      cartItems: [],
      orderTotal: 0,
      orderVatAmount: 0,
      showTermsDialog: false,
      showMembershipTermsDialog: false,
      showPrivacyPolicyDialog: false,
      showConsentFormDialog: false,
      termsAccepted: false,
      membershipTermsAccepted: false,
      privacyPolicyAccepted: false,
      consentFormAccepted: false,
      showForgotToAcceptTermsDialog: false,
      showForgotToAcceptMembershipTermsDialog: false,
      showForgotToAcceptPrivacyPolicyDialog: false,
      showForgotToAcceptConsentFormDialog: false,
      showItemDeletedSnackbar: false,
      position: 'center',
      duration: 4000,
      isInfinity: false,
      removedItemName: '',
      loading: true,
      loadingText: '',
      discountCodeInput: '',
      reepayWindow: null,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'stateReady',
      'mobileAppMode',
    ]),
    showDiscountCodeInput() {
      const discountCodeCartItem = _find(this.cartItems, ['item_type', 'discount_code']);
      return this.client.settings.checkout_show_discount_code_input && !discountCodeCartItem;
    },

    terms() {
      return this.client && this.client.terms ? this.client.terms : '';
    },

    recurring_terms() {
      return this.client && this.client.recurring_terms ? this.client.recurring_terms : '';
    },

    privacy_policy() {
      return this.client && this.client.privacy_policy ? this.client.privacy_policy : '';
    },

    consent_form() {
      return this.client && this.client.consent_form ? this.client.consent_form : '';
    },

    totalPrice() {
      return this.orderTotal;
    },

    sortedCartItems() {
      const itemsIncludingExtraItems = [];
      _each(this.cartItems, item => {
        item.cartTitle = this.getItemTitle(item);
        item.cartSubText = this.getItemSubtext(item);
        item.cartPrice = this.getItemPrice(item);
        item.suppressRemoveIcon = item.item_type === 'membership_registration_fee' || item.item_type === 'membership_benefit_discount';
        itemsIncludingExtraItems.push(item);
      });
      return itemsIncludingExtraItems;
    },
    showRecurringTerms() {
      return !!_find(this.cartItems,
          { item_type: 'membership_type' }) && this.client.settings.show_recurring_terms;
    },
  },
  async mounted() {
    await this.updateCart();
  },

  methods: {
    async updateCart() {
      this.loading = true;
      ({
        orderItems: this.cartItems,
        orderTotal: this.orderTotal,
        orderVatAmount: this.orderVatAount,
      } = await YogoApi.get(`/cart/processed?user=${this.user.id}`));
      this.loading = false;
    },
    getItemTitle(cartItem) {
      let itemTitle = cartItem.name;

      if (cartItem.item_type === 'gift_card_spend') {
        itemTitle = this.$t('checkout.GiftCard') + ' (' + cartItem.additional_details.gift_card_code + ')';
      }

      if (cartItem.item_type === 'appointment') {
        const appointmentDateFormatted = this.formatDate(cartItem.appointment_details.date,
            { weekday: true });
        const appointmentStartTime = cartItem.appointment_details.start_time.substr(0, 5);

        itemTitle += ` - ${appointmentDateFormatted} ${appointmentStartTime}`;
        if (cartItem.appointment_details.teacher_name) {
          itemTitle += ` ${this.$t('global.with')} ${cartItem.appointment_details.teacher_name}`;
        }
        if (cartItem.appointment_details.branch_name) {
          itemTitle += ` ${this.$t('global.inBranchName', { branchName: cartItem.appointment_details.branch_name })}`;
        }
      }
      return itemTitle;
    },
    getItemSubtext(cartItem) {
      let itemSubtext = '';
      if (cartItem.use_immediately_after_purchase) {
        if (cartItem.use_immediately_after_purchase.item_type === 'appointment') {
          const appointmentDateFormatted = this.formatDate(cartItem.use_immediately_after_purchase.date,
              { weekday: true });
          const appointmentStartTime = cartItem.use_immediately_after_purchase.start_time.substr(0,
              5);
          const appointmentTypeName = cartItem.use_immediately_after_purchase.appointment_type_name;
          itemSubtext += `${this.$t('global.CreateAppointment')}: ${appointmentTypeName} ${appointmentDateFormatted} ${appointmentStartTime}`;
          if (cartItem.use_immediately_after_purchase.teacher) {
            itemSubtext += ` ${this.$t('global.with')} ${cartItem.use_immediately_after_purchase.teacher_name}`;
          }
          if (cartItem.use_immediately_after_purchase.branch) {
            itemSubtext += ` ${this.$t('global.inBranchName', { branchName: cartItem.use_immediately_after_purchase.branch_name })}`;
          }
        }
        if (
            cartItem.use_immediately_after_purchase.item_type === 'class'
        ) {
          const seats = cartItem.use_immediately_after_purchase.numberOfSeats;
          itemSubtext = this.$t('checkout.AfterPurchaseReserveXOnClass', {
            x: this.$tc('global.NSeats', seats, { n: seats }),
            class: `${cartItem.use_immediately_after_purchase.class_type_name} ${this.formatDate(cartItem.use_immediately_after_purchase.class_date)} ${cartItem.use_immediately_after_purchase.class_start_time.substring(
                0,
                5)}`,
          });
        }
        if (
            cartItem.use_immediately_after_purchase.item_type === 'class_livestream'
        ) {
          itemSubtext = this.$t('checkout.AfterPurchaseReserveLivestreamOnClass', {
            class: `${cartItem.use_immediately_after_purchase.class_type_name} ${this.formatDate(cartItem.use_immediately_after_purchase.class_date)} ${cartItem.use_immediately_after_purchase.class_start_time.substring(
                0,
                5)}`,
          });
        }
        if (
            cartItem.use_immediately_after_purchase.item_type === 'class_waiting_list'
        ) {
          itemSubtext = this.$t('checkout.AfterPurchaseReserveSpotOnWaitingListForClass', {
            class: `${cartItem.use_immediately_after_purchase.class_type_name} ${this.formatDate(cartItem.use_immediately_after_purchase.class_date)} ${cartItem.use_immediately_after_purchase.class_start_time.substring(
                0,
                5)}`,
          });
        }
      }
      if (cartItem.item_type === 'event_first_installment') {
        const totalPriceFormatted = this.formatCurrency(cartItem.additional_details.eventTotalPrice);
        if (cartItem.applied_discount_amount) {
          const totalPriceAfterDiscount = cartItem.additional_details.eventTotalPrice - cartItem.additional_details.totalAppliedDiscountAmount;
          itemSubtext += `(${this.$t('checkout.TotalPriceAfterDiscount')}: ${this.formatCurrency(totalPriceAfterDiscount)})`;
        } else if (cartItem.includes_membership_benefit_discount_amount) {
          const totalPriceAfterMembershipBenfitDiscount = cartItem.additional_details.eventTotalPrice - cartItem.additional_details.totalAppliedMembershipBenefitDiscountAmount;
          itemSubtext += `(${this.$t('checkout.TotalMemberPrice')}: ${this.formatCurrency(totalPriceAfterMembershipBenfitDiscount)})`;
        } else {
          itemSubtext += `(${this.$t('checkout.TotalPrice')}: ${totalPriceFormatted})`;
        }
      }
      if (cartItem.item_type === 'class_series_type_first_installment') {
        const totalPriceFormatted = this.formatCurrency(cartItem.additional_details.classSeriesTypeTotalPrice);
        if (cartItem.applied_discount_amount) {
          const totalPriceAfterDiscount = cartItem.additional_details.classSeriesTypeTotalPrice - cartItem.additional_details.totalAppliedDiscountAmount;
          itemSubtext += `(${this.$t('checkout.TotalPriceAfterDiscount')}: ${this.formatCurrency(totalPriceAfterDiscount)})`;
        } else if (cartItem.includes_membership_benefit_discount_amount) {
          const totalPriceAfterMembershipBenfitDiscount = cartItem.additional_details.classSeriesTypeTotalPrice - cartItem.additional_details.totalAppliedMembershipBenefitDiscountAmount;
          itemSubtext += `(${this.$t('checkout.TotalMemberPrice')}: ${this.formatCurrency(totalPriceAfterMembershipBenfitDiscount)})`;
        } else {
          itemSubtext += `(${this.$t('checkout.TotalPrice')}: ${totalPriceFormatted})`;
        }
      }

      // Add explanation if memberships renew on 1st of month
      if (
          cartItem.item_type === 'membership_type'
          && this.applyPartialMembershipPayment(cartItem)
          && cartItem.ratioOfRemainingDaysAndFullMonthDays !== 1
      ) {
        if (cartItem.additional_details.paymentOptionNumberOfMonths === 1) {
          itemSubtext = this.$t(
              'membership.TheMembershipWillRenewOnDate',
              {
                date: this.formatDate(
                    DateTime.now()
                        .setZone(this.client.timezone)
                        .startOf('month')
                        .plus({ months: 1 })
                        .toISODate(),
                ),
              },
          );
        } else {
          itemSubtext = this.$t(
              'membership.PaymentForRestOfMonthPlusNMonthsMembershipWillRenewOnDate',
              {
                months: cartItem.additional_details.paymentOptionNumberOfMonths - 1,
                date: this.formatDate(
                    DateTime.now()
                        .setZone(this.client.timezone)
                        .startOf('month')
                        .plus({ months: cartItem.additional_details.paymentOptionNumberOfMonths })
                        .toISODate(),
                ),
              },
          );
        }

      }
      return itemSubtext;
    },
    getItemPrice(cartItem) {
      return cartItem.total_price;
    },

    applyPartialMembershipPayment(membershipTypeCartItem) {
      return membershipTypeCartItem.additional_details
          && membershipTypeCartItem.additional_details.ratioOfRemainingDaysAndFullMonthDays
          && membershipTypeCartItem.additional_details.ratioOfRemainingDaysAndFullMonthDays !== 1;
    },

    pay() {
      if (this.showRecurringTerms && !this.membershipTermsAccepted) {
        this.showForgotToAcceptMembershipTermsDialog = true;
        return;
      }

      if (!this.termsAccepted) {
        this.showForgotToAcceptTermsDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormDialog = true;
        return;
      }


      this.startPayment();
    },

    async startPayment() {
      switch (this.client.settings.payment_service_provider) {
        case 'reepay':
          this.reepayStartPayment();
          break;

        case 'stripe': {
          this.loadingText = this.$t('global.StartingPayment');
          this.loading = true;
          this.stripeStartPayment();
          break;
        }
      }
    },

    async deleteCartItem(cartItem) {
      this.loading = true;
      await YogoApi.delete('/cart-items/' + cartItem.cartItemId);
      await this.updateCart();
      this.loading = false;
    },

    async applyDiscountCode() {

      if (!this.discountCodeInput) return;

      const response = await YogoApi.post('/cart-items', {
        item_type: 'discount_code',
        discount_code: this.discountCodeInput,
        user: this.user.id,
      });

      if (response === 'E_DISCOUNT_CODE_NOT_FOUND') {
        alert(this.$t('checkout.discountCodeNotFound'));
        return;
      }
      if (response === 'E_DISCOUNT_CODE_CUSTOMER_LIMIT_REACHED') {
        alert(this.$t('checkout.thisDiscountCodeIsNoLongerAvailable'));
        return;
      }
      if (response === 'E_DISCOUNT_CODE_USE_PER_CUSTOMER_LIMIT_REACHED') {
        alert(this.$t('checkout.youHaveAlreadyUsedThisDiscountCodeTheMaximumNumberOfTimes'));
        return;
      }
      if (response === 'E_GIFT_CARD_IS_USED_UP') {
        alert(this.$t('checkout.giftCardIsUsedUp'));
        return;
      }
      if (response === 'E_GIFT_CARD_HAS_EXPIRED') {
        alert(this.$t('checkout.giftCardHasExpired'));
        return;
      }
      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }
      this.discountCodeInput = '';
      await this.updateCart();
    },
  },
};
</script>
<style lang="stylus">
.md-table-cell
  padding 0

.terms-dialog
  .terms-content
    margin: 0 40px 20px;
    overflow-y: auto;

.payment--symbols
  img
    height 25px !important
    margin-right 5px !important

</style>
