<template>
  <div class="activity">
    <div
        :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
    >
      <h2>{{ $t('profile.MyClassSeries_Plural') }}</h2>
      <div class="space2"></div>
      <ul>
        <li v-for="classSeries in filteredClassSeries" :key="'classSeries' + classSeries.id">
          <div class="space2"></div>
          <h3>{{ classSeries.class_series_type.name }}</h3>
          <div v-if="classSeries.installments.filter(i => !i.paid).length > 0">
            <p v-if="classSeries.installment_payments_enabled"
               class="inline-flex items-center mt-2 rounded-full px-2.5 py-0.5 text-xs font-medium cursor-pointer"
               :class="{
            'bg-green-100 text-green-800': classSeries.installment_payments_status === 'paid_in_full',
            'bg-yellow-100 text-yellow-800': classSeries.installment_payments_status === 'on_track',
            'bg-red-100 text-red-800': classSeries.installment_payments_status === 'failed',
          }"
               @click="$router.push({name: 'ClassSeriesInstallments', params: { classSeriesId: classSeries.id}})">{{
                $t('myProfile.myClassSeries.NOfNInstallmentsPaid', {
                  paidCount: classSeries.number_of_installments_paid,
                  installmentCount: classSeries.installments.length,
                })
              }}
            </p>
          </div>
          <div v-if="classSeries.class_series_type.allow_cancel_individual_signups
                && classSeries.classes_left > 0
                && classSeries.class_series_type.valid_until >= todayIso"
          class="mt-2">
            <p>{{ $t('global.CancelledClassesAvailable') }}: {{
                classSeries.classes_left
              }}
            </p>
            <p>
              {{ $t('profile.validUntil') }}: {{
                formatDate(classSeries.class_series_type.valid_until)
              }}
            </p>
          </div>
        </li>
      </ul>

    </div>
    <div class="space4"></div>
  </div>
</template>

<script>

import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import _filter from 'lodash/filter';
import { mapGetters } from 'vuex';
import { DateTime } from 'luxon';

export default {
  name: 'MyClassSeries',
  props: ['classSeries'],
  mixins: [dateTimeFunctions],
  computed: {
    ...mapGetters([
      'mobileAppMode',
      'client',
    ]),
    todayIso() {
      return DateTime.now().setZone(this.client.timezone).toISODate();
    },
    filteredClassSeries() {
      return _filter(
          this.classSeries,
          classSeriesObj => {
            const classSeriesHasClassesAvailable = classSeriesObj.class_series_type.allow_cancel_individual_signups
                && classSeriesObj.class_series_type.valid_until >= this.todayIso
                && classSeriesObj.classes_left > 0;

            const classSeriesHasUnpaidInstallments = classSeriesObj.installments.filter(i => !i.paid).length > 0;

            return classSeriesHasClassesAvailable || classSeriesHasUnpaidInstallments;
          },
      );
    },
  },
};
</script>
