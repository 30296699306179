<template>
  <div class="mt-1 relative yogo-datepicker">
    <label>{{ label }}</label>
    <div
        class="flex flex-shrink-0 items-center mb-5 border-b relative input-wrapper"
        :class="[{ 'border-none pl-20': borderNone }]"
    >

      <span
        class="calendar-icon"
        v-if="!isMobile"
      >
        <CalendarIcon></CalendarIcon>
      </span>

      <VueJSDatepicker
          v-if="!isMobile"
          v-model="localJSDate"
          :language="language"
          :monday-first="true"
          class="datepicker"
          :disabledDates="disabledDates"
      >
      </VueJSDatepicker>

      <input
        type="date"
        v-else
        :min="todayIso"
        v-model="localISODate"
        class="pl-10 h-6"
      >

      <div v-if="clearable && value" @click="clearDate" class="close-icon cursor-pointer">
        <close-icon/>
      </div>

    </div>

    <span class="error mt-2">
      <slot name="datepickerError"></slot>
    </span>

  </div>


</template>

<script>

import { da, en, nbNO, sv, de } from 'vuejs-datepicker/dist/locale';
import VueJSDatepicker from 'vuejs-datepicker';
import CalendarIcon from '../graphics/icons/CalendarIcon.vue';
import { mapGetters } from 'vuex';
import CloseIcon from '@/graphics/CloseIcon';
import { DateTime } from 'luxon';
import device from '@/mixins/device.vue';

export default {
  components: { CloseIcon, VueJSDatepicker, CalendarIcon },
  mixins: [device],
  data() {
    return {
      localJSDate: null,
      localISODate: null,
      todayIso: DateTime.now().toISODate(),
    };
  },
  props: ['value', 'clearable', 'label', 'disabledDates', 'borderNone'],
  computed: {
    ...mapGetters([
      'client',
    ]),
    language() {
      const languages = { en, da, nb: nbNO, sv, de };
      const locale = this.client && this.client.settings ? this.client.settings.locale : 'en';
      return languages[locale];
    },
  },
  methods: {
    clearDate() {
      this.$emit('input', null);
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.localJSDate = newValue ? DateTime.fromISO(newValue)
            .setZone(this.client.timezone)
            .toJSDate() : null;
        this.localISODate = newValue;
      },
      immediate: true,
    },
    localJSDate(newDate) {
      this.$emit('input', newDate ? DateTime.fromJSDate(newDate).toISODate() : null);
    },
    localISODate(newDate) {
      this.$emit('input', newDate);
    },
  },
};

</script>

<style lang="scss" scoped>

.input-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.calendar-icon {
  position: absolute;
  top: 2px;
}

.vdp-datepicker {
  position: relative;
  padding: 6px 5px 6px;

  ::v-deep input {
    padding-left: 25px;
    width: 100%;
    background: none;
    font-size: 16px;
  }

}

.close-icon {
  position: absolute;
  right: 4px;
  top: 4px;
}

.error {
  position: absolute;
  left: 0;
  top: 40px;
  color: red;
  font-size: 12px;
  display: none;
}

.yogo-datepicker.md-invalid .error {
  display: inline;
}


</style>
