<template>
  <div>
    <loadingspinner v-if="loading"></loadingspinner>
    <div :class="{hidden:loading}">
      <!-- Use class="hidden", not v-if. With v-if, the birthdatepicker is reset -->

      <div class="bg-white flex justify-center lg:justify-start lg:bg-transparent">
        <div class="flex flex-col lg:flex-row lg:px-0 max-w-xl lg:max-w-none w-full">

          <div
              class="lg:px-4 flex items-center justify-center flex-col lg:w-full order-1t lg:order-2">

            <ul class="w-full">

              <li v-if="localCartItem">
                <div class="flex justify-center flex-col items-center"
                     v-if="localCartItem.itemType =='appointment'">

                  <div class="text-center flex flex-col" v-if="this.user">
                    <h2 class="mb-4 font-bold">{{ $t('login.Hi') }} {{ this.user.first_name }}!</h2>
                    <ProfileImage class="flex self-center" :image="this.user.image" :size="90"/>
                  </div>

                  <h2 class="my-8 py-4 bold px-4 md:px-10 flex flex-col rounded-lg shadow-lg overflow-hidden">
                    <div class="text-center">
                      <div class="text-base font-normal">{{ $t('login.YouAreAboutToBook') }}</div>
                      <h6>{{ appointmentType ? appointmentType.name : '' }}</h6>
                      <div class="text-base" v-if="teacher">
                        {{ $t('global.with') }}
                      </div>
                      <h6 class="text-base font-extrabold" v-if="teacher">
                        {{ `${teacher.first_name} ${teacher.last_name}` }}
                      </h6>
                      <div class="mb-4 text-base font-bold mt-2">
                        {{ $t('global.Date') }}: <span>{{ formatDate(localCartItem.date) }} </span>
                        <br>
                        {{ $t('global.Time') }}: <span>{{
                          localCartItem.start_time.substr(0, 5)
                        }} </span>
                      </div>
                    </div>
                  </h2>

                  <h3 class="text-center font-bold mb-8" v-if="user">
                    {{ $t('global.HowWouldYouLikeToPay') }}
                  </h3>
                  <h3 class="text-center font-bold" v-else>
                    {{ $t('global.PleaseLogInOrSignUpToBookAppointment') }}
                  </h3>

                  <!-- Customer's existing access items -->
                  <div class="flex justify-center flex-col items-center w-full"
                       v-if="appointmentShowPaymentOptions">
                    <div class="flex flex-col w-full" v-if="userClassPasses.length > 0">
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(class_pass, idx) in userClassPasses"
                          :key="'user_class_pass_' + idx"
                      >
                        <div class="flex items-center mb-2">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="'class_pass_' + class_pass.id"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                          >
                          <span class="flex space-between w-full border-b pb-1 flex-wrap">
                              <span class="font-bold">
                                {{ class_pass.class_pass_type.name }}
                              </span>
                              {{ class_pass.classes_left + ' ' }} {{
                              $t('global.appointmentsLeft')
                            }}
                            </span>
                        </div>
                      </label>
                    </div>
                    <div v-if="userMemberships.length > 0">
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(membership, idx) in userMemberships"
                          :key="'user_membership' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="'membership_' + membership.id"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                          >
                          <span class="font-bold">
                            {{ membership.membership_type.name }}
                          </span>
                        </div>
                      </label>
                    </div>

                    <!-- The button that toggles visibility of payment options that are not customer's existing -->
                    <div
                        v-if="appointmentCustomerHasExistingAccess"
                    >
                      <button
                          @click="appointmentShowMorePaymentOptions = !appointmentShowMorePaymentOptions"
                          class="mt-2 flex self-center btn btn-secondary mb-4"
                          :class="{ 'btn-small-app w-full': mobileAppMode }"
                      >
                        {{
                          appointmentShowMorePaymentOptions ? $t('global.LessOptions') : $t(
                              'global.MoreOptions')
                        }}
                      </button>
                    </div>

                    <!-- Payment options that are NOT customer's existing access items-->
                    <div
                        v-if="!appointmentCustomerHasExistingAccess || appointmentShowMorePaymentOptions"
                        class="flex flex-col items-start w-full"
                    >
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-if="appointmentType.direct_purchase_enabled"
                      >
                        <div class="flex items-center pb-1">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              value="directPurchase"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                          >
                          <span class="w-full flex space-between border-b flex-wrap align__items--center">
                            <span class="font-bold">{{ $t('global.Buy') }}</span>
                            <span>
                              <span :class="{strikethrough: appointmentType.has_member_discount}">{{ formatCurrency(appointmentType.price) }}</span>
                              <span v-if="appointmentType.has_member_discount">
                                <br>
                                {{ formatCurrency(appointmentType.price_with_member_discount) }}
                              </span>
                            </span>
                          </span>
                        </div>

                      </label>
                      <br/><br/>
                      <label
                          class="block text-sm font-medium text-gray-700 w-full mb-2"
                          v-for="(class_pass_type, idx) in appointmentType.valid_class_pass_types"
                          :key="'class_pass_type' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="`class_pass_type_${class_pass_type.id}`"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                          >
                          <span class="w-full flex space-between border-b pb-1 flex-wrap">
                            <span class="font-bold">
                              {{ class_pass_type.name }}
                            </span>
                            <span> {{ formatCurrency(class_pass_type.price) }} </span>
                          </span>
                        </div>
                      </label>
                      <label
                          class="block text-sm font-medium text-gray-700 w-full"
                          v-for="(membership_type, idx) in appointmentType.valid_membership_types"
                          :key="'membership_type' + idx"
                      >
                        <div class="flex items-center">
                          <input
                              type="radio"
                              v-model="selectedAppointmentPaymentOption"
                              :value="`membership_type_${membership_type.id}`"
                              class="focus:ring-black h-4 w-4 text-black border-gray-600 mr-2 font-bold"
                          >
                          <span class="font-bold">
                            {{ membership_type.name }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </li>
            </ul>


            <div v-if="appointmentShowBookButton">
              <button
                  @click="appointmentBook"
                  class="btn btn-primary my-8"
                  :class="{ 'btn-primary-app': mobileAppMode }"
                  :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              >
                {{ $t('global.BookAppointment') }}
              </button>
            </div>
            <div class="w-full" v-if="appointmentShowPayButton">
              <button
                  @click="appointmentPay"
                  class="btn btn-primary my-8"
                  :class="{ 'btn-primary-app w-full': mobileAppMode }"
                  :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              >
                {{ $t('global.ContinueToPayment') }}
              </button>
            </div>
          </div>

        </div>

      </div>


      <md-dialog-alert
          :md-active.sync="showAlertDialog"
          :md-title="alertDialogText"
          @md-closed="alertDialogClosed()"
      />

    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { validationMixin } from 'vuelidate';
import YogoApi from '../gateways/YogoApi';
import { mapGetters } from 'vuex';
import Loadingspinner from './LoadingSpinner';

import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import _padEnd from 'lodash/padEnd';

import Imgix from '@/services/Imgix';
import ReepayPaymentMixin from './ReepayPaymentMixin';

import StripePaymentMixin from '@/components/StripePaymentMixin';
import currencyFormatters from '@/mixins/currencyFormatters';
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import ProfileImage from '@/components/ProfileImage';

export default {
  mixins: [
    validationMixin,
    Imgix,
    ReepayPaymentMixin,
    StripePaymentMixin,
    currencyFormatters,
    dateTimeFunctions,
  ],

  props: ['appointmentCartItem'],

  data() {
    return {
      loading: true,
      cartItemPrice: 0,

      localCartItem: this.appointmentCartItem,
      cartItemType: 'appointment',

      userMemberships: [],
      userClassPasses: [],

      showAlertDialog: false,
      alertDialogText: '',
      onCloseAlertDialog: null,

      appointmentType: null,
      teacher: null,
      appointmentShowPaymentOptions: false,
      selectedAppointmentPaymentOption: '',

      appointmentShowMorePaymentOptions: false,
    };
  },
  components: {
    ProfileImage,
    Loadingspinner,
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'stateReady',
      'mobileAppMode'
    ]),
    appointmentCustomerHasExistingAccess() {
      return this.userClassPasses.length || this.userMemberships.length;
    },
    appointmentShowTotal() {
      return this.appointmentShowPayButton;
    },
    appointmentShowBookButton() {
      return this.selectedAppointmentPaymentOption.match(/^class_pass_\d+$/)
          || this.selectedAppointmentPaymentOption.match(/^membership_\d+$/);
    },
    appointmentShowPayButton() {
      return this.selectedAppointmentPaymentOption.match(/^class_pass_type_\d+$/)
          || this.selectedAppointmentPaymentOption.match(/^membership_type_\d+$/)
          || this.selectedAppointmentPaymentOption === 'directPurchase';
    },
  },

  async created() {

    // console.log('@smart: created', this.appointmentCartItem)
    // @smart: created  {"itemType":"appointment","appointment_type_id":"2","date":"2022-03-31","start_time":"10:30:00"}

    // this.localCartItem = {
    //   itemType: "appointment",
    //   appointment_type_id: 2,
    //   date: "2022-03-31",
    //   start_time: "10:30:00",
    // };

    this.localCartItem = this.appointmentCartItem;

    this.localCartItem.start_time = _padEnd(this.localCartItem.start_time, 8, ':00');


    const appointmentIsAvailable = await this.checkAppointmentAvailability();
    if (!appointmentIsAvailable) return;

    const query = qs.stringify({
      populate: [
        'appointment_available_intervals',
        'valid_class_pass_types',
        'valid_membership_types',
        'has_member_discount',
        'price_with_member_discount'
      ],
    });

    this.appointmentType = await YogoApi.get(`/appointment-types/${this.localCartItem.appointment_type_id}?${query}`);
    if (this.localCartItem.teacher_id) {
      this.teacher = await YogoApi.get(`/users?id=${this.localCartItem.teacher_id}&teacher=1`);
      if (_isArray(this.teacher)) {
        this.teacher = this.teacher[0];
      }
    }

    await this.loadUserAccessItemsForAppointment();

    this.loading = false;

  },

  methods: {
    async loadUserAccessItemsForAppointment() {

      const queryString = qs.stringify({
        user: this.user.id,
        appointment_type: this.appointmentType.id,
        date: this.localCartItem.date,
      });
      ({
        memberships: this.userMemberhips,
        class_passes: this.userClassPasses,
      } = await YogoApi.get(`/appointments/access-items-valid-for-appointment?${queryString}`));

      this.appointmentShowPaymentOptions = true;
    },

    alertDialogClosed() {
      if (this.onCloseAlertDialog) {
        this.onCloseAlertDialog();
      }
    },

    async checkAppointmentAvailability() {
      const queryString = qs.stringify({
        date: this.localCartItem.date,
        appointment_type_id: this.localCartItem.appointment_type_id,
        teacher_id: this.localCartItem.teacher_id,
        start_time: this.localCartItem.start_time,
        branch_id: this.localCartItem.branch_id,
      });
      const result = await YogoApi.get(`/appointment-available-timeslots/check?${queryString}`);
      if (!result) {
        alert(this.$t('global.TheAppointmentTimeslotIsNoLongerAvailable'));
        this.$emit('closePopup');
        return false;
      }
      return result;
    },
    async appointmentPay() {
      if (!this.checkAppointmentAvailability()) return;

      let cartItem;
      if (this.selectedAppointmentPaymentOption === 'directPurchase') {
        // Direct payment for the appointment
        cartItem = {
          item_type: 'appointment',
          item_id: this.localCartItem.appointment_type_id,
          appointment_details: {
            date: this.localCartItem.date,
            appointment_type_id: this.localCartItem.appointment_type_id,
            teacher_id: this.localCartItem.teacher_id,
            start_time: this.localCartItem.start_time,
            branch_id: this.localCartItem.branch_id,
          },
          user: this.user.id,
          destroyOthers: true,
        };
      } else {
        // Buying a class pass or a membership for the appointment
        cartItem = {
          item_type: this.selectedAppointmentPaymentOption.substr(0, 15),
          item_id: this.selectedAppointmentPaymentOption.substr(16),
          use_immediately_after_purchase: {
            item_type: 'appointment',
            date: this.localCartItem.date,
            appointment_type_id: this.localCartItem.appointment_type_id,
            teacher_id: this.localCartItem.teacher_id,
            start_time: this.localCartItem.start_time,
            branch_id: this.localCartItem.branch_id,
          },
          user: this.user.id,
          destroyOthers: true,
        };
      }
      await YogoApi.post('/cart-items', cartItem);

      await this.$store.dispatch('updateUser');

      this.$router.push({ name: 'Checkout' });
    },

    async appointmentBook() {
      // Check that appointment is still available
      if (!this.checkAppointmentAvailability()) return;

      // Make appointment
      const postData = {
        date: this.localCartItem.date,
        appointment_type_id: this.localCartItem.appointment_type_id,
        customer_id: this.user.id,
        teacher_id: this.localCartItem.teacher_id || undefined,
        start_time: this.localCartItem.start_time,
        branch_id: this.localCartItem.branch_id || undefined,
        paymentOption: this.selectedAppointmentPaymentOption,
      };
      const newAppointment = await YogoApi.post(`/appointments`, postData);
      if (newAppointment.error) {
        // TODO Handle other errors with extended error handling.
        if (newAppointment.error.type === 'appointmentOverlaps') {
          if (newAppointment.error.overlappingObjects.customer.length) {
            alert(this.$t('global.YouAlreadyHaveAnAppointmentOrClassAtThisTime'));
          } else {
            alert(this.$t('global.TheAppointmentTimeslotIsNoLongerAvailable'));
          }
          this.$emit('closePopup');
        }
      }

      this.$emit('updateProfile');
      // Show alert that the appointment has been created
      let teacher = await YogoApi.get(`/users?id=${newAppointment.teacher_id}&teacher=1`);
      if (_isArray(teacher)) teacher = teacher[0];
      this.alertDialogText = this.$t(
          'global.YouHaveBookedAppointmentTypeWithTeacherOnDateAndTime',
          {
            appointmentType: this.appointmentType.name,
            teacher: `${teacher.first_name} ${teacher.last_name}`,
            date: this.formatDate(newAppointment.date),
            time: newAppointment.start_time.substr(0, 5),
          });
      this.showAlertDialog = true;
      this.onCloseAlertDialog = () => {
        this.$emit('closePopup');
      }
    },
  },
  watch: {
    selectedAppointmentPaymentOption(paymentOption) {
      if (paymentOption.match(/^class_pass_type_\d+$/)) {
        const classPassTypeId = parseInt(paymentOption.substr(16));
        const classPassType = _find(
            this.appointmentType.valid_class_pass_types,
            cpt => cpt.id === classPassTypeId,
        );
        this.cartItemPrice = classPassType.price;
      }
      if (paymentOption.match(/^membership_type_\d+$/)) {
        const membershipTypeId = parseInt(paymentOption.substr(16));
        const membershipType = _find(
            this.appointmentType.valid_membership_types,
            mt => mt.id === membershipTypeId,
        );
        this.cartItemPrice = membershipType.id;
      }
      if (paymentOption === 'directPurchase') {
        this.cartItemPrice = this.appointmentType.price;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.leading-tight {
  line-height: 1.2;
}

img.logo-svg {
  width: 120px;
  height: 80px;
  padding: 0;
  margin: 0 auto !important;
  display: block;
  @media (min-width: 1024px) {
    margin: 0 !important;
  }
}

img.logo-bitmap {
  width: 160px;
  height: auto;
  padding: 0;
  margin: 0 auto !important;
  display: block;
  @media (min-width: 1024px) {
    margin: 0 !important;
  }
}

.product-item {
  padding-bottom: 5px;
}

.total {
  padding-top: 5px;
}

</style>

