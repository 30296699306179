<template>
  <div>
    <md-dialog :md-active="showEventDialog" v-on:update:mdActive="updateShowEventDialog">
      <a class="close-signup-modal" @click="$emit('update:showEventDialog',false)">
        <span class="icon-close"></span>
      </a>
      <div class="modal-content">

        <div class="md:flex pt-2">
          <div class="flex-1">
            <h1 class="mr-2">{{ dialogEventTimeSlot.event.name }}</h1>
            <p class="ttu">
              {{ formatDate(dialogEventTimeSlot.date, { weekday: true }) }} {{
                dialogEventTimeSlot.start_time + '-' +
                dialogEventTimeSlot.end_time
              }}
            </p>

            <p class="bold ttu">
              <span v-if="dialogEventTimeSlot.event.price === 0">
                {{ $t('global.free') | capitalize }}
              </span>
              <span v-else>
                {{ $t('global.price') }}: {{ formatCurrency(dialogEventTimeSlot.event.price) }}
              </span>
            </p>
            <p class="bold mb-0 leading-loose" v-if="dialogEventTimeSlot.branchName">
              {{ dialogEventTimeSlot.branchName }}
            </p>
            <div v-if="client.settings.calendar_show_room && getRoom()">
              {{ getRoom().name }}
            </div>

            <DialogTeacherInfo :teachers="getTeachers()"></DialogTeacherInfo>

          </div>

          <div class="flex flex-1 items-start">
            <img
                class="modal__image--standard"
                :src="Imgix(dialogEventTimeSlot.event.image.filename, {w:900,h:600,fit:'crop'})"
                v-if="dialogEventTimeSlot.event.image"
                alt=""
            />
          </div>

        </div>
        <div class="line my-4"></div>
        <nl2br tag="p" :text="dialogEventTimeSlot.event.description"></nl2br>
      </div>
      <md-dialog-actions>
        <button class="btn btn-primary" @click="signUpForEvent(dialogEventTimeSlot.event)"
                v-if="dialogEventTimeSlot.user_can_sign_up_for_event">{{ $t('global.signup') }}
        </button>
        <button class="btn btn-primary" disabled="disabled"
                v-if="dialogEventTimeSlot.user_is_signed_up_for_event">
          {{ $t('global.youAreSignedUp') }}
        </button>
        <button class="btn btn-primary"
                @click.stop.prevent="signOffFromEvent(dialogEventTimeSlot.user_signup_id);$emit('update:showEventDialog',false)"
                v-if="dialogEventTimeSlot.user_can_sign_off_from_event"
        >{{ $t('global.cancelBooking') }}
        </button>
      </md-dialog-actions>
    </md-dialog>

    <EventSignup
        :user="user"
        ref="eventSignupComponent"
        @eventSignupSuccess="eventSignupSuccess"
        @eventSignoffSuccess="eventSignoffSuccess"
    ></EventSignup>

  </div>

</template>


<script>

import EventSignup from '@/components/EventSignup';
import DialogTeacherInfo from "./DialogTeacherInfo";
import Imgix from '@/services/Imgix';

import { mapGetters } from 'vuex';

import avatar from '@/graphics/avatar';
import currencyFormatters from '@/mixins/currencyFormatters';
import dateTimeFunctions from '@/mixins/dateTimeFunctions.vue';
import _get from 'lodash/get';

export default {
  mixins: [Imgix, currencyFormatters, dateTimeFunctions],
  props: [
    'showEventDialog',
    'dialogEventTimeSlot',
  ],
  computed: {
    ...mapGetters([
      'client',
      'user',
    ]),
  },
  components: {
    DialogTeacherInfo,
    EventSignup,
  },

  methods: {

    updateShowEventDialog(showEventDialog) {
      this.$emit('update:showEventDialog', showEventDialog);
    },

    signUpForEvent(event) {
      this.$refs.eventSignupComponent.$emit('signUpForEvent', event);
    },
    signOffFromEvent(eventSignupId) {
      this.$refs.eventSignupComponent.$emit('signOffFromEvent', eventSignupId);
    },
    eventSignupSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showEventDialog', false);
    },
    eventSignoffSuccess() {
      this.$emit('signupsChanged');
      this.$emit('update:showEventDialog', false);
    },
    avatar() {
      return avatar;
    },
    getRoom() {
      return this.dialogEventTimeSlot.event.time_slots_have_separate_rooms_and_teachers
          ? this.dialogEventTimeSlot.room
          : _get(this.dialogEventTimeSlot, 'event.room');
    },
    getTeachers() {
      return this.dialogEventTimeSlot.event.time_slots_have_separate_rooms_and_teachers
          ? this.dialogEventTimeSlot.teachers
          : _get(this.dialogEventTimeSlot, 'event.teachers');
    },
  },
};


</script>

<style lang="scss" scoped>

.teacher {
  margin-right: 20px;
}

.no-border {
  box-shadow: none;
}

.slide-fade-enter-active {
  transition: all .3s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-active {
  transition: all .2s ease;
  z-index: 2;
  position: relative;
}

.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateY(-30px);
  z-index: 2;
  position: relative;
}

</style>
