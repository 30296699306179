<template>
  <div>
    <loading-spinner/>
  </div>
</template>

<script>

import LoadingSpinner from '@/components/LoadingSpinner';
import YogoApi from '@/gateways/YogoApi';

export default {
  components: { LoadingSpinner },
  async created() {
    const orderId = this.$route.params.orderId;
    await YogoApi.post('/payments/stripe/process-order-if-completed', { orderId });
    this.$store.commit('setGiftCardFlashMessage',
        { type: 'paymentSuccess', order: orderId });
    this.$router.replace({ name: 'BuyGiftCard' });
  },
};

</script>
