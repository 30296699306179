<template>
  <yogo-dialog :show.sync="showAlert" narrow>
    <template #title>
      {{ $t('dialog.terms.TermsAndConditions') }}
    </template>
    <template #content>
      {{ $t('dialog.terms.YouMustAcceptOurTermsAndConditions') }}
    </template>
    <template #actions>
      <button
        class="btn btn-primary"
        @click="showAlert = false">
        {{ $t('dialog.Ok') }}
      </button>
    </template>
  </yogo-dialog>
</template>
<script>

// Import the modal2 component, name it YogoDialog and use it in the template
import YogoDialog from '@/components/YogoDialog.vue';

export default {
  name: 'ForgotToAcceptTermsAlert',
  components: {
    YogoDialog,
  },
  data() {
    return {
      showAlert: false,
    };
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show() {
      this.showAlert = this.show;
    },
    showAlert: {
      handler: function (val) {
        this.$emit('update:show', val);
      },
      immediate: true,
    },
  },
};
</script>
