<template>
  <div></div>
</template>

<script>

import YogoApi from '../gateways/YogoApi';
import GoogleTagManagerMixin from './GoogleTagManagerMixin';
import _pick from 'lodash/pick';
import MobileAppInterface from '@/mixins/MobileAppInterface.vue';
import sleep from '@/mixins/sleep.vue';
import semver from 'semver';
import { mapGetters } from 'vuex';

export default {

  mixins: [GoogleTagManagerMixin, MobileAppInterface, sleep],

  data() {
    return {
      // These are used by consumers of this mixin to get notified when the payment completes or fails.
      reepayPaymentCompleted: false,
      reepayInvalidCartItems: false,
    };
  },

  computed: {
    ...mapGetters(['user'])
  },

  methods: {
    async reepayStartPayment(orderId) {
      this.loading = true;

      await this.GTMEvent('paymentStarted');

      let result;
      if (orderId) {
        result = await YogoApi.post('/payments/reepay/create-session-for-order', { order: orderId });
      } else {
        result = await YogoApi.post('/payments/reepay/create-order-and-matching-session-type');

        if (result === 'E_INVALID_CART_ITEM') {
          alert(this.$t('checkout.oneOrMoreCartItemsAreNoLongerAvailable'));
          this.reepayInvalidCartItems = true;
          if (this.user) {
            await this.$store.dispatch('updateUser');
          }
          this.loading = false;
          return;
        }

        if (!result.status) {
          throw new Error(
              'Invalid result from /payments/reepay/create-order-and-matching-session-type');
        }
      }

      switch (result.status) {
        case 'ORDER_SETTLED':
          if (this.user) {
            await this.$store.dispatch('updateUser');
          }
          await this.GTMTransaction(result.order);
          this.reepayPaymentCompleted = true;
          this.$store.commit('setMyProfileFlashMessage',
              { type: 'paymentSuccess', order: result.order.id });
          if (this.user) {
            this.navigateToMyProfile();
          }
          return;
        case 'CHARGE_SESSION_CREATED':
          this.reepayOpenChargeSession(result.chargeSession);
          return;
        case 'RECURRING_SESSION_CREATED':
          this.reepayOpenRecurringSession(result.recurringSession, result.order);
          return;
      }
    },

    reepayOpenChargeSession(chargeSession) {
      let paymentCompleted = false;
      let paymentOverlayClosed = false;
      this.loading = false;

      this.reepayWindow = new window.Reepay.ModalCheckout(chargeSession.id,
          { forceVippsDesktopFlow: true });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Accept, async data => {
        console.log('reepay window accept data:', data);
        const orderId = data.invoice.split('_')[0];
        this.loading = true;
        await YogoApi.post('/payments/reepay/invoice/' + data.invoice + '/process-if-paid');
        await this.$store.dispatch('updateUser');
        this.reepayPaymentCompleted = true;
        await this.GTMTransaction(orderId);
        paymentCompleted = true;
        if (paymentOverlayClosed && this.user) {
          this.navigateToMyProfile();
        }
      });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Error, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Cancel, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Close, async () => {
        paymentOverlayClosed = true;
        await this.$store.dispatch('updateUser');
        if (paymentCompleted && this.user) {
          this.navigateToMyProfile();
        }
      });
    },

    reepayOpenRecurringSession(recurringSession, order) {
      console.log('recurringSession:', recurringSession, 'order:', order);
      let paymentCompleted = false;
      let paymentOverlayClosed = false;
      this.loading = false;

      this.reepayWindow = new window.Reepay.ModalCheckout(recurringSession.id,
          { forceVippsDesktopFlow: true });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Accept, async data => {
        console.log('reepay window accept data:', data);
        this.loading = true;
        await YogoApi.post(
            '/payments/reepay/settle-and-attach-card-to-zero-total-recurring-order',
            {
              order: order.id,
              paymentMethod: data.payment_method,
            },
        );
        this.reepayPaymentCompleted = true;
        await this.$store.dispatch('updateUser');
        await this.GTMTransaction(order.id);
        paymentCompleted = true;
        this.reepayPaymentCompleted = true;
        if (paymentOverlayClosed && this.user) {
          this.navigateToMyProfile();
        }
      });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Error, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Cancel, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Close, async () => {
        paymentOverlayClosed = true;
        await this.$store.dispatch('updateUser');
        if (paymentCompleted) {
          this.navigateToMyProfile();
        }
      });
    },

    async reepayStartGiftCardPayment(form) {
      this.loading = true;

      await this.GTMEvent('paymentStarted');

      const result = await YogoApi.post(
          '/payments/reepay/create-order-and-charge-session-for-gift-card',
          _pick(
              form,
              [
                'amount',
                'giver_name',
                'giver_email',
                'recipient_name',
                'recipient_email',
                'message',
              ],
          ),
          {
            headers: {
              'X-Yogo-Client-Accepts-Extended-Error-Format': 1,
            },
          },
      );

      if (result.error) {
        alert(result.error.localized_message + '\n\n' + result.error.localized_message);
        this.loading = false;
        return;
      }

      this.reepayOpenGiftCardChargeSession(result.chargeSession);

    },

    async reepayOpenGiftCardChargeSession(chargeSession) {
      this.loading = false;

      this.reepayWindow = new window.Reepay.ModalCheckout(chargeSession.id,
          { forceVippsDesktopFlow: true });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Accept, async data => {
        console.log('reepay window accept data:', data);
        const orderId = data.invoice.split('_')[0];
        this.loading = true;
        await YogoApi.post('/payments/reepay/invoice/' + data.invoice + '/process-if-paid');
        await this.GTMTransaction(orderId);
        this.giftCardPaid(this.form);
      });

      this.reepayWindow.addEventHandler(window.Reepay.Event.Error, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Cancel, () => {
      });
      this.reepayWindow.addEventHandler(window.Reepay.Event.Close, () => {
      });
    },

    async navigateToMyProfile() {
      console.log('this.mobileAppVersion:', this.mobileAppVersion);

      if (this.mobileAppMode && semver.satisfies(this.mobileAppVersion, '>=2.11.2')) {
        await this.callMobileApp('navigateTo', 'MyProfile');
        return;
      }

      this.$router.push({ name: 'MyProfile' });
    },
  },
};

</script>
