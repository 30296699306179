<template>
  <div></div>
</template>

<script>

import YogoApi from '../gateways/YogoApi';
import _map from 'lodash/map';
import { mapGetters } from 'vuex';

const yogoEvents = [
  'paymentStarted',
  'paymentCompleted',
];

export default {

  computed: {
    ...mapGetters(['client']),
  },

  methods: {
    GTMInitTag() {
      if (!this.client.settings.google_tag_manager_id) return;

      console.log('[YOGO] Google Tag Manager ID:',
          this.client.settings.google_tag_manager_id);

      window.dataLayer = [
        { 'gtm.start': new Date().getTime(), event: 'gtm.js' },
      ];

      const script = document.createElement('script');
      script.async = true;
      const url = this.client.settings.google_tag_manager_custom_url || 'https://www.googletagmanager.com/gtm.js';
      script.src = `${url}?id=${this.client.settings.google_tag_manager_id}`;
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);
    },

    GTMPushToDataLayer(obj) {
      if (window.dataLayer) {
        window.dataLayer.push(obj);
      }
    },

    GTMEvent(event) {
      if (yogoEvents.indexOf(event) === -1) {
        throw new Error('Invalid GTM event: ' + event);
      }
      this.GTMPushToDataLayer({ 'event': event });
    },

    async GTMTransaction(order) {
      if (!window.dataLayer) return;
      if (!order.order_items) {
        const orderId = order.id || order;
        order = await YogoApi.get('/orders/' + orderId + '?populate[]=order_items');
      }
      const dataLayerObj = {
        transactionId: order.id,
        transactionAffiliation: `YOGO - ${this.client.name}`,
        transactionTotal: order.total,
        transactionTax: order.vat_amount,
        transactionShipping: 0,
        transactionProducts: _map(order.order_items, orderItem => ({
          sku: orderItem.item_type.replace('_', '').toUpperCase() + orderItem.item_id,
          name: orderItem.name,
          category: orderItem.item_type,
          price: orderItem.item_price,
          quantity: orderItem.count,
        })),
      };
      this.GTMPushToDataLayer(dataLayerObj);
      this.GTMEvent('paymentCompleted');
    },
  },

};

</script>
