<template>
  <span>

    <i18n path="checkout.IHaveReadAndAcceptTheConsentForm" class="select-none">
      <template v-slot:link>
        <a class="underline" href="#" @click.stop.prevent="showConsentForm = true">
          {{ $t('checkout.IHaveReadAndAcceptTheConsentFormLinkText') }}</a>
      </template>
    </i18n>*

    <yogo-dialog :show.sync="showConsentForm">
      <template #content>
        <div  class="terms-and-conditions">
          <h1>{{ $t('signup.ConsentForm') }}</h1>
          <div v-html="client.settings.consent_form"></div>
        </div>
      </template>
      <template #actions>
        <button class="btn btn-primary" @click="showConsentForm = false">{{
            $t('dialog.Close')
          }}
        </button>
      </template>
    </yogo-dialog>

  </span>
</template>
<script>

import { mapGetters } from 'vuex';
import YogoDialog from '@/components/YogoDialog.vue';

export default {
  name: 'AcceptConsentFormText',
  components: {
    YogoDialog,
  },
  data() {
    return {
      showConsentForm: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
    ]),
  },
};
</script>
