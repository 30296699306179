<template>
  <div class="min-h-screen lg:flex lg:justify-center bg-white lg:bg-transparent pb-8">
    <div class="login narrow-wrapper w-full max-w-xl m-auto"
         :class="{ 'wrapper' : !mobileAppMode }">
      <div class="bg-white md:pl-6 md:pr-6 md:pb-6 pt-4 md:mt-4 lg:mt-8 lg:pb-16 rounded">
        <div class="my-6">
          <ModalLogo></ModalLogo>
        </div>
        <h2 class="flex-wrap mb-8 text-center">
          <div>
            {{ client.name }}
          </div>
        </h2>

        <div class="mb-4">
          <h3 class="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 mb-4">
            {{ $t('global.terms') }}
          </h3>
          <div v-html="client.settings.terms" class="terms-and-conditions"></div>
        </div>

        <div v-if="client.settings.show_recurring_terms" class="mt-12">
          <h3 class="mb-2 mr-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 mb-4">
            {{ $t('checkout.MembershipTerms') }}
          </h3>
          <div v-html="client.settings.recurring_terms" class="terms-and-conditions"></div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModalLogo from './ModalLogo';

export default {
  name: 'Terms',
  components: {
    ModalLogo,
  },
  computed: {
    ...mapGetters([
      'client',
      'clientLogoFilename',
      'mobileAppMode',
    ]),
  },
};
</script>
