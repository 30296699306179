<template>
  <div class="events" :class="{ 'wrapper' : !mobileAppMode }">

    <h1 class="hidden-sm">{{ $t('global.eventsLabel') }}</h1>

    <loadingspinner v-if="loading"></loadingspinner>

    <div
      v-else
    >
      <div class="theme--frame-box mt-10" v-if="!eventGroups.length">
        <h3>
          {{ $t('global.NoEventsScheduled') }}
        </h3>
      </div>

      <div
        v-for="eventGroup in eventGroups"
        v-else
        :key="'eventGroup_' + eventGroup.id"
        :class="{'mb-6' : mobileAppMode}"
      >
        <div class="space8 hidden-sm"></div>

        <eventsection
          :eventGroup="eventGroup"
          :signedUpForEventIds="signedUpForEventIds"
          :eventBuyCountMap="eventBuyCountMap"
          @updateEventBuyCountMap="updateEventBuyCountMap"
          v-on:openEventDialog="openEventDialog"
          v-on:buyEvent="buyEvent"
        ></eventsection>

      </div>

    </div>

    <div :class="mobileAppMode ? 'space4' : 'space8'"></div>

    <md-dialog :md-active.sync="showEventDialog" class="terms-dialog">
      <a class="close-signup-modal" @click="showEventDialog = false"><span
          class="icon-close"></span></a>
      <div class="modal-content">
        <div class="md:flex pt-2">
          <div class="flex-1">
            <h1>{{ dialogEvent.name }}</h1>

            <p class="bold" v-if="dialogEvent.room && !dialogEvent.time_slots_have_separate_rooms_and_teachers">
              {{ dialogEvent.room.name }}
            </p>
            <h3 v-else>{{ dialogEvent.time_freetext }}</h3>
            <DialogTeacherInfo :teachers="getEventTeachers(dialogEvent)"></DialogTeacherInfo>
            <div class="mb-2" v-if="dialogEvent.use_time_slots">
              <div v-for="timeSlot in dialogEvent.time_slots" :key="'timeSlot_' + timeSlot.id" class="mb-2">
                <span class="font-bold">
                  {{ formatDate(`${timeSlot.date} ${timeSlot.start_time}`, {weekday: true, time:true, capitalize:true}) }}-{{ formatTime(timeSlot.end_time) }}
                </span>
                <span v-if="dialogEvent.time_slots_have_separate_rooms_and_teachers" class="flex items-center">
                  <span v-if="timeSlot.room" class="flex items-center mr-2">
                    <LocationIcon class="mr-1 pr-1"></LocationIcon>
                    {{ timeSlot.room.name }}
                  </span>
                  <span v-if="timeSlot.teachers.length">
                    - {{ timeSlot.teachers.map(teacher => `${teacher.first_name} ${teacher.last_name}`.trim()).join(', ') }}
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-1 items-start">
            <img
              class="modal__image--standard"
              :class="{'rounded-lg' : mobileAppMode}"
              :src="Imgix(dialogEvent.image.filename, {w:900, h:600, fit: 'crop'})" alt=""
              v-if="dialogEvent.image"
            />
          </div>
        </div>
        <div class="yogo-line my-4"></div>

        <nl2br tag="p" :text="dialogEvent.description ? dialogEvent.description : ''"/>
        <div class="yogo-line my-4"></div>

      </div>
      <md-dialog-actions class="modal-footer">
        <div v-if="!mobileAppMode">
          <md-button disabled v-if="userSignedUpForEvent(dialogEvent)">
            {{ $t('global.youAreSignedUp') }}
          </md-button>
          <md-button disabled v-else-if="dialogEvent.signup_count >= dialogEvent.seats">
            {{ $t('global.fullyBooked') }}
          </md-button>
        </div>
        <div v-if="mobileAppMode">
          <button
            disabled
            v-if="userSignedUpForEvent(dialogEvent)"
            class="btn btn-primary"
            :class="{ 'btn-secondary-app': mobileAppMode }"
          >
            {{ $t('global.youAreSignedUp') }}
          </button>
          <button
            disabled
            v-else-if="dialogEvent.signup_count >= dialogEvent.seats"
            class="btn btn-primary"
            :class="{ 'btn-secondary-app': mobileAppMode }"
          >
            {{ $t('global.fullyBooked') }}
          </button>
        </div>
        <div class="flex space-between flex-wrap" v-else>
          <YogoNumberInput
            v-if="client.settings.customer_can_buy_multiple_seats_for_event && dialogEvent.price"
            v-model="eventBuyCountMap[dialogEvent.id]"
            :min="1"
            :max="dialogEvent.seats - dialogEvent.signup_count"
          ></YogoNumberInput>
          <button
            class="btn btn-primary"
            @click="buyEvent(dialogEvent)"
          >
            {{ $t('global.signup') }}
          </button>
          <button
            v-if="dialogEvent.installment_payments_enabled && dialogEvent.number_of_installments_left >= 1"
            class="btn btn-primary"
            @click="buyEvent(dialogEvent, payInInstallments = true)"
          >
            {{ $t('event.SignUpPayInInstallments') }}
          </button>
        </div>
      </md-dialog-actions>

    </md-dialog>

    <md-snackbar md-position="center" :md-duration="4000"
                 :md-active.sync="showSignedUpForFreeEventSnackbar" md-persistent
    >
      {{ $t('calendar.youAreNowSignedUpFor') }}
      {{ signedUpForFreeEventSnackbarEvent ? signedUpForFreeEventSnackbarEvent.name : '' }}
    </md-snackbar>


  </div>
</template>

<script>
import EventSection from './EventSection';
import YogoApi from '../gateways/YogoApi';
import LoadingSpinner from './LoadingSpinner.vue';
import YogoNumberInput from '@/components/ui/YogoNumberInput';
import moment from 'moment';
import { mapGetters } from 'vuex';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flatten from 'lodash/flatten';
import _map from 'lodash/map';
import _uniqBy from 'lodash/uniqBy';
import Imgix from "../services/Imgix";
import DialogTeacherInfo from '@/components/schedule/DialogTeacherInfo';
import dateTimeFunctions from '@/mixins/dateTimeFunctions.vue';
import LocationIcon from '../graphics/icons/LocationIcon.vue';

export default {
  components: {
    eventsection: EventSection,
    loadingspinner: LoadingSpinner,
    YogoNumberInput,
    DialogTeacherInfo,
    LocationIcon
  },
  mixins: [Imgix, dateTimeFunctions],
  data() {
    return {
      loading: false,
      showTeacherInfo: [{ show: false }, { show: false }, { show: false }, { show: false }],
      eventsLabel: 'kurser',
      eventGroups: [],
      dialogEvent: {},
      showEventDialog: false,
      signedUpForEventIds: [],

      eventBuyCountMap: {},

      showSignedUpForFreeEventSnackbar: false,
      signedUpForFreeEventSnackbarEvent: null,
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'profileImageFilename',
      'user',
      'client',
      'stateReady',
      'mobileAppMode',
    ]),
  },
  mounted: function () {
    if (this.stateReady) {
      this.fetchEvents();
    }
  },
  methods: {

    async fetchEvents() {

      this.loading = true;

      let [events, eventGroups, eventSignups] = await Promise.all([
        YogoApi.get('/events' +
            '?startDate=' + moment().startOf('day').format('YYYY-MM-DD') +
            '&populate[]=image' +
            '&populate[]=teachers' +
            '&populate[]=teachers.image' +
            '&populate[]=time_slots' +
            '&populate[]=time_slots.teachers' +
            '&populate[]=time_slots.room' +
            '&populate[]=room' +
            '&populate[]=signup_count' +
            '&populate[]=event_group' +
            '&populate[]=current_required_paid_amount' +
            '&populate[]=current_required_paid_amount_with_member_discount' +
            '&populate[]=current_required_number_of_paid_installments' +
            '&populate[]=number_of_installments_left' +
            '&populate[]=price_with_member_discount' +
            '&populate[]=installments.amount_with_member_discount',
        ),
        YogoApi.get('/event-groups' +
            '?populate[]=image' +
            '&populate[]=teachers',
        ),
        YogoApi.get('/event-signups?user=' + this.user.id),
      ]);

      for (let i = 0; i < events.length; i++) {
        let event = events[i];
        if (!event.event_group) continue;
        let eventGroup = _find(eventGroups, { id: event.event_group.id });
        if (!eventGroup) continue; // Event group might be archived.
        eventGroup.events = eventGroup.events || [];
        eventGroup.events.push(event);
      }
      eventGroups = _filter(eventGroups,
          eventGroup => !!eventGroup.events && !!eventGroup.events.length);

      this.eventGroups = eventGroups;
      this.signedUpForEventIds = eventSignups.map((signup) => {
        return parseInt(signup.event_id);
      });
      this.loading = false;
    },

    getEventTeachers(event) {
      if (event.time_slots_have_separate_rooms_and_teachers) {
        return _uniqBy(_flatten(_map(event.time_slots, 'teachers')), 'id');
      }
      return event.teachers;
    },

    updateEventBuyCountMap(eventId, count) {
      this.$set(this.eventBuyCountMap, eventId, count);
    },

    openEventDialog(event) {
      this.dialogEvent = event;
      this.showEventDialog = true;

      _each(this.dialogEvent.teachers, function (teacher) {
        if (teacher.image && !teacher.image.filename) {
          YogoApi.get('/images/' + teacher.image)
              .then((image) => {
                teacher.image = image;
              })
              .catch(() => {

              });
        }
      });
    },
    userSignedUpForEvent(event) {
      return this.signedUpForEventIds.indexOf(parseInt(event.id)) > -1;
    },

    async buyEvent(event, payInInstallments = false) {

      if (parseInt(event.price) === 0) {
        const response = await YogoApi.post('/event-signups', {
          user: this.user.id,
          event: event.id,
        });
        if (response === 'E_EVENT_IS_FULL') {
          alert(this.$t('global.fullyBooked'));
          this.fetchEvents();
          return;
        }
        if (response === 'E_USER_IS_ALREADY_SIGNED_UP_FOR_EVENT') {
          alert(this.$t('calendar.youAreAlreadySignedUp'));
          this.fetchEvents();
          this.$store.dispatch('updateUser');
          return;
        }
        this.showSignedUpForFreeEventSnackbar = true;
        this.signedUpForFreeEventSnackbarEvent = event;
        this.fetchEvents();
        this.$store.dispatch('updateUser');
        return;
      }

      const updatedEvent = await YogoApi.get('/events/' + event.id + '?populate[]=signup_count');
      if (updatedEvent.signup_count >= updatedEvent.seats) {
        await this.fetchEvents();
        alert(this.$t('global.fullyBooked'));
        return;
      }

      const count = this.eventBuyCountMap[event.id] || 1;
      Promise.all([...Array(count).keys()].map(async () => {
        // console.log("@smart---addToCart\n", count);
        await YogoApi.post('/cart-items', {
          item_type: payInInstallments ? 'event_first_installment' : 'event',
          item_id: event.id,
          user: this.user.id,
        });
      }));

      await this.$store.dispatch('updateUser');

      this.$router.push({ name: 'Checkout' });

      this.loading = false;

    },
  },

  watch: {
    stateReady(newState) {
      if (newState) {
        this.fetchEvents();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.no-border {
  box-shadow: none;
}

</style>

