<template>
  <div></div>
</template>

<script>

export default {
  data() {
    return {
      isMobile: false,
    };
  },
  created() {
    this.checkIfMobile();
  },
  methods: {
    checkIfMobile() {
      // check if ios or android
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      if (/android/i.test(userAgent)) {
        this.isMobile = true;
      }
      // iOS
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.isMobile = true;
      }
    },
  },
};

</script>
