<template>
  <div class="mb-4 birthday-select">

    <span
        @click="reset"
        v-if="!required"
        class="select-transparent order-first float-right text-right -mb-2 h-0 outline-none cursor-pointer flex justify-end z-10 items-center mt-2"
    >
        <span>
          {{ $t('login.Clear') }}
        </span>
      </span>

    <div class="yogo-input mb-2">
      <label class="p0">
        {{ $t('login.birthdayLabel') }}
        <span v-if="this.required">*</span>
      </label>
    </div>

    <div class="flex yogo-input">

      <select class="mr-2" v-model="year">
        <option
            disabled
            selected
            value=""
        >
          {{ $t('global.Year') }}
        </option>
        <option
            v-for="yearOption in yearOptions"
            :key="yearOption"
            :value="yearOption"
        >
          {{ yearOption }}
        </option>
      </select>

      <select class="mr-2" v-model="month">
        <option
            disabled
            selected
            value=""
        >
          {{ $t('global.Month') }}
        </option>
        <option
            value="1"
        >
          {{ $t('global.January') }}
        </option>
        <option
            value="2"
        >
          {{ $t('global.February') }}
        </option>
        <option
            value="3"
        >
          {{ $t('global.March') }}
        </option>
        <option
            value="4"
        >
          {{ $t('global.April') }}
        </option>
        <option
            value="5"
        >
          {{ $t('global.May') }}
        </option>
        <option
            value="6"
        >
          {{ $t('global.June') }}
        </option>
        <option
            value="7"
        >
          {{ $t('global.July') }}
        </option>
        <option
            value="8"
        >
          {{ $t('global.August') }}
        </option>
        <option
            value="9"
        >
          {{ $t('global.September') }}
        </option>
        <option
            value="10"
        >
          {{ $t('global.October') }}
        </option>
        <option
            value="11"
        >
          {{ $t('global.November') }}
        </option>
        <option
            value="12"
        >
          {{ $t('global.December') }}
        </option>
      </select>

      <select v-model="day">
        <option
            disabled
            selected
            value=""
        >
          {{ $t('global.Day') }}
        </option>
        <option
            v-for="dayInMonth in daysInMonth"
            :key="dayInMonth"
            :value="dayInMonth"
        >
          {{ dayInMonth }}
        </option>
      </select>


    </div>

    <slot name="errors"></slot>

  </div>
</template>

<script>

import _range from 'lodash/range';
import { DateTime } from 'luxon';

export default {
  name: 'BirthdaySelect',
  data() {
    return {
      year: '',
      month: '',
      day: '',
    };
  },
  computed: {
    yearOptions() {
      const thisYear = DateTime.now().setZone(this.client ? this.client.timezone : 'UTC').year;
      return _range(thisYear + 1, thisYear - 120, -1);
    },
    daysInMonth() {
      if (!this.year || !this.month) {
        return 31;
      }
      const monthObject = DateTime.fromObject({
        year: this.year,
        month: this.month,
        day: 1,
      });
      return monthObject.daysInMonth;
    },
  },
  props: {
    value: String,
    required: Boolean,
  },
  watch: {
    value(newValue) {
      if (!newValue.match(/^\d\d\d\d-\d\d-\d\d$/)) {
        this.year = '';
        this.month = '';
        this.day = '';
      }
    },
    year() {
      this.change();
    },
    month() {
      this.change();
    },
    day() {
      this.change();
    },
  },
  methods: {
    change() {
      // If all fields are empty, emit empty string
      if (!this.year && !this.month && !this.day) {
        this.$emit('input', '');
        return;
      }
      // If any field is empty, do nothing
      if (!this.year || !this.month || !this.day) {
        return;
      }
      let luxonObject = DateTime.fromObject({
        year: this.year,
        month: this.month,
        day: this.day,
      });
      // If the date is invalid, try to fix it by decrementing the day
      while (this.day > 28 && !luxonObject.isValid) {
        this.day -= 1;
        luxonObject = DateTime.fromObject({
          year: this.year,
          month: this.month,
          day: this.day,
        });
      }
      // If the date is now valid, emit it
      if (luxonObject.isValid) {
        this.$emit('input', luxonObject.toISODate());
      }
      // Date is still not valid. Do nothing.
    },
    reset() {
      this.year = '';
      this.month = '';
      this.day = '';
    },
  },
};
</script>


<style lang="scss" scoped>

.birthday-select {

  .error {
    display: none;
    left: 0;
    opacity: 0;
    -webkit-transform: translate3d(0, -8px, 0);
    transform: translate3d(0, -8px, 0);
  }

  &.invalid .error {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background: #fbeeca;
    font-weight: bold;
    padding: 14px;
    padding-left: 55px;
    margin-bottom: 10px;
    display: flex !important;
    align-items: center;

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-info'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='16' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='8' x2='12.01' y2='8'%3E%3C/line%3E%3C/svg%3E");
      padding-bottom: 6px;
      font-size: 16px;
      position: absolute;
      width: 25px;
      height: 25px;
      left: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
    }

  }

}

</style>
