<template>
  <div></div>
</template>

<script>

import { mapGetters } from 'vuex';
import qs from 'qs';

export default {
  computed: {
    ...mapGetters([
      'mobileAppMode',
    ]),
    mobileAppVersion() {
      const queryString = document.location.search.replace('?', '');
      const query = qs.parse(queryString);
      if (query.mobileAppVersion) {
        return query.mobileAppVersion;
      }
      return null;
    },
  },
  methods: {
    async callMobileApp(handlerName, ...args) {
      if (window.flutter_inappwebview) {
        return new Promise((resolve) => {
          window.flutter_inappwebview.callHandler(handlerName, ...args)
              .then(() => {
                resolve();
              })
              .catch((error) => {
                resolve(error);
              });
        });
      }
    },
  },
};
</script>
