<template>
  <div class="min-h-screen lg:flex lg:justify-center bg-white lg:bg-transparent pb-8">
    <div class="login narrow-wrapper w-full max-w-xl m-auto"
         :class="{ 'wrapper' : !mobileAppMode }">
      <div class="bg-white md:pl-6 md:pr-6 md:pb-6 pt-4 md:mt-4 lg:mt-8 lg:pb-16">
        <div class="my-6">
          <ModalLogo></ModalLogo>
        </div>
        <h2 class="flex-wrap mb-8 text-center">
          <div>
            {{ client.name }}
          </div>
        </h2>

        <div v-if="loading">
          <LoadingSpinner></LoadingSpinner>
        </div>
        <div v-else-if="state === 'orderNotFound'">
          <div class="text-center">
            {{ $t('paymentLink.OrderNotFound') }}
          </div>
        </div>
        <div v-else-if="state === 'invalidItems'">
          <div class="text-center">
            {{ $t('paymentLink.InvalidItems') }}
          </div>
        </div>
        <div v-else>
          <div v-if="order.paid" class="text-center my-4 bold">
            <div class="text-green-500 text-3xl">
              {{ $t('paymentLink.PAID') }}
            </div>
            <div>
              {{ this.$t('paymentLink.ThePaymentIsCompleted') }}
            </div>
          </div>
          <ul>
            <li v-for="orderItem in order.order_items" class="flex space-between"
                :key="orderItem.id">
              <div>{{ orderItem.name }}</div>
              <div>{{ formatCurrency(orderItem.total_price) }}</div>
            </li>
          </ul>
          <div class="flex space-between mt-4 bold text-2xl">
            <div>{{ $t('global.Total') }}</div>
            <div>{{ formatCurrency(order.total) }}</div>
          </div>
          <div v-if="!order.paid">

            <div class="flex--row space--between align--top mt-4">
              <div>
                <label
                    class="checkbox"
                >
                  <input
                      v-model="termsAccepted"
                      type="checkbox"
                  >
                  <accept-terms-text class="leading-tight"/>
                </label>

                <label
                    class="checkbox mt-2"
                    v-if="showRecurringTerms"
                >
                  <input
                      v-model="membershipTermsAccepted"
                      type="checkbox"
                  >
                  <accept-membership-terms-text class="leading-tight"/>
                </label>

                <label
                    class="checkbox mt-2"
                    v-if="client.settings.customer_must_accept_the_privacy_policy"
                >
                  <input
                      v-model="privacyPolicyAccepted"
                      type="checkbox"
                  >
                  <accept-privacy-policy-text class="leading-tight"/>
                </label>

                <label
                    class="checkbox mt-2"
                    v-if="client.settings.customer_must_accept_the_consent_form"
                >
                  <input
                      v-model="consentFormAccepted"
                      type="checkbox"
                  >
                  <accept-consent-form-text class="leading-tight"/>
                </label>

              </div>
            </div>

            <button class="btn-primary mt-8 w-full" @click="startPayment">
              {{ $t('global.Pay') }}
            </button>
          </div>
        </div>


      </div>

    </div>

    <forgot-to-accept-terms-alert :show.sync="showForgotToAcceptTermsDialog"/>
    <forgot-to-accept-membership-terms-alert :show.sync="showForgotToAcceptMembershipTermsDialog"/>
    <forgot-to-accept-privacy-policy-alert :show.sync="showForgotToAcceptPrivacyPolicyAlert"/>
    <forgot-to-accept-consent-form-alert :show.sync="showForgotToAcceptConsentFormAlert"/>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';

import ModalLogo from './ModalLogo';
import LoadingSpinner from "./LoadingSpinner";
import reepayPaymentMixin from '@/components/ReepayPaymentMixin.vue';
import stripePaymentMixin from '@/components/StripePaymentMixin.vue';
import YogoApi from '@/gateways/YogoApi';
import qs from 'qs';
import currencyFormatters from '@/mixins/currencyFormatters.vue';
import _find from 'lodash/find';
import AcceptTermsText from '@/components/fragments/AcceptTermsText.vue';
import AcceptMembershipTermsText from '@/components/fragments/AcceptMembershipTermsText.vue';
import AcceptPrivacyPolicyText from '@/components/fragments/AcceptPrivacyPolicyText.vue';
import AcceptConsentFormText from '@/components/fragments/AcceptConsentFormText.vue';
import ForgotToAcceptTermsAlert from '@/components/fragments/ForgotToAcceptTermsAlert.vue';
import ForgotToAcceptMembershipTermsAlert
  from '@/components/fragments/ForgotToAcceptMembershipTermsAlert.vue';
import ForgotToAcceptPrivacyPolicyAlert
  from '@/components/fragments/ForgotToAcceptPrivacyPolicyAlert.vue';
import ForgotToAcceptConsentFormAlert
  from '@/components/fragments/ForgotToAcceptConsentFormAlert.vue';

export default {
  name: 'PaymentLink',
  components: {
    ForgotToAcceptConsentFormAlert,
    ForgotToAcceptPrivacyPolicyAlert,
    ForgotToAcceptMembershipTermsAlert,
    ForgotToAcceptTermsAlert,
    AcceptConsentFormText,
    AcceptPrivacyPolicyText,
    AcceptMembershipTermsText,
    AcceptTermsText,
    ModalLogo,
    LoadingSpinner,
  },
  mixins: [validationMixin, reepayPaymentMixin, stripePaymentMixin, currencyFormatters],
  data() {
    return {
      loading: true,
      state: '',
      order: null,

      termsAccepted: false,
      showForgotToAcceptTermsDialog: false,

      membershipTermsAccepted: false,
      showForgotToAcceptMembershipTermsDialog: false,

      privacyPolicyAccepted: false,
      showForgotToAcceptPrivacyPolicyAlert: false,

      consentFormAccepted: false,
      showForgotToAcceptConsentFormAlert: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'clientLogoFilename',
      'requestedRoute',
      'mobileAppMode',
    ]),
    showRecurringTerms() {
      return !!_find(this.order.order_items,
          { item_type: 'membership_type' }) && this.client.settings.show_recurring_terms;
    },
  },
  async mounted() {
    await this.fetchOrder();
  },
  methods: {
    async fetchOrder() {
      const queryString = qs.stringify({
        paymentLinkToken: this.$route.params.paymentLinkToken,
        populate: [
          'order_items',
        ],
      });
      [this.order] = await YogoApi.get(`/orders?${queryString}`);
      if (!this.order) {
        alert(this.$t('paymentLink.OrderNotFound'));
        this.state = 'orderNotFound';
      }
      this.loading = false;
    },
    startPayment() {
      if (this.showRecurringTerms && !this.membershipTermsAccepted) {
        this.showForgotToAcceptMembershipTermsDialog = true;
        return;
      }

      if (!this.termsAccepted) {
        this.showForgotToAcceptTermsDialog = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_privacy_policy && !this.privacyPolicyAccepted) {
        this.showForgotToAcceptPrivacyPolicyAlert = true;
        return;
      }

      if (this.client.settings.customer_must_accept_the_consent_form && !this.consentFormAccepted) {
        this.showForgotToAcceptConsentFormAlert = true;
        return;
      }

      if (this.client.settings.payment_service_provider === 'stripe') {
        this.stripeStartPayment(this.order.id, document.location.href);
      } else if (this.client.settings.payment_service_provider === 'reepay') {
        this.reepayStartPayment(this.order.id);
      }
    },
  },
  watch: {
    // Listen for payment completion in the Reepay mixin
    async reepayPaymentCompleted() {
      console.log('payment completed');
      await this.fetchOrder();
      this.loading = false;
    },
    // Listen for invalid cart items in the Reepay mixin
    reepayInvalidCartItems() {
      console.log('invalid items');
      this.state = 'invalidItems';
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>


</style>
