<template>
  <div>

    <h3
      class="flex text-base font-semibold leading-6 text-gray-900 pointer"
      @click="show = !show"
    >
      {{ $t('global.Attendees') }}  ({{ attendees.length }})
      <div class="flex items-center pointer ml2">
        <span :class="{ 'rotate': show }" class="block icon-arrowdown"></span>
      </div>
    </h3>

    <!-- dropdown content -->
    <transition v-if="show" name="slide-fade" mode="out-in">
      <div class="flex flex-wrap my-2">
      <div
        v-for="(attendee) in attendees"
        v-bind:key="attendee.id"
        class="w-6/12 sm:w-4/12 lg:w-3/12 flex items-center mb-2"
      >
        <div class="w-4/12 md:w-3/12">
          <img
            :src="attendee.image_filename ? Imgix(attendee.image_filename, {w:200, h:200, fit: 'crop'}) : avatar()"
            class="w-full h-auto rounded"
            style="border-radius: 50%;"
            alt=""
          />
        </div>
        <p class="mr-2 w-8/12 md:w-9/12 ml-2 overflow-hidden">
          {{ attendee.first_name }} {{ attendee.last_name }}
        </p>
      </div>
      </div>
    </transition>
    <!-- end dropdown content -->

  </div>

</template>

<script>

  import avatar from '@/graphics/avatar'
  import Imgix from "../../services/Imgix"
  import { mapGetters } from "vuex";

  export default {
    props: ['attendees'],
    mixins: [Imgix],
    data() {
      return {
        show: false,
      }
    },
    computed: {
      ...mapGetters(['client']),
    },
    methods: {
      avatar() {
        return avatar
      },
    }
  }

</script>

<style lang="scss" scoped>

  .teacher {
    margin-right: 20px;
  }

  .slide-fade-enter-active {
    transition: all .3s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-active {
    transition: all .2s ease;
    z-index: 2;
    position: relative;
  }

  .slide-fade-leave-to {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

  .slide-fade-enter {
    opacity: 0;
    transform: translateY(-30px);
    z-index: 2;
    position: relative;
  }

</style>
