<template>
  <div>
    <loading-spinner/>
  </div>
</template>

<script>

import LoadingSpinner from '@/components/LoadingSpinner';
import YogoApi from '@/gateways/YogoApi';

export default {
  components: { LoadingSpinner },
  async created() {
    const membershipId = this.$route.params.membershipId;
    await YogoApi.post('/payments/stripe/process-membership-payment-method-update-if-completed', { membership: membershipId });
    this.$store.commit('setMembershipFlashMessage', {type: 'updated'});

    this.$router.replace({ name: 'Membership', params: {id: membershipId} });
  },
};

</script>
