<template>
  <div :class="{ 'wrapper' : !mobileAppMode }">

    <div class="theme--frame-box">

      <loadingspinner v-if="loading" :text="loadingText"></loadingspinner>

      <div v-else>

        <router-link v-if="mobileAppMode" :to="{ name: 'MyProfile' }" class="flex aic">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
               stroke-linejoin="round" class="feather feather-arrow-left">
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          <span class="pl-1">
          {{ $t('global.back') }}
        </span>
        </router-link>

        <h4>{{ $t('event.InstallmentPayments') }}:</h4>
        <h1>
          {{ eventSignup.event.name }}
        </h1>

        <div class="mt-8 flex flex-col mb-8">
          <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                        <span class="sr-only">
                          Number
                        </span>
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {{ $t('global.Date') }}
                      </th>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        {{ $t('global.Amount') }}
                      </th>
                      <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right">
                        {{ $t('global.DownloadReceipt') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr
                      v-for="(installment, idx) in sortedInstallments"
                      :key="`installment_${installment.id}`"
                      :class="{
                        'bg-green-100 text-green-800': installment.paid,
                        'bg-red-100 text-red-800': installment.date <= todayIso && !installment.paid,
                        }"
                      >
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {{ idx + 1 }}
                      </td>
                      <td class="whitespace-nowrap flex px-3 py-4 text-sm text-gray-500">
                        <span v-if="installment.date === '1970-01-01'">
                          {{ $t('event.FirstInstallmentSlashDeposit') }}
                        </span>
                        <span v-else>{{ formatDate(installment.date) }}</span>
                        <span v-if="installment.paid">
                          <checkmark-icon class="ml-4"></checkmark-icon>
                        </span>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {{ formatCurrency(installment.amount - installment.discount_amount - installment.membership_benefit_discount_amount) }}
                      </td>
                      <td class="relative flex whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 justify-end">
                        <a class="text-indigo-600 hover:text-indigo-900 pointer" :href="getReceiptLink(installment.order)" v-if="installment.order"
                        target="_blank">
                        <DownloadIcon></DownloadIcon>
                      </a>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>&nbsp;</th>
                      <td class="pr-3 py-3.5 text-sm font-semibold text-gray-900 px-3">
                        {{ $t('global.Total') }}
                        <span
                            v-if="eventSignup.discount_code_name || eventSignup.is_membership_benefit_discount_applied">
                        ({{ formatCurrency(installmentsTotalBeforeDiscount) }}
                        <span v-if="eventSignup.is_membership_benefit_discount_applied">
                          - {{ $t('global.MemberDiscount') }} {{
                            formatCurrency(installmentsMembershipBenefitDiscountTotal)
                          }}</span>
                          <span v-if="eventSignup.discount_code_name"> - {{ $t('global.Discount') }} {{
                            formatCurrency(installmentsDiscountTotal)
                          }}</span>)
                      </span>
                      </td>
                      <td class="px-3 py-3.5 text-sm font-semibold text-gray-900">
                        {{ formatCurrency(installmentsTotalAfterDiscount) }}
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div v-if="eventSignup.installment_payment_failed_number_of_times > 0">
          <h3 class="bg-red-100 text-red-800 mt-2 mb-2 inline-block py-1 px-2 rounded-lg">{{ $t('event.PaymentFailed') }}</h3>
          <button class="btn btn-primary block" @click="retryPaymentWithCurrentPaymentMethod">
            {{ $t('event.TryAgainWithSameCard') }}
          </button>
        </div>

        <div class="mt-4">
          <h2 class="mb-2">{{ $t('event.PaymentMethod') }}</h2>
          {{ paymentMethod.pay_type }}: XXXX-XXXX-XXXX-{{ paymentMethod.card_last_4_digits }}
        </div>
        <button
          class="btn btn-primary mt-4" @click="startChangeCreditCard"
          v-if="eventSignup.installment_payments_status !== 'paid_in_full'"
        >
          {{ $t('event.ChangePaymentCard') }}
        </button>


        <div class="space8"></div>


      </div>
    </div>

    <div class="space8"></div>
    <div class="space8"></div>
    <div class="space8"></div>

    <Alert :show.sync="showAlertDialog" :alert-text="alertDialogText"/>

    <md-snackbar :md-duration="4000" :md-active.sync="showSnackbar">
      {{ snackbarText }}
    </md-snackbar>

  </div>
</template>

<script>

import Loadingspinner from "./LoadingSpinner.vue";
import YogoApi from '../gateways/YogoApi';
import { mapGetters } from 'vuex';
import _filter from 'lodash/filter';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import _sumBy from 'lodash/sumBy';
import dateTimeFunctions from '../mixins/dateTimeFunctions';
import qs from 'qs';
import currencyFormatters from '@/mixins/currencyFormatters';
import CheckmarkIcon from '@/graphics/icons/CheckmarkIcon';
import DownloadIcon from '@/graphics/icons/DownloadIcon';
import Alert from '@/components/Alert';
import { DateTime } from 'luxon';

export default {
  name: 'EventSignupInstallments',
  components: {
    DownloadIcon,
    CheckmarkIcon,
    Loadingspinner,
    Alert,
  },
  mixins: [dateTimeFunctions, currencyFormatters],
  data() {
    return {
      loading: true,
      loadingText: '',

      eventSignup: {},

      showAlertDialog: false,
      alertDialogText: '',

      reepayWindow: null,

      snackbarText: '',
      showSnackbar: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'cartItems',
      'stateReady',
      'apiRoot',
      'eventSignupFlashMessage',
      'mobileAppMode',
    ]),
    activeSubscription() {
      if (!this.eventSignup) return null;

      let activePaymentSubscriptions = _filter(this.eventSignup.payment_subscriptions,
          paymentSubscription => paymentSubscription.status === 'active');

      if (!activePaymentSubscriptions.length) {
        return null;
      }

      if (!activePaymentSubscriptions.length > 1) {
        throw Error('Too many active subscription');
      }

      return activePaymentSubscriptions[0];
    },
    todayIso() {
      return DateTime.now().setZone(this.client ? this.client.timezone : 'UTC').toISODate();
    },
    sortedInstallments() {
      if (!this.eventSignup || !this.eventSignup.installments) return [];
      return _sortBy(this.eventSignup.installments, 'date');
    },
    paymentMethod() {
      return this.eventSignup
          ? _find(this.eventSignup.payment_subscriptions, { status: 'active', archived: 0 })
          : {};
    },
    installmentsTotalAfterDiscount() {
      return this.installmentsTotalBeforeDiscount
          - this.installmentsDiscountTotal
          - this.installmentsMembershipBenefitDiscountTotal;
    },
    installmentsTotalBeforeDiscount() {
      return _sumBy(this.eventSignup.installments, 'amount');
    },
    installmentsDiscountTotal() {
      return _sumBy(this.eventSignup.installments, 'discount_amount');
    },
    installmentsMembershipBenefitDiscountTotal() {
      return _sumBy(this.eventSignup.installments, 'membership_benefit_discount_amount');
    },
  },
  mounted() {
    if (this.stateReady) this.init();
  },
  methods: {
    async init() {
      await this.getEventSignup();
      if (this.eventSignupFlashMessage) {
        if (this.eventSignup.installment_payments_status === 'failed') {
          this.retryPaymentWithCurrentPaymentMethod();
        } else {
          this.alertDialogText = {
            updated: this.$t('event.PaymentCardUpdated'),
            failed: this.$t('event.PaymentUpdateCancelled'),
          }[this.eventSignupFlashMessage.type];
          this.showAlertDialog = true;
        }

        this.$store.commit('setEventSignupFlashMessage', null);
      }
    },
    async getEventSignup() {

      const queryString = qs.stringify({
        id: this.$route.params.eventSignupId,
        populate: [
          'installments.order',
          'event',
          'payment_subscriptions',
          'installment_payments_status',
        ],
      });
      [this.eventSignup] = await YogoApi.get(`/event-signups?${queryString}`);

      this.loading = false;

    },


    async startChangeCreditCard() {
      switch (this.client.settings.payment_service_provider) {
        case 'reepay': {
          const recurringSession = await YogoApi.post(
              '/payments/reepay/create-recurring-session');
          this.reepayWindow = new window.Reepay.ModalCheckout(recurringSession.id);

          this.reepayWindow.addEventHandler(window.Reepay.Event.Accept, async data => {
            console.log('data:', data);
            await YogoApi.post('/payments/reepay/attach-card-to-event-signup', {
              cardId: data.payment_method,
              eventSignup: this.eventSignup.id,
            });
            if (this.eventSignup.installment_payment_failed_number_of_times > 0) {
              await this.retryPaymentWithCurrentPaymentMethod();
            }
            await this.getEventSignup();
          });

          this.reepayWindow.addEventHandler(window.Reepay.Event.Error, () => {
          });
          this.reepayWindow.addEventHandler(window.Reepay.Event.Cancel, () => {
          });
          this.reepayWindow.addEventHandler(window.Reepay.Event.Close, async () => {
            await this.$store.dispatch('updateUser');
          });
          break;
        }

        case 'stripe': {
          this.loadingText = this.$t('event.OneMoment');
          this.loading = true;
          const eventSignupId = this.$route.params.eventSignupId;
          const success_url = `${document.location.origin}/frontend/index.html#/stripe/event-signup-update-payment-method-success/${eventSignupId}`;
          const setupSession = await YogoApi.post(
              '/payments/stripe/create-checkout-setup-session-for-event-signup',
              {
                eventSignup: eventSignupId,
                success_url: success_url,
                cancel_url: document.location.href,
              });

          document.location = setupSession.url;
          break;
        }
      }

    },

    async retryPaymentWithCurrentPaymentMethod() {
      this.loading = true;
      const response = await YogoApi.post('/event-signups/' + this.$route.params.eventSignupId + '/retry-failed-installment-payment');
      if (response === 'E_EVENT_SIGNUP_DOES_NOT_NEED_PAYMENT') {
        // Local data might be outdated. Reload.
        alert(this.$t('event.paymentIsUpToDate'));
        this.getEventSignup();
        return;
      }
      if (response === 'E_PAYMENT_FAILED') {
        this.loading = false;
        alert(this.$t('global.paymentDeclined'));
        this.getEventSignup();
        return;
      }
      alert(this.$t('global.paymentSuccess'));
      this.getEventSignup();
    },

    getReceiptLink(order) {
      return this.apiRoot + '/orders/' + order.id + '/pdf-receipt?receiptToken=' + order.receipt_token;
    },
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState) this.init();
    },
  },
};
</script>

<style>


</style>
