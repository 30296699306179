<template>
  <div :class="{ 'wrapper' : !mobileAppMode }">
    <div class="theme--frame-box">

      <loading-spinner v-if="state === 'loading'"/>

      <div class="flex flex-col text-center" v-else>
        <h1 class="mb-4">{{ gym.name }}</h1>

        <div v-if="state === 'prompt'">

          <h2 class="mb-8">{{ $t('global.WouldYouCheckIn') }}</h2>

          <div>
            <button
              class="btn btn-primary w-full md:w-auto mt-2 mb-2"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              @click="checkIn"
            >
              {{ $t('global.CheckIn') }}
            </button>
            <button
              v-if="!mobileAppMode"
              class="btn btn-primary w-full md:w-auto  mt-2"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              @click="$router.push({name: 'MyProfile'})"
            >
              {{ $t('global.Dismiss') }}
            </button>

          </div>
        </div>

        <div v-else-if="state === 'done'">

          <div class="checkin-icon-big my-8 flex justify-center">
            <svg :class="{active: startSuccessAnimation}" xmlns="http://www.w3.org/2000/svg" width="24"
                 height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                 stroke-linecap="round" stroke-linejoin="round" class="feather feather-user-check">
              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" class="svg-elem-1"></path>
              <circle cx="8.5" cy="7" r="4" class="svg-elem-2"></circle>
              <polyline points="17 11 19 13 23 9" class="svg-elem-3"></polyline>
            </svg>
          </div>
        </div>

        <div v-else-if="state === 'blank'"></div>

        <div v-else-if="state === 'timeout'">
          <h2 class="class=mb-4">{{ $t('checkin.GymCheckinTimeout_title') }}</h2>
          <div>{{ $t('checkin.GymCheckinTimeout_text') }}</div>
        </div>

        <div v-else-if="state === 'error'">
          <h2 class="class=mb-4">{{ $t('checkin.GymCheckinFailed_title') }}</h2>
          <div>{{ $t('checkin.GymCheckinFailed_text') }}</div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import YogoApi from '@/gateways/YogoApi';
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  name: 'OpenGymCheckin',
  components: { LoadingSpinner },
  data() {
    return {
      state: 'loading',
      gym: null,

      startSuccessAnimation: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'mobileAppMode',
    ]),
    queryParameters() {
      return this.$route.query;
    },
  },
  async created() {
    // Fetch gym
    this.gym = await YogoApi.get(`/gyms/${this.$route.query.gymId}`);

    if (this.checkForTimeout()) {
      return;
    }

    this.state = 'prompt';
  },
  methods: {
    checkForTimeout() {
      // Maximum possible time from QR code generation to valid check-in is 7.5 minutes
      const MAX_INTERVAL = 7.5 * 60 * 1000;
      const now = Date.now();
      if (now - this.$route.query.timestamp > MAX_INTERVAL) {
        this.state = 'timeout';
        return true;
      }
      return false;
    },
    async checkIn() {
      this.state = 'loading';
      try {
        const response = await YogoApi.post(
          '/gym-checkins',
          {
            gymId: this.$route.query.gymId,
            userId: this.user.id,
            token: this.$route.query.token,
          },
            {
              headers: {
                'X-Yogo-Client-Accepts-Extended-Error-Format': 1,
              }
            }
        );
        if (response.error) {
          this.state = 'blank';
          this.$store.dispatch('alert/setCallback', () => {
            this.$router.push({ name: 'MyProfile' });
          });
          return;
        }
      } catch ({response}) {
        if (this.checkForTimeout()) {
          return;
        }
        this.state = 'error';
        return;
      }

      this.state = 'done';
      setTimeout(() => {
        this.startSuccessAnimation = true;
      }, 100);
      setTimeout(() => {
        this.$router.push({ name: 'MyProfile' });
      }, 2500);
    },
  },
};

</script>

<style lang="scss">

.checkin-icon-big {
  svg {
    width: 180px;
    height: 180px;
  }
}

svg .svg-elem-1 {
  stroke-dashoffset: 25.56814956665039px;
  stroke-dasharray: 25.56814956665039px;
  -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}

svg.active .svg-elem-1 {
  stroke-dashoffset: 0;
}

svg .svg-elem-2 {
  stroke-dashoffset: 27.132741228718345px;
  stroke-dasharray: 27.132741228718345px;
  -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s;
}

svg.active .svg-elem-2 {
  stroke-dashoffset: 0;
}

svg .svg-elem-3 {
  stroke-dashoffset: 10.485280990600586px;
  stroke-dasharray: 10.485280990600586px;
  -webkit-transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
  transition: stroke-dashoffset 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s;
}

svg.active .svg-elem-3 {
  stroke-dashoffset: 0;
}
</style>
