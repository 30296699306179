<template>
  <div>
  </div>
</template>

<script>

import YogoApi from '../gateways/YogoApi';
import GoogleTagManagerMixin from './GoogleTagManagerMixin';
import _pick from 'lodash/pick';
import ClientSite from '@/gateways/ClientSite';

export default {

  mixins: [GoogleTagManagerMixin],
  methods: {

    async stripeStartPayment(orderId, _success_url) {

      this.loading = true;

      const success_url = _success_url || `${document.location.origin}/frontend/index.html#/stripe-payment-succes/{{ORDER_ID}}`;

      const cancel_url = ClientSite.clientWebsiteReferrerUrl() || document.location.href;

      let result;
      if (orderId) {
        result = await YogoApi.post('/payments/stripe/create-session-for-order', {
          order: orderId,
          cancel_url,
          success_url,
        });
      } else {
        result = await YogoApi.post('/payments/stripe/create-order-and-matching-session-type',
            {
              cancel_url,
              success_url,
            });
      }

      if (result === 'E_INVALID_CART_ITEM') {
        alert(this.$t('checkout.oneOrMoreCartItemsAreNoLongerAvailable'));
        await this.$store.dispatch('updateUser');
        this.loading = false;
        return;
      }

      if (!result.status) {
        throw new Error(
            'Invalid result from /payments/stripe/create-order-and-matching-session-type');
      }

      switch (result.status) {
        case 'ORDER_SETTLED':
          if (this.user) {
            await this.$store.dispatch('updateUser');
          }
          await this.GTMEvent('paymentStarted');
          await this.GTMTransaction(result.order);
          this.$store.commit('setMyProfileFlashMessage',
              { type: 'paymentSuccess', order: result.order.id });
          this.$router.push({ name: 'MyProfile' });
          return;
        case 'CHECKOUT_SESSION_CREATED':
          await this.GTMEvent('paymentStarted');
          window.top.location = result.url;
          return;
      }
    },

    async stripeStartGiftCardPayment(form) {
      this.loading = true;

      await this.GTMEvent('paymentStarted');

      const success_url = `${document.location.origin}/frontend/index.html#/stripe-giftcard-payment-success/{{ORDER_ID}}`;

      // Gift cards purchases that are cancelled should return to the gift card page, not the client site.
      const cancel_url = document.location.href;

      const postData = {
        ...(
            _pick(
                form,
                [
                  'amount',
                  'giver_name',
                  'giver_email',
                  'recipient_name',
                  'recipient_email',
                  'message',
                ],
            )
        ),
        stripe_success_url: success_url,
        stripe_cancel_url: cancel_url,
      };

      const result = await YogoApi.post(
          '/payments/stripe/create-order-and-charge-session-for-gift-card',
          postData,
          {
            headers: {
              'X-Yogo-Client-Accepts-Extended-Error-Format': 1,
            },
          },
      );

      if (result.error) {
        alert(result.error.localized_message + '\n\n' + result.error.localized_message);
        this.loading = false;
        return;
      }

      localStorage.setItem('yogoFrontend_giftCardDetails', JSON.stringify(form));

      window.top.location = result.url;

    },

  },
};

</script>
