<template>
  <div class="myprofile wrapper">

    <h1 class="hidden-sm">{{ $t('global.myProfile') }}</h1>

    <div class="space4 hidden-sm"></div>
    <div :class="{ 'space4' : mobileAppMode }"></div>
    <div class="flex__grid--thirds md:mt-4">

      <div class="card-panel flex--2-3 pt-sm-0">

        <MyActivity
            v-if="client.settings.frontend_myprofile_show_myactivity"
            :historicClassSignups="historicClassSignups"
            :historic-class-livestream-signups="historicClassLivestreamSignups"
            class="mb-5 md:mb-4 lg:mb-5"
        >
        </MyActivity>

        <div
            class="card-panel flex--row-standard space--between"
        >
          <div
              v-if="client.settings.frontend_show_menu_item_calendar || bulletin_board_enabled"
              class="width__50--desktop mb-5 md:mb-4 lg:mb-5"
          >

            <div
                class="mb-5 md:mb-4 lg:mb-5"
                :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
                v-if="bulletin_board_enabled"
            >
              <h2 class="mb-3">
                {{ bulletin_board_title }}
              </h2>

              <div class="user-html-excerpt">
                <p
                    class="text-base user-html"
                    v-html="client.settings.bulletin_board_content_html"
                />
              </div>

              <router-link :to="{ name: 'BulletinBoard' }">
                <button
                    class="btn btn-secondary"
                    :class="{ 'btn-secondary-app bg-white w-full': mobileAppMode }"
                >
                  {{ $t('global.ShowMore') }}
                </button>
              </router-link>
            </div>
            <MyClasses
                v-if="client.settings.frontend_show_menu_item_calendar"
                :classes="upcomingClasses" @refresh="getMyProfile"
            />
          </div>

          <div class="width__50--desktop">

            <!-- Should be visible only if the client has created one or more appointment types(services) -->
            <MyAppointments
                v-if="clientHasCreatedAppointmentTypes"
                class="mb-5 md:mb-4 lg:mb-5"
                :appointments="appointments"
                @refresh="getMyProfile"
            >
            </MyAppointments>

            <MyEvents
                v-if="client.settings.frontend_show_menu_item_events"
                :eventSignups="eventSignups"
                @refresh="getMyProfile"
                class="mb-5 md:mb-4 lg:mb-5"
            >
            </MyEvents>
          </div>
        </div>

        <MyVideos
            :customer="customer"
            :videoGroups="customer.video_groups_that_customer_can_access"
            v-if="videoGroups.length"
            @reload="getMyProfile"
            class="mb-5 md:mb-4 lg:mb-5"
        >
        </MyVideos>

      </div>

      <div
          class="card-panel flex--1-3"
          :class="mobileAppMode ? '' : 'pb-4'"
      >
        <MyMembership
            :memberships="customer.memberships"
            v-if="customer.memberships.length"
            class="mb-5 md:mb-4 lg:mb-5"
        >
        </MyMembership>

        <MyClassPasses
            :classPasses="customer.class_passes"
            v-if="customer.class_passes.length"
            class="mb-5 md:mb-4 lg:mb-5"
        >
        </MyClassPasses>
        <div
            v-if="!customer.memberships.length && !customer.class_passes.length"
            class="mb-5 md:mb-4 lg:mb-5"
            :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
        >
          <h3 class="mb-5 md:mb-4 lg:mb-5">{{ $t('profile.noMemberships') }}</h3>
          <router-link class="underline" :to="{name:'Prices'}">
            {{ $t('global.seePrices') }}
          </router-link>
        </div>


        <div v-if="showMyClassSeries">
          <my-class-series :class-series="classSeries"/>
        </div>

        <div
            v-if="customer.invoices.length"
            class="mb-5 md:mb-4 lg:mb-5"
        >
          <MyPurchases :invoices="customer.invoices"></MyPurchases>
        </div>

        <MyInfo :customer="customer"></MyInfo>
      </div>

    </div> <!-- flex-row -->

    <div :class="mobileAppMode ? 'space4' : 'space8'"></div>

    <yogo-dialog narrow :show.sync="showPaymentSuccessDialog">
      <template #title>
        {{ $t('global.thanksForYourOrder') }}
      </template>
      <template #content>
        {{ $t('global.paymentSuccess') }}
      </template>
      <template #actions>
        <button class="btn btn-primary" @click="showPaymentSuccessDialog = false">
          {{ $t('dialog.Ok') }}
        </button>
      </template>
    </yogo-dialog>

  </div>
</template>

<script>
import MyClasses from './MyClasses';
import MyAppointments from './MyAppointments';
import MyEvents from './MyEvents';
import MyActivity from './MyActivity';
import MyMembership from './MyMembership';
import MyClassPasses from './MyClassPasses';
import MyPurchases from './MyPurchases';
import MyInfo from './MyInfo';
import MyVideos from "./MyVideos";
import YogoApi from '../gateways/YogoApi';
import moment from 'moment';
import { mapGetters } from 'vuex';
import qs from 'qs';

import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _reverse from 'lodash/reverse';
import _sortBy from 'lodash/sortBy';
import _find from 'lodash/find';
import MyClassSeries from '@/components/MyClassSeries';
import VideoMixins from '@/mixins/VideoMixins';
import { DateTime } from 'luxon';
import YogoDialog from '@/components/YogoDialog.vue';

export default {
  name: 'MyProfile',
  components: {
    MyClassSeries,
    MyPurchases,
    MyClasses,
    MyAppointments,
    MyEvents,
    MyActivity,
    MyMembership,
    MyInfo,
    MyClassPasses,
    MyVideos,
    YogoDialog,
  },
  mixins: [VideoMixins],
  data() {
    return {
      receipt: false,
      customer: {
        class_signups: [],
        memberships: [],
        class_passes: [],
        orders: [],
        invoices: [],
      },
      historicClassSignups: [],
      historicClassLivestreamSignups: [],
      upcomingClasses: [],
      eventSignups: [],
      appointments: [],
      classSeries: [],

      bulletin_board_enabled: false,
      bulletin_board_title: '',

      showPaymentSuccessDialog: false,
      clientHasCreatedAppointmentTypes: false,

      videoGroups: [],
    };
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userName',
      'profileImageFilename',
      'client',
      'clientLogoFilename',
      'user',
      'stateReady',
      'myProfileFlashMessage',
      'mobileAppMode',
    ]),
    showMyClassSeries() {
      const todayIso = DateTime.now()
          .setZone(this.client ? this.client.timezone : 'UTC')
          .toISODate();
      return !!_find(
          this.classSeries,
          classSeries => {
            const classSeriesHasValidClassesToUse = classSeries.class_series_type.allow_cancel_individual_signups
                && classSeries.classes_left > 0
                && classSeries.class_series_type.valid_until >= todayIso;

            const classSeriesHasUnpaidInstallments = classSeries.installments.filter(i => !i.paid).length > 0;

            return classSeriesHasValidClassesToUse || classSeriesHasUnpaidInstallments;
          },
      );
    },
  },
  mounted() {
    if (this.stateReady) this.getMyProfile();
    if (this.myProfileFlashMessage && this.myProfileFlashMessage.type === 'paymentSuccess') {
      this.showPaymentSuccessDialog = true;
      this.$store.commit('setMyProfileFlashMessage', null);
    }

    // Clear gift card details. They might be lingering after a cancelled payment.
    localStorage.removeItem('yogoFrontend_giftCardDetails');
  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState) this.getMyProfile();
    },
  },
  methods: {

    async getMyProfile() {
      const appointmentTypes = await YogoApi.get('/appointment-types');
      this.clientHasCreatedAppointmentTypes = appointmentTypes && appointmentTypes.length > 0;

      const userQuery = {
        id: this.user.id,
        populate: [
          'memberships',
          'memberships.membership_type',
          'memberships.payment_option',
          'memberships.payment_subscriptions',
          'memberships.pending_no_show_fees',
          'memberships.next_payment',
          'memberships.current_or_upcoming_membership_pause',
          'memberships.current_membership_pause',
          'memberships.upcoming_membership_pause',
          //'memberships.pending_no_show_fees.class',
          //'memberships.pending_no_show_fees.class.class_type',
          'class_passes',
          'class_passes.class_pass_type',
          'image',
          //'video_groups_that_customer_can_access',
          'invoices',
          'settings',
        ],
      };

      if (this.client.extended_video_enabled) {
        userQuery.populate.push('recommended_videos');
      } else {
        userQuery.populate.push('video_groups_that_customer_can_access');
      }

      const userQueryString = qs.stringify(userQuery);

      const historicClassSignupsQueryString = qs.stringify({
        user: this.user.id,
        startDate: moment()
            .startOf('month')
            .subtract(11, 'months')
            .format('YYYY-MM-DD'),
        endDate: moment()
            .startOf('month')
            .add(1, 'month')
            .subtract(1, 'day')
            .format('YYYY-MM-DD'),
        populate: ['class', 'class.class_type'],
      });

      const classesQueryString = qs.stringify({
        userIsSignedUpOrOnWaitingList: this.user.id,
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD'),
        populate: [
          'class_type',
          'room',
          'room.branch',
          'user_is_signed_up_for_class',
          'user_can_sign_off_from_class',
          'user_is_signed_up_for_livestream',
          'user_can_sign_off_from_livestream',
          'user_is_signed_up_for_waiting_list',
          'user_can_sign_off_from_waiting_list',
          'user_number_on_waiting_list',
          'class_accepts_customer_signups',
          'user_can_sign_up_for_class',
          'user_can_sign_up_for_number_of_seats',
          'user_signup_count',
          'class_signoff_warning',
          'class_signoff_deadline_timestamp',
          'user_must_receive_warning_after_signoff_deadline',
          'user_can_start_livestream',
          'livestream_link',
          'ics_url',
          'ics_url_livestream',
          'user_is_signed_up_for_class_with_class_series_name',
          'user_is_signed_up_for_livestream_with_class_series_name',
        ],
        sort: [
          'date ASC',
          'start_time ASC',
        ],
      });

      const eventSignupsQueryString = qs.stringify({
        user: this.user.id,
        populate: [
          'event',
          'event.time_slots',
          'event.time_slots.room',
          'event.time_slots.room.branch',
          'event.time_slots.teachers',
          'event.room',
          'event.room.branch',
          'event.teachers',
          'installments',
          'number_of_installments_paid',
          'installment_payments_status',
        ],
        startDate: DateTime.now().setZone(this.client.timezone).minus({ months: 1 }).toISODate(),
      });

      const appointmentQueryString = qs.stringify({
        customer: this.user.id,
        startDate: DateTime.now().setZone(this.client.timezone).toISODate(),
        endDate: DateTime.now().setZone(this.client.timezone).plus({ days: 354 }).toISODate(),
        populate: [
          'teacher',
          'customer',
          'appointment_type',
          'room.branch',
        ],
      });

      const classSeriesQueryString = qs.stringify({
        user: this.user.id,
        populate: [
          'class_series_type.classes_and_livestream_classes',
          'class_series_type.number_of_classes_and_livestream_classes',
          'class_series_type.number_of_remaining_classes_and_livestream_classes',
          'class_series_type.number_of_remaining_classes_and_livestream_classes',
          'class_series_type.first_class',
          'class_series_type.last_class',
          'class_series_type.valid_until',
          'installments',
          'installment_payments_status',
          'number_of_installments_paid',
        ],
      });

      const clientSettingsQueryString = qs.stringify({
        keys: [
          'bulletin_board_enabled',
          'bulletin_board_title',
        ],
      });

      let upcomingClassResponse, clientSettingsResponse;

      [
        [this.customer],
        this.historicClassSignups,
        this.historicClassLivestreamSignups,
        upcomingClassResponse,
        this.eventSignups,
        this.videoGroups,
        this.appointments,
        this.classSeries,
        clientSettingsResponse,
      ] = await Promise.all([
            YogoApi.get(`/users?${userQueryString}`),
            YogoApi.get(`/class-signups?${historicClassSignupsQueryString}`),
            YogoApi.get(`/class-livestream-signups?${historicClassSignupsQueryString}`),
            YogoApi.get(`/classes?${classesQueryString}`),
            YogoApi.get(`/event-signups?${eventSignupsQueryString}`),
            YogoApi.get('/video-groups'),
            YogoApi.get(`/appointments?${appointmentQueryString}`),
            YogoApi.get(`/class-series?${classSeriesQueryString}`),
            YogoApi.get(`/clients/${this.client.id}/settings?${clientSettingsQueryString}`),
          ],
      );
      this.customer.class_passes = _filter(
          this.customer.class_passes,
          classPass => (classPass.classes_left > 0 || classPass.class_pass_type.pass_type === 'unlimited') &&
              (classPass.valid_until === null || moment(classPass.valid_until, 'YYYY-MM-DD').isSameOrAfter(moment(), 'day')),
      );
      this.customer.memberships = _filter(
          this.customer.memberships,
          membership => membership.status !== 'ended',
      );
      this.upcomingClasses = upcomingClassResponse.classes;

      if (this.client.extended_video_enabled) {
        this.populateDescriptionPreviews(this.customer.recommended_videos);
      } else {
        _each(this.customer.video_groups_that_customer_can_access, videoGroup => {
          switch (videoGroup.sort_videos_by) {
            case 'name':
              videoGroup.videos = _sortBy(videoGroup.videos,
                  video => video.video_provider_data.name);
              break;
            case 'created':
              videoGroup.videos = _sortBy(
                  videoGroup.videos,
                  video => DateTime.fromISO(video.created_at_datetime_for_sorting_utc || video.video_provider_data.created_time).toMillis()
              );
              break;
          }
          if (videoGroup.sort_videos_direction === 'desc') {
            videoGroup.videos = _reverse(videoGroup.videos);
          }
          this.populateDescriptionPreviews(videoGroup.videos, 179, 220);
        });
      }

      this.appointments = _sortBy(this.appointments, ['date', 'start_time']);

      this.bulletin_board_enabled = clientSettingsResponse.bulletin_board_enabled;
      this.bulletin_board_title = clientSettingsResponse.bulletin_board_title;

    },

  },
};
</script>


<style lang="scss" scoped>

.user-html-excerpt {
  display: block;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 20px solid #fff;
  -webkit-mask-image: linear-gradient(to bottom, white, transparent);
  mask-image: linear-gradient(to bottom, white, transparent);
}

</style>
