<template>
  <div class="schedule wrapper">
    <div class="theme--frame-box">
      <a
        v-if="client.settings.calendar_show_teacher_filter || client.settings.calendar_show_classtype_filter"
        class="underline fr m-5"
        @click="toggle">
          {{ $t('global.filters') }}
      </a>
      <div class="space--between flex--start mb60-sm wrap">
        <div class="flex--1-3 yogo-input mb-4" v-if="client.branches && client.branches.length > 1">
          <label for="branch">{{ $t('global.Branch') }}</label>
          <select name="branch" v-model="filterByBranch">
            <option value="0">
              {{  $t('global.branchesAll') }}
            </option>
            <option v-for="branch in client.branches" :value="branch.id" :key="'branch_' + branch.id">
              {{ branch.name }}
            </option>
          </select>
        </div>
      </div>

      <slide-up-down :active="filtersVisible" :duration="500" ref="filterAnimateDown">

        <div class="flex--row pb-4">

          <div v-if="client.settings.calendar_show_teacher_filter" class="flex--1-3 mr10-md mr20-lg yogo-input">
            <label>{{ $t('global.teacher') }}</label>
            <select v-model="filterByTeacher">
              <option :value="0">- - {{ $t('global.teachersAll') }} - -</option>
              <option v-for="teacher in teachers" :value="teacher.id" :key="teacher.id">{{ teacher.first_name + ' ' +
                teacher.last_name }}
              </option>
            </select>
          </div>

          <div v-if="client.settings.calendar_show_classtype_filter" class="flex--1-3 yogo-input" :style="{visibility: filterBySessionType === 'event' ? 'hidden' : 'visible'}">
            <label>{{ $t('global.classType') }}</label>
            <select v-model="filterByClassType">
              <option :value="0">- - {{ $t('global.classTypesAll') }} - -</option>
              <option v-for="classType in classTypes" :value="classType.id" :key="classType.id">{{ classType.name }}
              </option>
            </select>
          </div>

        </div>

      </slide-up-down>

      <div class="clear"></div>

      <div class="flex--row space--between flex--start fc-sm aic">

        <div class="flex flex-shrink-0 items-center rounded-full py-1 mb-4 cursor-pointer">

          <Datepicker
            v-model="calendarDate"
            class="datepicker"
            >
          </Datepicker>
        </div>

        <span class="flex__align-center space--between calendar__week--nav o0-sm">
          <md-button class="md-icon-button" @click.prevent="gotoPreviousWeek" :disabled="!canGoBack">
            <span class="icon-arrowleft"></span>
          </md-button>
          {{ $t('calendar.week') }} {{ weekNumber }}
          <md-button class="md-icon-button" @click.prevent="gotoNextWeek">
            <span class="icon-arrowright"></span>
          </md-button>
        </span>

        <div v-if="client.settings.calendar_show_classes_and_events_separately_or_together  === 'separately'" class="flex--row o1-sm wrap">

          <RadioButtons
            id="filterBySessionType"
            v-model="filterBySessionType"
            :options="group_options"
          >
          </RadioButtons>

        </div>

      </div>

      <ScheduleList
        v-if="client.settings.calendar_layout_type === 'list'"
        :filterBySessionType="client.settings.calendar_show_classes_and_events_separately_or_together === 'together' ? null : filterBySessionType"
        :date="calendarDate"
        :filterByTeacher="filterByTeacher"
        :filterByClassType="filterByClassType"
        :filterByBranch="filterByBranch"
        >
      </ScheduleList>

      <ScheduleTimetable
        v-else-if="client.settings.calendar_layout_type === 'timetable'"
        :date="calendarDate"
        :filterBySessionType="client.settings.calendar_show_classes_and_events_separately_or_together === 'together' ? null : filterBySessionType"
        :filterByTeacher="filterByTeacher"
        :filterByClassType="filterByClassType"
        :filterByBranch="filterByBranch"
      >
      </ScheduleTimetable>

      <div class="space8"></div>

    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import ScheduleTimetable from './schedule/ScheduleTimetable'
  import ScheduleList from './schedule/ScheduleList'
  import RadioButtons from './RadioButtons'
  import YogoApi from '../gateways/YogoApi'
  import SlideUpDown from 'vue-slide-up-down'
  import Datepicker from './Datepicker'
  import { DateTime } from 'luxon';
  import _sortBy from 'lodash/sortBy';

  export default {
    components: {
      ScheduleTimetable,
      ScheduleList,
      SlideUpDown,
      RadioButtons,
      Datepicker,
    },

    data() {
      return {
        dateLabel: 'dato',
        privateSessionsLabel: 'Privattimer',
        groupSessionsLabel: 'Hold',
        eventTimeSlotsLabel: 'Kurser',
        teacherLabel: 'Underviser',
        classTypeLabel: 'Holdtype',
        radio: 'teamlessons',
        calendarDate: DateTime.now().toISODate(),

        earliestAllowedMondayIso: DateTime.now().startOf('week').toISODate(),

        filterByTeacher: 0,
        filterByClassType: 0,
        teachers: [],
        classTypes: [],
        filtersVisible: false,

        filterBySessionType: 'group',

        group_options: [
          {
            value: 'group',
            text: this.$t('calendar.classes'),
          },
          {
            value: 'private',
            text: this.$t('calendar.privateSessions'),

          },
          {
            value: 'event',
            text: this.$t('calendar.events'),
          },
        ]

      }
    },
    computed: {
      ...mapGetters('calendarFilters', {
        filters: 'all'
      }),
      ...mapGetters([
        'client',
        'stateReady',
      ]),
      filterByBranch: {
        get() {
          return this.client.branches.length > 1 ? this.filters.branch : null;
        },
        set(value) {
          this.setFilter({ key: 'branch', value })
        }
      },


      weekNumber() {
        return DateTime.fromISO(this.calendarDate).weekNumber;
      },
      canGoBack() {
        return DateTime.fromISO(this.calendarDate).startOf('week').toISODate() > this.earliestAllowedMondayIso;
      },
    },
    mounted() {
      const el = this.$refs.filterAnimateDown.el
      el.addEventListener('transitionend', () => {
      })
    },
    methods: {
      ...mapActions('calendarFilters', ['setFilter']),
      toggle() {
        this.filtersVisible = !this.filtersVisible
      },
      gotoNextWeek() {
        this.calendarDate = DateTime.fromISO(this.calendarDate).plus({weeks: 1}).toISODate();
      },
      gotoPreviousWeek() {
        this.calendarDate = DateTime.fromISO(this.calendarDate).minus({weeks: 1}).toISODate();
      },
      datepickerDateIsDisabled(date) {
        const datepickerDateIso = DateTime.fromJSDate(date).toISODate();
        return datepickerDateIso < this.todayISO;
      },
      async initFiltersAndDate() {
        this.earliestAllowedMondayIso = DateTime.now().setZone(this.client.timezone).startOf('week').toISODate();
        this.calendarDate = DateTime.now().setZone(this.client.timezone).toISODate();
        [this.teachers, this.classTypes] = await Promise.all([
          YogoApi.get(
              '/users' +
              '?teacher=1' +
              '&hasClassesOrEventTimeSlotsFromDateForward=' + DateTime.now().setZone(this.client.timezone).startOf('week').toISODate(),
          ),
          YogoApi.get(
              '/class-types' +
              '?hasClassesFromDateForward=' + DateTime.now().setZone(this.client.timezone).startOf('week').toISODate(),
          ),
        ]);
        this.teachers = _sortBy(this.teachers, ['first_name','last_name']);
        this.classTypes = _sortBy(this.classTypes, 'name');
      }
    },
    async created() {
      if(this.stateReady) {
        this.initFiltersAndDate();
      }
    },
    watch: {
      stateReady(newReadyState) {
        if (newReadyState) {
          this.initFiltersAndDate();
        }
      },
      calendarDate(newDate) {
        const todayIso = DateTime.now().setZone(this.client.timezone).toISODate();
        // There is a bug in the datepicker, so we can not just disable dates before today.
        if (newDate === '' || newDate < todayIso) {
          this.$nextTick(() => {
            this.calendarDate = todayIso;
          })
        }
      },
    },
  }
</script>

<style lang="scss">

  .date-picker {
    .md-field:after {
      height: 0;
    }
    @media (max-width: 768px) {
      width: 140px !important;
    }
  }

  .before-today {
    display: none;
   @media (min-width: 1024px) {
      display: block;
    }
  }

</style>
