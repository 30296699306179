<template>

  <div>

    <md-snackbar md-position="center"
                 :md-active.sync="showSnackbar" md-persistent :md-duration="8000">
      {{ snackbarText }}

      <a v-if="showICSButton" class="btn-primary bg-white" :href="ICSButtonLink">{{
          $t('global.AddToCalendar')
        }}
      </a>

    </md-snackbar>

  </div>

</template>

<script>

import YogoApi from '@/gateways/YogoApi';

import _isString from 'lodash/isString';
import _assign from 'lodash/assign';


import { mapGetters } from 'vuex';
import MobileAppInterface from '@/mixins/MobileAppInterface.vue';

export default {
  name: 'ClassSignup',
  mixins: [MobileAppInterface],
  props: {
    showErrorsToUser: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      classItem: null,

      showSnackbar: false,
      snackbarText: '',
      showICSButton: false,
      ICSButtonLink: '',
    };
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
  },

  created() {
    this.$on('signUpForClass', this.signUpForClass);
    this.$on('signOffFromClass', this.signOffFromClass);
    this.$on('signUpForLivestream', this.signUpForLivestream);
    this.$on('signUpForWaitingList', this.signUpForWaitingList);
    this.$on('signOffFromWaitingList', this.signOffFromWaitingList);
    this.$on('classPrimaryActionClick', this.classPrimaryActionClick);
  },

  methods: {

    async signUpForClass(classItem, options) {

      options = _assign({
        showSnackbar: true,
      }, options);

      const signupResult = await YogoApi.post('/class-signups', {
        user: this.user.id,
        class: classItem.id,
      });

      if (_isString(signupResult) && signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        // Always prompt the user to buy another membership
        this.$router.push({
          name: 'PurchaseAccessForClass',
          query: { classId: classItem.id, seatCount: 1, attendanceType: 'class' },
        });
        return;
      }

      if (_isString(signupResult) && signupResult.substr(0, 2) === 'E_') {
        return this.classSignupFailure(signupResult);
      }

      this.snackbarText = this.$t('calendar.YouAreNowSignedUpForClassName',
        { className: classItem.class_type.name });

      if (options.showSnackbar) {
        this.showSnackbar = true;
        this.showICSButton = true;
        this.ICSButtonLink = classItem.ics_url;
      }

      this.$emit('classSignupSuccess');
    },

    async signUpForLivestream(classItem, options) {

      options = _assign({
        showSnackbar: true,
      }, options);

      const signupResult = await YogoApi.post('/class-livestream-signups', {
        user: this.user.id,
        'class': classItem.id,
      });

      if (signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {
        this.$router.push({
          name: 'PurchaseAccessForClass',
          query: { attendanceType: 'class_livestream', seatCount: 1, classId: classItem.id },
        });
        return;
      } else if (_isString(signupResult) && signupResult.substr(0, 2) === 'E_') {
        return this.classSignupFailure(signupResult);
      }

      this.snackbarText = this.$t('calendar.youAreNowSignedUpForLivestreamForClassName',
        { className: classItem.class_type.name });

      if (options.showSnackbar) {
        this.showSnackbar = true;
        this.showICSButton = true;
        this.ICSButtonLink = classItem.ics_url_livestream;
      }

      this.$emit('classSignupSuccess');
    },

    async signOffFromClass(classItem) {
      if (arguments.length !== 1) throw 'signOffFromClass needs exactly 1 parameter: classItem';

      const deadlineTimestamp = classItem.user_is_signed_up_for_livestream ?
        classItem.class_livestream_signoff_deadline_timestamp :
        classItem.class_signoff_deadline_timestamp;

      if (classItem.user_must_receive_warning_after_signoff_deadline && Date.now() > deadlineTimestamp) {
        if (!confirm(classItem.class_signoff_warning)) {
          return;
        }
      } else if (!confirm(this.$t('calendar.WouldYouLikeToCancelTheBooking?'))) {
        return;
      }

      let response;
      if (classItem.user_is_signed_up_for_livestream) {
        response = await YogoApi.delete('/class-livestream-signups/' + classItem.user_livestream_signup_id);
      } else {
        response = await YogoApi.post('/class-signups/change-count', {
          class: classItem.id,
          user: this.user.id,
          count: 0,
        });
      }

      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      if (classItem.user_is_signed_up_for_livestream) {
        this.snackbarText = this.$t('calendar.youAreNoLongerSignedUpForLivestreamFor',
          { className: classItem.class_type.name });
      } else {
        this.snackbarText = this.$t('calendar.youAreNoLongerSignedUpFor',
          { className: classItem.class_type.name });
      }

      this.showSnackbar = true;

      this.$emit('classSignoffSuccess');

      this.callMobileApp('invalidateSchedule');
    },

    async changeNumberOfSignupsForClass(classItem, count, options = {}) {

      // Does the use have enough classes left?
      const numberOfAdditionalSeats = count - classItem.user_signup_count;
      if (numberOfAdditionalSeats > classItem.user_can_sign_up_for_number_of_seats) {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        //  Always prompt the user to buy another membership
        this.$router.push({
          name: 'PurchaseAccessForClass',
          query: {
            classId: classItem.id,
            seatCount: numberOfAdditionalSeats,
            attendanceType: 'class',
          },
        });
        return;
      }

      // Should we display a warning about late cancel?
      if (
        count < classItem.user_signup_count
        && classItem.user_must_receive_warning_after_signoff_deadline
        && Date.now() > classItem.class_signoff_deadline_timestamp
      ) {
        if (!confirm(classItem.class_signoff_warning)) {
          return;
        }
      } else if (
        count === 0
        && !confirm(this.$t('calendar.WouldYouLikeToCancelTheBooking?'))
      ) {
        return;
      }

      // Make the change
      let response = await YogoApi.post('/class-signups/change-count', {
        class: classItem.id,
        user: this.user.id,
        count,
      });


      // Error?
      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      // Success
      if (options.showSnackbar !== false) {

        if (count === 0) {
          // Removed all sign-ups
          this.snackbarText = this.$t('calendar.youAreNoLongerSignedUpFor',
              { className: classItem.class_type.name });
          this.showICSButton = false;
        } else if (classItem.user_signup_count === 0 && count === 1) {
          this.snackbarText = this.$t(
              'calendar.YouAreNowSignedUpForClassName',
              {
                className: classItem.class_type.name,
              },
          );
          this.showICSButton = true;
          this.ICSButtonLink = classItem.ics_url;
        } else {
          // Still sign-up(s) left
          this.snackbarText = this.$t(
              'calendar.YouAreNowSignedUpForNSeatsOnClassName',
              {
                seats: count,
                className: classItem.class_type.name,
              },
          );
          this.showICSButton = true;
          this.ICSButtonLink = classItem.ics_url;
        }

        this.showSnackbar = true;
      }

      this.$emit('classSignoffSuccess');

      this.callMobileApp('invalidateSchedule');
    },

    async signUpForWaitingList(classItem) {
      const signupResult = await YogoApi.post('/class-waiting-list-signups', {
        user: this.user.id,
        'class': classItem.id,
      });

      if (signupResult === 'E_CUSTOMER_HAS_NO_VALID_CLASS_PASS_OR_MEMBERSHIP') {

        // If the user has an active membership, but it has reached its weekly/monthly limit or is
        // not usable for some other reason, show the reason.
        if (classItem.user_active_invalid_memberships && classItem.user_active_invalid_memberships.length) {
          alert(classItem.user_active_invalid_memberships[0].reason_for_membership_not_valid.localized_message);
        }

        // Always prompt the user to buy another membership
        this.$router.push({
          name: 'PurchaseAccessForClass',
          query: { attendanceType: 'class_waiting_list', seatCount: 1, classId: classItem.id },
        });
        return;
      } else if (_isString(signupResult) && signupResult.substr(0, 2) === 'E_') {
        return this.waitingListSignupFailure(signupResult);
      }

      this.snackbarText = this.$t('global.youJoinedTheWaitlistFor',
        { className: classItem.class_type.name });

      this.showSnackbar = true;

      this.$emit('classWaitingListSignupSuccess');

    },

    async signOffFromWaitingList(classItem) {
      if (arguments.length !== 1) throw 'signOffFromWaitingList needs exactly 1 parameter: classItem';

      const response = await YogoApi.delete('/class-waiting-list-signups/' + classItem.user_waiting_list_signup_id);

      if (response.substr(0, 2) === 'E_') {
        alert(response);
        return;
      }

      this.snackbarText = this.$t('global.youAreNoLongerOnTheWaitlistFor',
        { className: classItem.class_type.name });

      this.showSnackbar = true;

      this.$emit('classWaitingListSignoffSuccess');

      this.callMobileApp('invalidateSchedule');
    },

    classSignupFailure(e) {
      if (this.showErrorsToUser) {
        alert(this.$t('calendar.classSignupError', { errorMessage: e }));
      }
      this.$emit('classSignupFailure', e);
    },

    waitingListSignupFailure(e) {
      if (this.showErrorsToUser) {
        alert(this.$t('calendar.waitingListSignupError', { errorMessage: e }));
      }
      this.$emit('waitingListSignupFailure', e);
    },

    cancelSignup() {
      this.$emit('classSignupFailure', 'E_USER_CANCELLED_SIGNUP');
    },

    classPrimaryActionClick(action) {
      console.log('Class primary action click', action);
    },

  },
};

</script>


<style lang="scss">
.md-button-content {
  background: #fff !important;
}
</style>
