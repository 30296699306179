<template>
  <loadingspinner></loadingspinner>
</template>

<script>

  import Loadingspinner from "./LoadingSpinner";

  export default {

      components: {Loadingspinner},
      name: 'init',
  }

</script>