<template>
  <div>
    <button class="p-2 flex-1 text-2xl" @click="decrement()">-</button>
    <input class="text-lg w-10 flex-1 font-bold text-center outline-none" type="number" :min="min" :max="max" v-model="num" />
    <button class="p-2 flex-1 text-2xl" @click="increment()">+</button>
  </div>
</template>

<script>
export default {
  name: "YogoNumberInput",
  props: {
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      num: 1,
    };
  },
  watch: {
    value(newVal) {
      this.num = newVal;
    },
    num() {
      this.$emit('input', parseInt(this.num) || 0);
    },
  },
  mounted() {
    this.num = this.value;
  },
  methods: {
    increment() {
      if (this.num >= this.max) {
        return;
      } else {
        this.num++;
      }
    },
    decrement() {
      if (this.num <= this.min) {
        return;
      } else {
        this.num--;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

input[type="number"] {
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
  appearance: textfield !important;
  }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

</style>
