<template>

  <div class="min-h-screen lg:flex lg:justify-center bg-white lg:bg-transparent pb-8">
    <div class="login narrow-wrapper w-full max-w-xl m-auto" :class="{ 'wrapper' : !mobileAppMode }">
      <div class="bg-white md:pl-6 md:pr-6 md:pb-6 pt-4 md:mt-4 lg:mt-8 lg:pb-16">
        <div class="my-6">
          <ModalLogo></ModalLogo>
          <div class="space4"></div>
          <h2>{{ $t('profile.resetPass') }}</h2>
          <div class="space8"></div>
          <div class="flex-column" v-if="!emailSent">
            <p class="center">{{ $t('profile.resetPasswordDesc') }}</p>
            <div class="space8"></div>

            <form novalidate @submit.prevent="submitForm">

              <InputField
                :label="$t('login.emailLabel')"
                :class="getValidationClass('email')"
                type="email"
                autocomplete="email"
                v-model="form.email"
              >
                <template v-slot:errors>
                  <span class="error" v-if="emailNotFound">{{ $t('login.emailNotInDB') }}</span>
                  <span class="error" v-else-if="!$v.form.email.required">{{ $t('login.typeEmail') }}</span>
                  <span class="error" v-else-if="!$v.form.email.email">{{ $t('login.notValidEmail') }}</span>
                </template>
              </InputField>

              <div class="space8"></div>

              <div class="flex__row--mobile space__between--mobile">
                <button type="submit" class="btn btn-primary">{{ $t('global.sendEmail') }}</button>
                <router-link :to="{ name: 'Login' }" class="whitespace--nowrap">{{ $t('global.backToLogin') }}</router-link>
              </div>

            </form>
          </div>
          <div v-else>
            <p>
              {{ $t('login.sendConfirmEmail') }}
            </p>
            <div class="space2"></div>
            <p>
              {{ $t('login.checkSpamFolder') }}
            </p>
            <div class="space4"></div>
            <p>
              <button type="button" class="btn btn-primary" @click="$router.push({ name: 'Login' })">{{ $t('global.backToLogin') }}</button>
            </p>

          </div>
        </div>
      </div>

    </div>


    <md-dialog-alert
        :md-active.sync="emailNotFound"
        :md-content="$t('login.emailNotInDB')"
        :md-confirm-text="$t('global.Ok')"/>

  </div>
</template>

<script>

    import {validationMixin} from 'vuelidate'
    import YogoApi from '../gateways/YogoApi'
    import InputField from './InputField.vue'
    import {
        required,
        email,
    } from 'vuelidate/lib/validators'

    import ModalLogo from './ModalLogo'
    import { mapGetters } from 'vuex';

    export default {
        components: {
          ModalLogo,
          InputField
        },
        mixins: [validationMixin],
        data() {
            return {
                email: '',
                password: '',
                rememberMe: false,
                form: {
                    email: ''
                },
                emailSent: false,
                emailNotFound: false
            }
        },
      computed: {
        ...mapGetters([
          'mobileAppMode',
        ]),
      },
        mounted: function () {

        },
        methods: {
            async submitForm() {
                try {
                    this.emailNotfound = false
                    this.$v.$touch();

                    if (this.$v.$invalid) return;

                    const location = window.location
                    const route = this.$router.resolve({
                        name: 'ResetPasswordWithToken',
                        params: {email: '{email}', token: '{token}'}
                    })

                    const response = await YogoApi.post('/password-reset-send-token', {
                        email: this.form.email,
                        setNewPasswordLink: location.protocol + '//' + location.host + location.pathname + route.href
                    })

                    if (response === 'E_EMAIL_NOT_FOUND') {
                        this.emailNotFound = true
                        return
                    }

                    this.form.email = '';

                    this.$v.$reset()

                    this.emailSent = true;

                } catch (err) {
                    console.log(err)
                }

            },
            getValidationClass(fieldName) {
                const field = this.$v.form[fieldName]

                if (field) {
                    return {
                        'invalid': field.$invalid && field.$dirty
                    }
                }
            },
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                },
            }
        },
    }
</script>
