<template>
  <div
    :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
  >

    <h2 class="mb-4">{{ $t('profile.myAppointmentsLabel') }}</h2>

    <BookAppointmentButton
      class="mb-2"
      v-if="client.settings.frontend_myprofile_show_book_appointment_button"
      :buttonText="$t('global.BookAppointment')"
      @refresh="$emit('refresh')"
    />


    <div v-if="!appointments.length">
      {{ $t('profile.noAppointments') }}
    </div>

    <ul class="widget__list--standard" v-else>

      <li v-for="appointment in appointments" :key="'appointment' + appointment.id">
        <div class="flex__row--full">
          <p class="bold">
            {{ appointment.appointment_type.name }}
          </p>
          <!--<button
            class="btn btn-primary"
            v-if="parseInt(appointment.event.price) === 0"
            @click="signOffFromEvent(appointment)">{{ $t('global.cancelBooking') }}
          </button>-->
        </div>
        <p>
          {{ appointment.teacher.first_name }} {{ appointment.teacher.last_name }}
        </p>
        <p v-if="appointment.room">
          {{ $t('global.Location') }}: {{ appointment.room.name }} <span v-if="appointment.room.branch">- {{ appointment.room.branch.name }}</span>
        </p>

        <p>
          {{ formatDate(appointment.date) }}, {{ appointment.start_time.substr(0, 5) }}
        </p>
        <div class="space2"></div>
      </li>
    </ul>

  </div>
</template>

<script>

import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import BookAppointmentButton from '@/components/BookAppointmentButton';
import { mapGetters } from 'vuex';

export default {
  name: 'MyAppointments',
  mixins: [dateTimeFunctions],
  props: ['appointments'],
  components: {
    BookAppointmentButton,
  },
  computed: {
    ...mapGetters([
      'client',
      'mobileAppMode'
    ]),
  },
};
</script>


<style>


</style>
