<template>
  <Modal v-show="showModal" @close="close">
    <template slot="body">
      {{ alertText }}
    </template>
    <template slot="footer">
      <button
        class="btn btn-primary"
        :class="{ 'btn-small-app': mobileAppMode }"
        @click="close"
      >
        Ok
    </button>
    </template>
  </Modal>

</template>
<script>
import Modal from '@/components/Modal';
import { mapGetters } from 'vuex';
export default {
  components: { Modal },
  props: ['alertText', 'show'],
  data() {
    return {
      showModal: false,
    }
  },
  watch: {
    show(newShowValue) {
      if (newShowValue) {
        this.showModal = true;
      }
    },
  },
  computed: {
    ...mapGetters([
      'mobileAppMode'
      ]),
  },
  methods: {
    close() {
      this.showModal = false;
      this.$emit('update:show', false);
    }
  }
};
</script>
