<template>
  <div class="classes">
    <div
        :class="mobileAppMode ? 'rounded-lg bg-white p-4 shadow-lg' : 'theme--frame-box'"
    >

      <h2>{{ $t('profile.myClasses') }}</h2>
      <div class="space4"></div>
      <div v-if="!classes.length">
        {{ $t('profile.noClasses') }}
      </div>

      <ul class="widget__list--standard" v-else>
        <li
            v-for="classItem in classes"
            :key="'class_' + classItem.id"
            class="border-b pb-4"
        >
          <div class="flex__row--full">
            <div class="flex--1 w-full">
              <div v-if="classItem.user_is_signed_up_for_class_with_class_series_name
                || classItem.user_is_signed_up_for_livestream_with_class_series_name"
                   class="flex justify-start mb-1"
              >
                <span class="items-center font-semibold text-xs text-green-700">
                  <span class="py-1 pr-1 flex items-center">
                  <TwoCirclesIcon class="mr-1"></TwoCirclesIcon>
                  {{
                      classItem.user_is_signed_up_for_class_with_class_series_name
                      || classItem.user_is_signed_up_for_livestream_with_class_series_name
                    }}

                  </span>

                </span>
              </div>
              <div class="flex justify-between">
                <p class="bold">
                  <span v-if="classItem.user_is_signed_up_for_waiting_list"
                        style="display:flex;align-items: center;">
                    <SignedUpForWaitingListIcon></SignedUpForWaitingListIcon>#{{
                      classItem.user_number_on_waiting_list
                    }}
                    <md-tooltip>{{
                        $t('global.youAreNumberXOnTheWaitlist',
                            { number: classItem.user_number_on_waiting_list })
                      }}</md-tooltip>
                  </span>
                  {{
                    classItem.class_type.name +
                    (classItem.cancelled ? ' ' + $t('global.cancelled') : '')
                  }}
                </p>

                <span
                    :class="{'class-cancelled' : classItem.cancelled, livestream: classItem.user_is_signed_up_for_livestream}"
                    class="items-center"
                    v-if="classItem.user_is_signed_up_for_livestream"
                >
                  {{ $t('global.Livestream') }}
                </span>
              </div>

              <p v-if="classItem.user_signup_count > 1 || (client.settings.customer_can_reserve_multiple_seats_for_class && classItem.class_accepts_customer_signups)">
                {{
                  classItem.user_signup_count > 1 ? $t('global.YouHaveNSeats',
                      { n: classItem.user_signup_count }) : $t('global.YouHaveOneSeat')
                }}
                <a
                    href="#"
                    @click.prevent="openEditNumberOfSeatsDialog(classItem)"
                    v-if="showChangeSignupCount(classItem)"
                >
                  |
                  <span class="font-bold underline">
                    {{ $t('global.Change') }}
                  </span>
                </a>
              </p>

              <p class="" v-if="classItem.room && classItem.room.branch">
                {{ classItem.room.branch.name }}
              </p>

            </div>
          </div>

          <p>
            {{ formatDate(classItem.date) }}, {{
              classItem.start_time.substring(0, 5)
            }} - {{ classItem.end_time.substring(0, 5) }}
          </p>
          <div class="space2"></div>

          <button
              class="btn btn-primary mb-1"
              :class="{ 'btn-primary-app': mobileAppMode }"
              :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
              v-if="classItem.user_can_start_livestream"
              @click="$router.push({name: 'LivestreamClassPreloader', params: {classId: classItem.id}})"
          >
            {{ $t('global.OpenLivestream') }}
          </button>

          <div class="flex justify-between items-end flex-wrap">
            <div class="flex-1">
              <button
                  class="btn btn-primary mt-2"
                  :class="{ 'btn-secondary-app': mobileAppMode }"
                  v-if="classItem.user_can_sign_off_from_class || classItem.user_can_sign_off_from_livestream"
                  @click="signOffFromClass(classItem)">{{ $t('global.cancelBooking') }}
              </button>
              <button
                  class="btn btn-primary"
                  :class="{ 'btn-secondary-app flex-1': mobileAppMode }"
                  v-if="classItem.user_can_sign_off_from_waiting_list"
                  @click="signOffFromWaitingList(classItem)">{{ $t('global.removeFromWaitlist') }}
              </button>
            </div>

            <div
                v-if="!mobileAppMode"
                class="flex-1 flex justify-end"
            >
              <a
                  class="btn-secondary mt-2"
                  :href="classItem.user_is_signed_up_for_livestream ? classItem.ics_url_livestream : classItem.ics_url"
              >
                {{ $t('global.AddToCalendar') }}
              </a>
            </div>

          </div>
        </li>
      </ul>

      <ClassSignup
          ref="classSignupComponent"
          @classSignoffSuccess="classSignoffSuccess"
          @classWaitingListSignoffSuccess="classWaitingListSignoffSuccess"
      ></ClassSignup>

    </div>

    <md-dialog :md-active.sync="showEditNumberOfSeatsDialog">
      <md-dialog-title>{{ editNumberOfSeatsDialogClass.class_type.name }}</md-dialog-title>
      <md-dialog-content>
        <div class="flex justify-start items-center">
          <yogo-number-input :min="changeSignupCountMin(editNumberOfSeatsDialogClass)"
                             :max="changeSignupCountMax(editNumberOfSeatsDialogClass)"
                             v-model="editNumberOfSeatsDialogCounter"
                             class="mr-2"></yogo-number-input>
          {{ $t('global.seats') }}
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <button
            class="btn mr-4 px-2"
            @click="showEditNumberOfSeatsDialog = false"
        >
          {{ $t('global.Dismiss') }}
        </button>
        <button
            class="btn btn-primary"
            @click="saveNumberOfSeats(editNumberOfSeatsDialogClass, editNumberOfSeatsDialogCounter)">
          {{ $t('global.Save') }}
        </button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ClassSignup from '@/components/ClassSignup';
import SignedUpForWaitingListIcon from "../graphics/SignedUpForWaitingListIcon";
import TwoCirclesIcon from "../graphics/icons/TwoCirclesIcon";
import dateTimeFunctions from '@/mixins/dateTimeFunctions';
import YogoNumberInput from '@/components/ui/YogoNumberInput';

export default {
  components: {
    YogoNumberInput,
    ClassSignup,
    SignedUpForWaitingListIcon,
    TwoCirclesIcon,
  },
  mixins: [dateTimeFunctions],
  data() {
    return {
      showEditNumberOfSeatsDialog: false,
      editNumberOfSeatsDialogClass: { class_type: {} },
      editNumberOfSeatsDialogCounter: 0,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'classSignoffDeadlineInHumanFormat',
      'mobileAppMode',
    ]),
  },
  props: ['classes'],
  methods: {
    changeSignupCountMin(classItem) {
      return classItem.user_can_sign_off_from_class
          ? 0
          : classItem.user_signup_count;
    },
    changeSignupCountMax(classItem) {
      return classItem.user_can_sign_up_for_class
          ? 99
          : classItem.user_signup_count;
    },
    showChangeSignupCount(classItem) {
      return this.changeSignupCountMin(classItem) !== classItem.user_signup_count
          || this.changeSignupCountMax(classItem) !== classItem.user_signup_count;
    },
    signOffFromClass(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromClass', classItem);
    },

    classSignoffSuccess() {
      this.$emit('refresh');
    },
    signOffFromWaitingList(classItem) {
      this.$refs.classSignupComponent.$emit('signOffFromWaitingList', classItem);
    },
    classWaitingListSignoffSuccess() {
      this.$emit('refresh');
    },
    openEditNumberOfSeatsDialog(classItem) {
      this.editNumberOfSeatsDialogClass = classItem;
      this.editNumberOfSeatsDialogCounter = classItem.user_signup_count;
      this.showEditNumberOfSeatsDialog = true;
    },
    async saveNumberOfSeats() {
      await this.$refs.classSignupComponent.changeNumberOfSignupsForClass(
          this.editNumberOfSeatsDialogClass,
          this.editNumberOfSeatsDialogCounter,
      );
      this.showEditNumberOfSeatsDialog = false;
      this.$emit('refresh');
    },
  },
};
</script>

<style scoped>

.livestream {
  color: #1e429f;
  padding-left: .7rem;
  padding-right: .7rem;
  line-height: 1.25rem;
  font-size: .7rem;
  font-weight: 600;
  display: inline-flex;
  border-radius: 9999px;
  background-color: #e1effe;
}

.class-series {
  color: rgb(13 148 136);
  padding-left: .7rem;
  padding-right: .7rem;
  line-height: 1.25rem;
  font-size: .7rem;
  font-weight: 600;
  display: inline-flex;
  border-radius: 9999px;
  background-color: rgb(240 253 250);
}

</style>
