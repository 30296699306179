import qs from 'qs';

const isRunningInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    console.log('caught error');
    return true;
  }
}

const clientWebsiteReferrerUrl = () => {

  // This parameter is set both if the frontend module is running in an iframe
  // and if it has opened in a new tab.

  const queryString = window.location.search.substr(1);
  const query = qs.parse(queryString);

  return query.clientWebsiteReferrerUrl || '';
}

export default {
  isRunningInIframe,
  clientWebsiteReferrerUrl,

  closeOwnPopup() {
    if (!isRunningInIframe()) return;
    window.parent.postMessage(JSON.stringify({command: 'closeYogoFrontend'}), '*');
  },

}
