<template>

  <div class="profileedit" :class="{ 'wrapper' : !mobileAppMode }">
    <div class="theme--frame-box">

      <div v-if="!mobileAppMode">
        <h1>{{ $t('profile.editProfile') }}</h1>
        <div class="space8"></div>
      </div>

      <loadingspinner v-if="loading"></loadingspinner>

      <form novalidate @submit.prevent="submitForm" v-else>

        <div v-if="!mobileAppMode" class="space4"></div>

        <router-link v-if="mobileAppMode" :to="{ name: 'MyProfile' }" class="flex aic">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
               fill="none"
               stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
               class="feather feather-arrow-left">
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          <span class="pl-1">
          {{ $t('global.back') }}
          </span>
        </router-link>

        <imagefield :imageId.sync="form.image" imageFormat="profile"></imagefield>

        <div class="flex--row space--between">

          <div class="flex__1-2">

            <md-field :class="getValidationClass('first_name')">
              <label>{{ $t('login.firstNameLabel') }}</label>
              <md-input required type="text" v-model="form.first_name"
                        autocomplete="given-name"></md-input>
              <span class="md-error"
                    v-if="!$v.form.first_name.required">{{ $t('login.firstNameMust') }}</span>
            </md-field>

          </div>

          <div class="flex__1-2">

            <md-field :class="getValidationClass('last_name')">
              <label>{{ $t('login.lastNameLabel') }}</label>
              <md-input required type="text" v-model="form.last_name"
                        autocomplete="family-name"></md-input>
              <span class="md-error" v-if="!$v.form.last_name.required">{{
                  $t('login.lastNameMust')
                }}</span>
            </md-field>

          </div>
        </div>

        <div class="flex--row space--between">

          <div class="flex__1-2">
            <md-field>
              <label class="active">{{ $t('login.addressLabel') }}</label>
              <md-input v-model="form.address_1"></md-input>
            </md-field>
          </div>

          <datepicker v-model="form.date_of_birth"
                      :clearable="true"
                      class="flex__1-2"
                      :label="$t('login.birthdayLabel')"
          ></datepicker>

        </div>

        <div class="flex--row space--between">

          <div class="flex__1-2">
            <md-field>
              <label class="active">{{ $t('login.address2Label') }}</label>
              <md-input v-model="form.address_2"></md-input>
            </md-field>
          </div>

          <div class="flex__1-2">
            <md-field>
              <label class="active">{{ $t('login.zipLabel') }}</label>
              <md-input v-model="form.zip_code" autocomplete="postal-code"></md-input>
            </md-field>
          </div>

        </div>

        <div class="flex--row space--between">

          <div class="flex__1-2">
            <md-field>
              <label>{{ $t('login.cityLabel') }}</label>
              <md-input v-model="form.city" autocomplete="address-line3"></md-input>
            </md-field>
          </div>


          <div class="flex__1-2 yogo-input">
            <label>{{ $t('login.countryLabel') }}</label>
            <country-select v-model="form.country"/>
          </div>

        </div>


        <div class="flex--row space--between">

          <div class="flex__1-2">
            <md-field :class="getValidationClass('email')">
              <label>{{ $t('login.emailLabel') }}</label>
              <md-input required type="email" v-model="form.email" autocomplete="email"></md-input>
              <span class="md-error" v-if="!$v.form.email.required">{{
                  $t('login.typeEmail')
                }}</span>
              <span class="md-error" v-else-if="!$v.form.email.email">{{
                  $t('login.emailNotValid')
                }}</span>
            </md-field>
          </div>

          <div class="flex__1-2">

            <md-field :class="getValidationClass('phone')">
              <label>{{ $t('login.phoneLabel') }}</label>
              <md-input type="text" v-model="form.phone" autocomplete="tel"></md-input>
              <!--<span class="md-error" v-if="!$v.form.phone.required">Telefonnummer skal indtastes</span>-->
            </md-field>

          </div>

        </div> <!-- flex-row space-between -->

        <div class="space4"></div>

        <div class="flex--row space-between">
          <div class="mb-6 flex__1-2 yogo-input" v-if="client && client.branches && client.branches.length">
            <label>{{ $t('login.branchLabel') }}</label>
            <select v-model="form.branch">
              <option v-if="!client.settings.signup_branch_field_required" :value="0"></option>
              <option
                  v-for="branch in branchSelectData" :value="branch.id"
                  :key="'branch' + branch.id"
              >
                {{ branch.name }}
              </option>
            </select>
          </div>
          <div class="flex__1-2">
            <label class="checkbox mb-6">
              <input type="checkbox" v-model="form.newsletter">
              <span>{{ client.settings.signup_newsletter_checkbox_label }}</span>
            </label>
          </div>
        </div>

        <div class="flex--row space--between">

          <div class="flex__1-2">
            <md-field :class="getValidationClass('vat_id')">
              <label>{{ $t('global.CVRNumber') }}</label>
              <md-input type="text" v-model="form.vat_id" autocomplete="vat_id"></md-input>
            </md-field>
          </div>

          <div class="flex__1-2">

            <md-field :class="getValidationClass('company_name')">
              <label>{{ $t('global.Company') }}</label>
              <md-input type="text" v-model="form.company_name"
                        autocomplete="company_name"></md-input>
              <!--<span class="md-error" v-if="!$v.form.phone.required">Telefonnummer skal indtastes</span>-->
            </md-field>

          </div>

        </div> <!-- flex-row space-between -->

        <div class="space4"></div>

        <div class="flex--row space--between">

          <div class="flex__1-2">

            <md-field :class="getValidationClass('password')">
              <label>{{ $t('login.passwordLabel') }}</label>
              <md-input id="password" type="password" v-model="form.password"
                        autocomplete="new-password"></md-input>
              <span class="md-error"
                    v-if="!$v.form.password.passwordStrength">{{
                  $t('login.passwordDescription')
                }}</span>
            </md-field>
          </div>

          <div class="flex__1-2">

            <md-field :class="getValidationClass('confirm_password')">
              <label>{{ $t('login.passwordConfirmLabel') }}</label>
              <md-input type="password" v-model="form.confirm_password"
                        autocomplete="new-password"></md-input>
              <span class="md-error"
                    v-if="!$v.form.confirm_password.sameAsPassword">{{
                  $t('login.passwordMatch')
                }}</span>
            </md-field>

          </div>

        </div> <!-- flex-row space-between -->


        <div v-if="client.settings.customer_profile_use_additional_info_field">
          <div class="space4"></div>

          <p class="bold">{{ $t('login.additionalInfoFieldSubtitle') }}</p>
          <label v-if="client.settings.customer_profile_additional_info_field_subtitle">
            {{ client.settings.customer_profile_additional_info_field_subtitle }}
          </label>
          <md-field>
            <md-textarea v-model="form.customer_additional_info"></md-textarea>
          </md-field>
        </div>

        <div class="space8"></div>

        <button
            type="submit"
            class="btn btn-primary"
            :class="{ 'btn-primary-app': mobileAppMode }"
            :style="{ 'background-color': mobileAppMode ? client.settings.theme_primary_color : '' }"
        >
          {{ $t('global.update') }}
        </button>

        <div class="space8"></div>

        <a @click.prevent="showMoreSection = !showMoreSection">
          <span v-if="!showMoreSection">{{ $t('global.More') }}</span>
          <span v-else>{{ $t('global.Less') }}</span>
        </a>
        <div v-if="showMoreSection">
          <div class="space4"></div>
          <button type="button" class="btn btn-primary" @click="deleteProfile">{{
              $t('global.DeleteProfile')
            }}
          </button>
        </div>
        <div class="space8"></div>

        <md-snackbar
            :md-position="snackbarPosition"
            :md-duration="snackbarIsInfinity ? Infinity : snackbarDuration"
            :md-active.sync="showSnackbar"
            md-persistent
        >
          <span>{{ $t('profile.profileUpdated') }}</span>
        </md-snackbar>

      </form>
    </div>

    <div class="space8"></div>

  </div>

</template>

<script>

import YogoApi from '../gateways/YogoApi';
import { mapGetters } from 'vuex';
import _pick from 'lodash/pick';
import _sortBy from 'lodash/sortBy';
import _assign from 'lodash/assign';
import moment from 'moment';
import { validationMixin } from 'vuelidate';
import { email, required, sameAs } from 'vuelidate/lib/validators';
import Loadingspinner from "./LoadingSpinner.vue";

import imagesLoaded from 'vue-images-loaded';
import Imagefield from "./ImageField";

import Datepicker from './Datepicker';
import CountrySelect from '@/components/CountrySelect.vue';
//import InputField from '@/components/InputField.vue';

export default {
  name: 'ProfileEdit',
  mixins: [validationMixin],
  components: {
    CountrySelect,
    Imagefield,
    Loadingspinner,
    Datepicker,
  },
  directives: {
    imagesLoaded,
  },
  data() {
    return {
      userFromApi: {},

      form: {
        country: 'DK',
        branch: '',
      },
      branchSelectData: [],
      windowWidth: 0,
      showSnackbar: false,
      snackbarPosition: 'center',
      snackbarDuration: 4000,
      snackbarIsInfinity: false,

      loading: true,

      showMoreSection: false,
    };
  },
  computed: {
    ...mapGetters([
      'client',
      'user',
      'profileImageFilename',
      'stateReady',
      'mobileAppMode',
    ]),
    initialProfileImagePreviewSrc() {
      return this.Imgix(this.profileImageFilename, { w: 400, h: 400, fit: 'crop' });
    },
    imageUploaderSize() {
      return this.windowWidth >= 768 ? 100 : 75;
    },

  },
  async created() {
    this.branchSelectData = await YogoApi.get('/branches/');
    this.branchSelectData = _sortBy(this.branchSelectData, 'sort');
  },
  methods: {
    async submitForm() {

      this.$v.$touch();

      if (this.$v.$invalid) return;

      let userData = _pick(
          this.form, [
            'first_name',
            'last_name',
            'address_1',
            'address_2',
            'zip_code',
            'city',
            'country',
            'email',
            'phone',
            'image',
            'date_of_birth',
            'vat_id',
            'company_name',
            'newsletter',
          ],
      );

      if (this.client.settings.customer_profile_use_additional_info_field) {
        userData.customer_additional_info = this.form.customer_additional_info;
      }

      if (this.client.branches.length) {
        const branchId = parseInt(this.form.branch);
        userData.branches = branchId ? [branchId] : [];
      }

      if (this.form.password) {
        userData.password = this.form.password;
      }
      if (this.client.settings.signup_show_branch_field) {
        // This has a default, and should therefore only be posted if select field is visible
        userData.branch = this.form.branch + 1;
      }
      let newUser = await YogoApi.put('/users/' + this.user.id, userData);

      if (newUser === 'E_EMAIL_EXISTS') {
        alert(this.$t('profile.emailExists').replace('{email}', userData.email));
        return;
      }


      newUser = await YogoApi.get('/users/' + this.user.id + '?populate=image&populate[]=branches');


      this.form.password = '';
      this.form.confirm_password = '';

      await this.$store.dispatch('updateUser', newUser.id);

      this.populateForm(newUser);

      this.showSnackbar = true;


    },
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName];

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty,
        };
      }
    },
    populateForm() {
      console.log(this.user);
      _assign(this.form, _pick(this.user, [
            'first_name',
            'last_name',
            'address_1',
            'address_2',
            'email',
            'phone',
            'zip_code',
            'city',
            'country',
            'customer_additional_info',
            'date_of_birth',
            'vat_id',
            'company_name',
            'newsletter',
          ],
      ));

      this.form.image = this.user.image ? this.user.image.id : null;

      this.$set(this.form, 'branch', this.user.branches.length ? this.user.branches[0].id : 0);
      this.$nextTick(this.$forceUpdate);
    },
    getWindowWidth() {
      this.windowWidth = document.documentElement.clientWidth;
    },
    imageDidLoad() {
      if (this.imageIsWaiting) {
        this.imageIsWaiting = false;
      }
    },
    async deleteProfile() {
      if (!confirm(this.$t('global.DeleteProfileWarning1'))) {
        return;
      }
      if (!confirm(this.$t('global.DeleteProfileWarning2'))) {
        return;
      }
      const response = await YogoApi.delete(
          `/users/${this.user.id}`,
          {
            headers: {
              'X-Yogo-Client-Accepts-Extended-Error-Format': 1,
            },
          },
      );
      if (response.error) {
        return;
      }
      await YogoApi.post(`/logout`);
      this.$store.dispatch('logout');
      if (this.mobileAppMode) {
        alert(this.$t('global.MobileAppModeDeleteProfileConfirmation'));
      }
      this.$router.push('Login');
    },

  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth);
      this.getWindowWidth();
    });
    if (this.stateReady) {
      this.populateForm();
      this.loading = false;
    }

  },
  watch: {
    stateReady(newReadyState) {
      if (newReadyState) {
        this.populateForm();
        this.loading = false;
      }
    },
    calendarDate(newDate) {
      if (newDate === '') {
        this.calendarDate = moment().format('YYYY-MM-DD');
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWindowWidth);
  },
  validations: {
    form: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        passwordStrength(value) {
          if (!value) return true;
          const upperCase = !!value.match(/[A-Z]+/);
          const lowerCase = !!value.match(/[a-z]+/);
          const numbers = !!value.match(/[0-9]+/);
          const length = value.length >= 6;
          return upperCase && lowerCase && numbers && length;
        },
      },
      confirm_password: {
        sameAsPassword: sameAs('password'),
      },
    },
  },
};
</script>
