<template>
  <Modal @close="$emit('close')">
    <template v-slot:header>
      <h2 class="mb-5 font-bold">
        {{ client.settings.video_preferences_customer_dialog_title }}
      </h2>
      <h3 class="mb-5 font-bold">
        <nl2br :text="client.settings.video_preferences_customer_dialog_text" tag="div"></nl2br>
      </h3>
    </template>
    <template v-slot:body>
      <div class="mb-4 md:mb-10" v-for="filter in visibleFilters" :key="'filter_' + filter.id">
        <h3 class="text-lg font-bold mb-2">
          {{ filter.name }}
        </h3>
        <div
            v-for="filterValue in filter.filter_values"
            :key="'filterValue' + filterValue.id"
            class="yogo-filter-value inline-flex mr-2 border-2 mb-2 font-semibold mb-1 text-xs px-3 py-1 rounded-full cursor-pointer hover:border-gray-400 transition-all"
            :class="{'border-black': selectedVideoFilterValues.indexOf(filterValue.id) > -1}"
            @click="toggleVideoFilterValue(filterValue.id)"
        >
          {{ filterValue.name }}
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
          type="button"
          class="btn btn-secondary"
          @click="savePreferences"
      >
        {{ $t('global.Save') }}
      </button>
    </template>
  </Modal>
</template>

<script>

import Modal from '@/components/Modal';
import { mapGetters } from 'vuex';
import _intersection from 'lodash/intersection';
import _sortBy from 'lodash/sortBy';
import _map from 'lodash/map';
import _filter from 'lodash/filter';
import _each from 'lodash/each';
import _without from 'lodash/without';
import _includes from 'lodash/includes';
import YogoApi from '@/gateways/YogoApi';

export default {
  name: 'VideoPreferencesModal',
  components: { Modal },
  props: ['show'],
  data() {
    return {
      allVideoFilters: [],
      selectedVideoFilterValues: [],
    };
  },
  computed: {
    ...mapGetters(['client', 'user']),
    visibleFilters() {
      // Which filters are visible?
      const visibleFilters = _filter(
          this.allVideoFilters,
          videoFilter => _intersection(
              _map(videoFilter.filter_values, 'id'),
              this.client.settings.video_filter_values_available_for_customer_preferences,
          ).length > 0,
      );
      // Which values are visible for each filter
      _each(
          visibleFilters,
          visibleFilter => {
            visibleFilter.filter_values = _filter(
                visibleFilter.filter_values,
                filterValue => _includes(
                    this.client.settings.video_filter_values_available_for_customer_preferences,
                    filterValue.id,
                ),
            );
          },
      );
      return visibleFilters;
    },
  },
  async created() {
    let userSettings;
    [this.allVideoFilters, userSettings] = await Promise.all([
      YogoApi.get(`/video-filters?populate[]=filter_values`),
      YogoApi.get(`/users/${this.user.id}/settings`),
    ]);
    this.selectedVideoFilterValues = userSettings.video_preferences;
    this.allVideoFilters = _sortBy(this.allVideoFilters, 'sort_idx');
    _each(
        this.allVideoFilters,
        (videoFilter) => {
          videoFilter.filter_values = _sortBy(videoFilter.filter_values, 'sort_idx');
        },
    );
  },
  methods: {
    toggleVideoFilterValue(filterValueId) {
      if (this.selectedVideoFilterValues.indexOf(filterValueId) > -1) {
        this.selectedVideoFilterValues = _without(this.selectedVideoFilterValues, filterValueId);
      } else {
        this.selectedVideoFilterValues.push(filterValueId);
      }
    },
    async savePreferences() {
      await YogoApi.post(`/users/${this.user.id}/settings`, {
        video_preferences: this.selectedVideoFilterValues,
      });
      this.$emit('preferencesChanged');
      this.$emit('close');
    },
  },
};

</script>
